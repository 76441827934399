import React from 'react';
import { Create, SimpleForm, TextInput, required, SelectInput, BooleanInput, ArrayInput, SimpleFormIterator, DateTimeInput, useNotify, AutocompleteArrayInput } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { industryList, positionList } from 'helpers/categories';
import useAdminApi from 'hooks/useAdminApi';

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    width: '100%',
  },
  leftContainer: {
    padding: '0 0 0 30px',
    width: '50%',
    borderRight: '1px solid #e5e5e5',
  },
  rightContainer: {
    padding: '0 0 0 30px',
    width: '50%',
  },
  content: {
    width: '70%',
  },
  title: {
    fontWeight: '700',
    padding: '0 0 10px 0',
    fontSize: '20px',
  },
  description: {
    color: 'rgb(155,155,155)',
    fontSize: '13px',
    position: 'relative',
    top: '-20px',
  },
  fellowDescription: {
    fontSize: '13px',
    position: 'relative',
    top: '-7px',
  },
  stageDescription: {
    color: 'rgb(122,122,122)',
    fontSize: '12px',
    position: 'relative',
    top: '-7px',
  },
  hidden: {
    display: 'none',
  },
});

const CreateUser = (props) => {
  const notify = useNotify();
  const classes = useStyles();

  const onFailure = (error) => {
    const errorMessage = Object.values(JSON.parse(error.response.request.response)).toString().split('.,');
    notify(`유저 생성에 실패했습니다. ${errorMessage}`, 'warning');
  };

  const { data: categories } = useAdminApi({
    method: 'GET',
    url: '/categories/',
  });

  return (
    <Create onFailure={onFailure} title="유저 생성" {...props}>
      <SimpleForm submitOnEnter={false} redirect="list">
        <Box className={classes.mainContainer}>
          <Box className={classes.leftContainer}>
            <Typography className={classes.title}>기본 정보</Typography>
            <TextInput className={classes.content} label="유저 이름" source="username" variant="standard" validate={[required()]} />
            <DateTimeInput className={classes.content} label="생년월일" source="birth_date" variant="outlined" validate={[required()]} />
            <TextInput className={classes.content} label="유저 비밀번호" source="password" variant="standard" validate={[required()]} />
            <TextInput className={classes.content} label="닉네임" source="nickname" variant="standard" validate={[required()]} />
            <TextInput className={classes.content} label="핸드폰번호" source="phone_number" variant="standard" validate={[required()]} />
            <div className={classes.description}>ex)01012341234</div>
            <TextInput className={classes.content} label="이메일" source="email" variant="standard" validate={[required()]} />
            <SelectInput
              className={classes.content}
              label="성별"
              source="gender"
              choices={[{ id: 'female', name: '여성' }]}
              defaultValue="female"
              variant="outlined"
              disabled={true}
              validate={[required()]}
            />
            <Typography className={classes.title}>직업 정보</Typography>
            <TextInput className={classes.content} label="소속" source="company" variant="standard" />
            <TextInput className={classes.content} label="직업" source="job" variant="standard" />
            <SelectInput className={classes.content} label="업종" source="industry" choices={industryList} variant="outlined" validate={[required()]} />
            <SelectInput className={classes.content} label="직무" source="position" choices={positionList} variant="outlined" validate={[required()]} />
            <Typography className={classes.title}>카테고리</Typography>
            {categories && <AutocompleteArrayInput source="category_ids" label="카테고리" choices={categories?.results} />}
          </Box>
          <Box className={classes.rightContainer}>
            <Typography className={classes.title}>계정 정보</Typography>
            <BooleanInput label="계정 활성여부" source="is_active" defaultValue={true} />
            <BooleanInput className={classes.hidden} label="마케팅 수신동의" source="marketing" defaultValue={false} disabled={true} />
            <div className={classes.stageDescription}>
              * 펠로우 유저 설정 시 펠로우 정보를{' '}
              <b>
                <u>반드시</u>
              </b>{' '}
              입력해주세요!
            </div>
            <SelectInput
              source="stage"
              choices={[
                { id: '0', name: '0 - 콘솔로 생성한 유저' },
                { id: '10', name: '10 - 일반 유저' },
                { id: '20', name: '20 - 펠로우 유저' },
                { id: '30', name: '30 - 인스파이러 유저' },
              ]}
              className={classes.content}
              label="유저 유형"
              variant="outlined"
              validate={[required()]}
            />
            <Typography className={classes.title}>펠로우, 인스파이러 정보</Typography>
            <Typography className={classes.fellowDescription}>
              <div>
                * 펠로우, 인스파이러 등록 시 <b>필수 정보</b>를 꼭 입력해 주세요 😉
              </div>
            </Typography>
            <ArrayInput source="data.fellow.careers" label="추가 이력 (필수)">
              <SimpleFormIterator>
                <TextInput className={classes.arrayInput} label="회사" source="company" />
                <TextInput className={classes.arrayInput} label="직책" source="position" />
              </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="data.fellow.sub_categories" label="대표 키워드(최소 5개)">
              <SimpleFormIterator>
                <TextInput className={classes.arrayInput} label="펠로우 키워드" />
              </SimpleFormIterator>
            </ArrayInput>
            <TextInput className={classes.content} label="포트폴리오/개인 페이지 url" source="data.fellow.website" variant="standard" />
            <Typography className={classes.title}>추가 정보</Typography>
            <BooleanInput label="콘조이스 연사 여부" source="data.is_conjoyce_speaker" variant="outlined" />
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default CreateUser;
