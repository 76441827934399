import React from 'react';
import { List, Datagrid, TextField, DateField, SelectField, SelectInput, Filter, NumberField, Pagination, EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
  },
  editButton: {
    fontWeight: 'bold',
  },
  thumbnailImage: {
    '& img': {
      maxWidth: '200px',
    },
    textAlign: 'center',
  },
});

const status = [
  { id: 'pending', name: '승인 대기' },
  { id: 'accepted', name: '승인' },
  { id: 'rejected', name: '거절' },
];

const ApplyFilter = (props) => (
  <Filter {...props}>
    <SelectInput source="status" label="승인 상태별로 보기" choices={status} alwaysOn />
  </Filter>
);

const ApplyPagination = (props) => <Pagination rowsPerPageOptions={[20, 50, 100, 200]} {...props} />;

const EditApplyButton = (props) => {
  const classes = useStyles();
  const { status } = props.record.data;

  return status === 'pending' ? <EditButton className={classes.editButton} {...props} /> : <div />;
};

const ListApply = (props) => {
  const classes = useStyles();

  return (
    <List {...props} title="펠로우 지원 리스트" pagination={<ApplyPagination />} bulkActionButtons={false} filters={<ApplyFilter />}>
      <Datagrid classes={{ headerCell: classes.headerCell }}>
        <NumberField label="ID" source="data.author.id" />
        <TextField label="이름" source="data.author.username" />
        <TextField label="회사" source="data.author.company" />
        <DateField label="지원 날짜" source="created_at" showTime={true} />
        <SelectField label="승인 상태" source="data.status" choices={status} />
        <EditApplyButton label="지원 관리" />
      </Datagrid>
    </List>
  );
};

export default ListApply;
