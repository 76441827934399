import React from 'react';
import { List, Datagrid, TextField, TextInput, Filter, downloadCSV, AutocompleteInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import jsonExport from 'jsonexport/dist';
import { useQuery, gql } from '@apollo/client';
import { listSerializedCouponLabels } from 'graphql/queries';

const useStyles = makeStyles({
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
  },
  editButton: {
    fontWeight: 'bold',
  },
  thumbnailImage: {
    '& img': {
      maxWidth: '200px',
    },
    textAlign: 'center',
  },
  invalidityTag: {
    color: 'black',
    backgroundColor: '#FFE1E3',
    padding: '2px 6px',
    borderRadius: '15px',
    fontSize: '13px',
    wordBreak: 'keep-all',
  },
  validityTag: {
    color: 'black',
    backgroundColor: '#8CE7DA',
    padding: '2px 6px',
    borderRadius: '15px',
    fontSize: '13px',
    wordBreak: 'keep-all',
  },
  table: {
    border: '1px solid #444444',
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid #444444',
    padding: '6px 6px',
  },
  td: {
    border: '1px solid #444444',
    padding: '4px 6px',
  },
});

const IsValidField = ({ record }) => {
  const classes = useStyles();
  const now = new Date().toISOString();
  return <div>{record.is_valid ? <span className={classes.validityTag}>미사용</span> : <span className={classes.invalidityTag}>사용</span>}</div>;
};

const DateTimeField = ({ record, source }) => <div>{new Date(record.created_at).toLocaleString()}</div>;

const ComponentPanel = ({ record }) => {
  const classes = useStyles();

  return (
    <>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.th}>쿠폰 타입</th>
            <th className={classes.th}>쿠폰 이름</th>
            <th className={classes.th}>쿠폰 제한</th>
            <th className={classes.th}>소멸일시</th>
            <th className={classes.th}>쿠폰 장수</th>
          </tr>
        </thead>
        <tbody>
          {record.components.map((item) => (
            <tr>
              <td className={classes.td}>{item.coupon.type}</td>
              <td className={classes.td}>{item.coupon.coupon_name}</td>
              <td className={classes.td}>{item.coupon?.tags}</td>
              <td className={classes.td}>{new Date(item.coupon.expire_at).toLocaleString()}</td>
              <td className={classes.td}>{item.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const exporter = (records) => {
  const postsForExport = records.map((record) => {
    let fileds = {};
    fileds['쿠폰 코드'] = record.id;
    fileds['라벨'] = record.label;
    fileds['생성일시'] = new Date(record.created_at).toLocaleString();
    fileds['유효 여부'] = record.is_valid;
    fileds['사용 유저 ID'] = record.used_user_id;
    fileds['사용 일시'] = new Date(record.used_at).toLocaleString();
    return fileds;
  });
  jsonExport(postsForExport, (err, csv) => {
    downloadCSV(csv, '난수_쿠폰_리스트');
  });
};

const ListSerializedCoupon = (props) => {
  const classes = useStyles();

  const { loading, data } = useQuery(
    gql`
      ${listSerializedCouponLabels}
    `
  );

  const filters = [
    <AutocompleteInput
      label="라벨"
      source="label"
      choices={data?.listSerializedCouponLabels?.items?.map((item) => {
        let obj = {};
        obj['id'] = item;
        obj['name'] = item;
        return obj;
      })}
      alwaysOn
    />,
  ];

  return (
    <List {...props} title="난수-쿠폰 리스트" bulkActionButtons={false} pagination={false} filters={filters} exporter={exporter}>
      <Datagrid expand={<ComponentPanel />} classes={{ headerCell: classes.headerCell }}>
        <TextField label="id(난수)" source="id" sortable={false} />
        <TextField label="라벨" source="label" sortable={false} />
        <DateTimeField label="생성일시" source="created_at" sortable={false} />
        <IsValidField label="유효 여부" source="is_valid" sortable={false} />
        <TextField label="사용 유저 ID" source="used_user_id" sortable={false} />
        <DateTimeField label="사용 일시" source="used_at" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default ListSerializedCoupon;
