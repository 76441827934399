import React from 'react';
import { Create, SimpleForm, TextInput, required, ImageInput, ImageField, useRefresh, useRedirect, BooleanInput } from 'react-admin';

const CreateInterview = (props) => {
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect('/interviews');
  };

  return (
    <Create title="Interview 생성" {...props} onSuccess={onSuccess}>
      <SimpleForm submitOnEnter={false}>
        <ImageInput label="썸네일" source="thumbnail_img_url" variant="standard" validate={[required()]}>
          <ImageField />
        </ImageInput>
        <TextInput label="제목" source="subject" validate={[required()]} />
        <TextInput label="요약" source="summary" validate={[required()]} />
        <TextInput label="링크" source="link" validate={[required()]} />
        <BooleanInput label="홈페이지 노출" source="opened" />
      </SimpleForm>
    </Create>
  );
};

export default CreateInterview;
