import React, { useState } from 'react';
import { Button as ButtonMenu, useNotify } from 'react-admin';
import { Lock as LockIcon } from '@material-ui/icons';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Input } from '@material-ui/core';
import { adminAxios } from 'helpers/axios';

const ResetPasswordButton = ({ userId, onChange }) => {
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);
  const [password, setPassword] = useState('');

  const handleClick = () => setShowDialog(true);

  const handleCloseClick = () => setShowDialog(false);

  const handleChange = e => setPassword(e.target.value);

  const handleConfirm = async () => {
    const { status, statusText } = await adminAxios({
      method: 'POST',
      uri: `/users/${userId}/reset_password/`,
      data: {
        password
      }
    });
    if (status === 202) {
      setShowDialog(false);
      return onChange();
    }
    return notify(`패스워드 변경에 실패했습니다 사유: ${statusText}`, 'warning');
  };

  return (
    <>
      <ButtonMenu onClick={handleClick} label="패스워드 변경">
        <LockIcon />
      </ButtonMenu>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="패스워드 변경">
        <DialogTitle>패스워드 변경</DialogTitle>
        <DialogContent>
          <Input onChange={handleChange} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick}>취소</Button>
          <Button color="primary" disabled={!password} onClick={handleConfirm}>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResetPasswordButton;
