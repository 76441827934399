import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  labelText: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '15px 0',
    fontSize: '0.75rem',
    fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Arial,sans-serif',
    fontWeight: '400',
    lineHeight: '1',
  },
  smallIcon: {
    fontSize: 'small',
    margin: '0 0 15px 0',
  },
  valueText: {
    fontSize: '1rem',
    color: 'rgb(0,0,0)',
  },
  countContainer: {
    marginBottom: '20px',
  },
  tags: {
    margin: '0 0 20px 0',
  },
  listContainer: {
    width: '350px',
    margin: '0 0 30px 0',
  },
  layoutContainer: {
    width: '100%',
    display: 'flex',
  },
  leftLayout: {
    width: '50%',
  },
  rightLayout: {
    width: '50%',
  },
});

const DetailPromotion = (props) => {
  const classes = useStyles();
  return <div></div>;
};

export default DetailPromotion;
