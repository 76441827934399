import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ArrayInput,
  DateTimeInput,
  required,
  SaveButton,
  Toolbar,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  useRefresh,
  useRedirect,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { Button, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '50ch',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  inputRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  formLabel: {
    width: 'auto',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    borderTop: '1px solid #e5e5e5',
    background: '#80808052',
    padding: '20px 10px 0px',
    '& > span:nth-child(2)': {
      fontWeight: 'normal',
      marginLeft: '12px',
      fontSize: '14px',
    },
    '&.first': {
      paddingTop: '0px',
      borderTop: 'none',
    },
  },
}));

const CreateAttendeeToolbar = (props) => {
  const { webinarId } = props;
  const history = useHistory();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const createRedirect = (basePath, id, data) => `/webinarAttendee/create/?webinarId=${webinarId}`;

  const handleSuccessSave = () => {
    redirect(`/webinars/${webinarId}/show/attendeesList`);
    refresh();
  };

  return (
    <Toolbar {...props}>
      <SaveButton label="저장" onSuccess={handleSuccessSave} submitOnEnter={true} />
      <SaveButton label="저장하고 계속 생성하기" redirect={createRedirect} submitOnEnter={false} variant="text" />
    </Toolbar>
  );
};
const userOptionRenderer = (choice) => (choice ? (choice.id ? `${choice.id}/${choice.username}/${choice.company}/${choice.job}/${choice.email}` : '❌선택안함') : '');

const CreateWebinarAttendee = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { webinarId } = parse(history.location.search);
  const redirect = (basePath, id, data) => `/webinars/${webinarId}/show/attendeesList`;

  return (
    <Create title="참석자 생성하기" {...props}>
      <SimpleForm submitOnEnter={false} toolbar={<CreateAttendeeToolbar webinarId={webinarId} />} redirect={redirect}>
        <div className={classes.inputRow}>
          <TextInput disabled source="webinar_id" initialValue={webinarId} label="웨비나 ID" variant="standard" fullWidth={true} />
        </div>
        <div className={classes.formLabel}>
          <span>참석자 추가 </span>
        </div>
        <div className={classes.inputRow}>
          <ReferenceInput source="user_id" label="유저 검색 (id, 이름, 이메일)" reference="searchUsers" variant="standard" fullWidth={true}>
            <AutocompleteInput optionText={userOptionRenderer} />
          </ReferenceInput>
        </div>
        <div className={classes.inputRow}>
          <NumberInput source="program_id" label="프로그램 ID" variant="standard" fullWidth={true} />
        </div>
        <div className={classes.formLabel}>
          <span>주문 정보</span>
          <span>직접 생성 시: imp_adm_T연도월일시간분초 ex)imp_adm_T202104131820 </span>
        </div>
        <div className={classes.inputRow}>
          <TextInput source="order_id" label="주문 ID (imp_uid)" variant="standard" fullWidth={true} />
        </div>
        <div className={classes.inputRow}>
          <DateTimeInput source="ordered_at" label="구매 일시" variant="standard" fullWidth={true} />
        </div>
      </SimpleForm>
    </Create>
  );
};

export default CreateWebinarAttendee;
