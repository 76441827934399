import React from 'react';
import { List, Datagrid, ShowButton, TextField, downloadCSV } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import jsonExport from 'jsonexport/dist';

const useStyles = makeStyles({
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
  },
  editButton: {
    fontWeight: 'bold',
  },
});

const CreatedAtField = ({ record, source }) => <div>{new Date(record.created_at).toLocaleString()}</div>;
const URLField = ({ record, source }) => <div>{process.env.REACT_APP_ENV === 'production' ? `https://heyjoyce.com/b2b/${record.url}` : `https://dev.heyjoyce.com/b2b/${record.url}`}</div>;

const exporter = (records) => {
  const postsForExport = records.map((record) => {
    let fileds = {};
    fileds['회사 이름'] = record.name;
    fileds['회사 코드'] = record.code;
    fileds['생성 일시'] = new Date(record.created_at).toLocaleString();
    fileds['기업교육 등록 주소'] = process.env.REACT_APP_ENV === 'production' ? `https://heyjoyce.com/b2b/${record.url}` : `https://dev.heyjoyce.com/b2b/${record.url}`;
    return fileds;
  });
  jsonExport(postsForExport, (err, csv) => {
    downloadCSV(csv, 'b2b_리스트');
  });
};

const ShowUserButton = (props) => {
  const classes = useStyles();
  return <ShowButton className={classes.showButton} {...props} />;
};

const ListB2B = (props) => {
  const classes = useStyles();

  return (
    <List {...props} title="B2B 기업 교육 리스트" bulkActionButtons={false} pagination={false} exporter={exporter}>
      <Datagrid classes={{ headerCell: classes.headerCell }} rowClick={false}>
        <TextField label="구분 이름" source="name" sortable={false} />
        <TextField label="회사 코드" source="code" sortable={false} />
        <CreatedAtField label="생성 일시" source="created_at" sortable={false} />
        <URLField label="기업교육 등록 주소" source="url" sortable={false} />
        <ShowUserButton label="" />
      </Datagrid>
    </List>
  );
};

export default ListB2B;
