import React from 'react';
import { Edit, SimpleForm, Toolbar, SaveButton, TextField, SelectField, DateField, BooleanInput, FunctionField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  section: {
    width: '100%',
    margin: '20px 0',
    border: '1px solid #cdcdcd',
  },
  fullWidth: {
    width: '100%',
  },
}));

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const rootType = [{ id: 'program', name: '프로그램' }];
const communityType = [
  { id: 0, name: '댓글' },
  { id: 1, name: '답글' },
];

const EditCommunity = (props) => {
  const classes = useStyles();

  return (
    <Edit undoable={false} title="커뮤니티(댓글) 편집" {...props}>
      <SimpleForm toolbar={<UserEditToolbar />} submitOnEnter={false} redirect="list">
        <TextField label="id" source="id" />
        <SelectField label="분류" source="depth" choices={communityType} />
        <div className={classes.section}></div>
        <SelectField label="최상위 타입" source="root_type" choices={rootType} />
        <TextField label="최상위 id" source="root_id" />
        <div className={classes.section}></div>
        <TextField label="작성자 id" source="data.author.id" />
        <TextField label="작성자" source="data.author.nickname" />
        <TextField label="내용" source="data.body" />
        <div className={classes.section}></div>
        <DateField label="작성일" source="created_at" showTime={true} />
        <DateField label="최종 수정일" source="updated_at" showTime={true} />
        <DateField label="삭제일" source="deleted_at" showTime={true} />
        <div className={classes.section}></div>
        <FunctionField
          addLabel={false}
          render={(record) =>
            record.depth === 0 && !record.deleted_at ? (
              <BooleanInput
                className={classes.fullWidth}
                label="상단 고정"
                source="is_pinned"
                helperText="상단 고정을 해제하면 작성자 정보와 함께 커뮤니티 영역에 노출됩니다. (작성자: 어드민 콘솔에 로그인한 계정)"
              />
            ) : (
              ''
            )
          }
        />
        <FunctionField addLabel={false} render={(record) => (!record.deleted_at ? <BooleanInput label="삭제" source="is_deleted" /> : '')} />
      </SimpleForm>
    </Edit>
  );
};

export default EditCommunity;
