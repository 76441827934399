import { adminAxios } from 'helpers/axios';

const orderProvider = {
  getList: async (resource, params) => {
    const { filter, pagination, sort } = params;
    const offset = (pagination.page - 1) * pagination.perPage;

    let queryParams = `?offset=${offset}&limit=${pagination.perPage}`;

    if (filter.method) {
      queryParams += `&method=${filter.method}`;
    }

    if (filter.status) {
      queryParams += `&status=${filter.status}`;
    }

    if (filter.range_start || filter.range_end) {
      if (!filter.range_start) {
        queryParams += `&createdAtRange=1900-01-01/${filter.range_end}`;
      } else if (!filter.range_end) {
        const now = new Date();
        const formatted_now = now.toISOString().split('T')[0];
        queryParams += `&createdAtRange=${filter.range_start}/${formatted_now}`;
      } else {
        queryParams += `&createdAtRange=${filter.range_start}/${filter.range_end}`;
      }
    }

    if (filter.search) {
      queryParams += `&search=${filter.search}`;
    }

    queryParams += `&ordering=${sort.order === 'DESC' ? '-' : ''}${sort.field}`;

    const { data } = await adminAxios({
      method: 'GET',
      uri: '/orders/',
      query: queryParams,
    });

    const results = data.results.map((item) => ({
      id: item.imp_uid,
      ...item,
    }));

    return {
      data: results,
      total: data.count,
    };
  },
  getOne: async (resource, params) => {
    const { data } = await adminAxios({
      method: 'GET',
      uri: `/orders/${params.id}/`,
    });

    return {
      data: {
        id: data.imp_uid,
        ...data,
      },
    };
  },
  update: async (resource, params) => {
    const { status } = params.data;
    const { data } = await adminAxios({
      method: 'PATCH',
      uri: `/orders/${params.id}/`,
      data: {
        status,
      },
    });

    return {
      data: {
        id: data.imp_uid,
        ...data,
      },
    };
  },
};

export default orderProvider;
