import { adminAxios } from 'helpers/axios';

const faqProvider = {
  getList: async (resource, params) => {
    const {
      filter: { opened, type },
      pagination: { page, perPage },
    } = params;
    const offset = (page - 1) * perPage;
    const {
      data: { count, results },
    } = await adminAxios({
      method: 'GET',
      uri: '/faq/',
      query: `?limit=${perPage}&offset=${offset}${opened ? `&opened=${opened}` : ''}${type ? `&type=${type}` : ''}`,
    });

    return {
      data: results,
      total: count,
    };
  },
  create: async (resource, params) => {
    const {
      data: { question = '', answer = '', opened = false, type = '전체' },
    } = params;

    const { data } = await adminAxios({
      method: 'POST',
      uri: '/faq/',
      data: { question, answer, opened, type },
    });

    return {
      data: data,
    };
  },
  getOne: async (resource, params) => {
    const { data } = await adminAxios({
      method: 'GET',
      uri: '/faq/',
      query: params.id + '/',
    });
    return {
      data: data,
    };
  },
  update: async (resource, params) => {
    const { id, question = '', answer = '', opened = false, type = '전체' } = params.data;

    const { data } = await adminAxios({
      method: 'PATCH',
      uri: '/faq/',
      data: { question, answer, opened, type },
      query: id + '/',
    });

    return {
      data: data,
    };
  },
  delete: async (resource, params) => {
    const { id } = params;
    const { data } = await adminAxios({
      method: 'DELETE',
      uri: '/faq/' + id + '/',
    });

    return {
      data: data,
    };
  },
};

export default faqProvider;
