function changeHTMLTag(htmlString) {
  //change <p> => <br>
  const regex1 = /<br>/g;
  const regex2 = /<[/]p><p>/g;
  const regex3 = /<br><[*]><br>/g;
  const regex4 = /<br><[*]>|<p><[*]><[/]p>/g;

  var newString = htmlString.replace(regex1, '<*>');
  newString = newString.replace(regex2, '<br>');
  newString = newString.replace(regex3, '</p><p>');
  newString = newString.replace(regex4, '');
  return newString;
}

function addImageTag(htmlString) {
  const regexPc = /imagePc<img /g;
  const regexMobile = /imageMobile<img /g;
  const regexPc2 = /<p>imagePc<[/]p><p><img/g;
  const regexMobile2 = /<p>imageMobile<[/]p><p><img/g;

  var newString = htmlString.replace(regexPc, '<img class="imagePc" ');
  newString = newString.replace(regexMobile, '<img class="imageMobile" ');
  newString = newString.replace(regexPc2, '<img class="imagePc" ');
  newString = newString.replace(regexMobile2, '<img class="imageMobile" ');
  return newString;
}

function replaceImageTag(htmlString) {
  const regexPc = /<img class="imagePc" /g;
  const regexMobile = /<img class="imageMobile" /g;
  var newString = htmlString.replace(regexPc, 'imagePc<img ');
  newString = newString.replace(regexMobile, 'imageMobile<img ');
  return newString;
}

function hasOrderError(contentList) {
  for (let i = 0; i < contentList.length; i++) {
    if (!('order' in contentList[i])) {
      return true;
    }
    if ('orderErrorState' in contentList[i] && contentList[i]['orderErrorState']) {
      return true;
    }
  }
  return false;
}

function hasALLDAYProgram(contentList) {
  for (const content of contentList) {
    if (content.snippet === 'PROGRAM_ALLDAY') {
      return true;
    }
  }
  return false;
}

const IMAGETYPE = {
  default: '',
  pc: 'imagePc',
  mobile: 'imageMobile',
};

const DEFAULT_COLOR = ['#000000', '#FFFFFF', '#F8F8F8', '#FDDB00', '#F04E44', '#2A3384', '#005950', '#FFE1E3', '#8CE7DA', '#1a1a1a'];

const emptyContent = {
  background_color: '#ffffff',
  rich_text: '',
  snippet: 'NEWIMAGE',
  data: {},
  order: 1,
  orderErrorState: false,
  isCollapsed: true,
};

const PROMOTION_INFO = {
  version: 'v2',
};

export { addImageTag, changeHTMLTag, replaceImageTag, IMAGETYPE, DEFAULT_COLOR, emptyContent, hasOrderError, hasALLDAYProgram, PROMOTION_INFO };
