/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getWebinar = /* GraphQL */ `
  query GetWebinar($id: ID!) {
    getWebinar(id: $id) {
      id
      type
      notice {
        title
        subtitle
        body
        program_name
        program_url
        prequestion_url
        feedback_url
      }
      scheduled_at
      is_all_user_access
      is_terminated
      speakers {
        profile_url
        title
        subtitle
        body
      }
      operators {
        id
        username
        email
        phone
        profile_url
        updated_at
        created_at
      }
      attendees {
        id
        username
        email
        phone
        updated_at
        created_at
        position
        membership_type
        program_id
        order_id
        ordered_at
        attendance
        push_token
      }
      programs {
        id
        type
        name
        original_price
        discount_price
        register_start_at
        register_end_at
        register_available
      }
      url
      pagecall {
        id
        type
        name
        is_terminated
      }
      ivs {
        id
        playback_url
        stream_endpoint
        stream_key
      }
      sendbird {
        channel_url
        name
        participant_count
        custom_type
      }
      updated_at
      created_at
    }
  }
`;

export const listWebinars = /* GraphQL */ `
  query ListWebinars($filter: TableWebinarFilterInput, $limit: Int, $nextToken: String) {
    listWebinars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        notice {
          title
        }
        type
        scheduled_at
        is_all_user_access
        is_terminated
        url
        updated_at
        created_at
      }
      nextToken
    }
  }
`;

export const listPrograms = /* GraphQL */ `
  query ListPrograms($filter: TableProgramFilterInput, $limit: Int) {
    listPrograms(filter: $filter, limit: $limit) {
      items {
        id
        type
        name
        register_start_at
        register_end_at
        register_available
        original_price
        discount_price
      }
    }
  }
`;

export const searchFeeds = /* GraphQL */ `
  query SearchFeeds($filter: TableFeedSearchInput, $limit: Int, $page: Int) {
    searchFeeds(filter: $filter, limit: $limit, page: $page) {
      items {
        id
        type
        category_id
        is_pinned
        tags
        updated_at
        created_at
        data {
          body
          title
          summary
          author {
            id
            profile_url
            username
            company
            job
            age
            is_public
          }
          thumbnail
          status
        }
      }
      total
    }
  }
`;
export const listFeeds = /* GraphQL */ `
  query ListFeeds($filter: TableFeedFilterInput, $limit: Int, $page: Int) {
    listFeeds(filter: $filter, limit: $limit, page: $page) {
      items {
        id
        type
        category_id
        is_pinned
        tags
        updated_at
        created_at
        view_count
        data {
          body
          summary
          title
          fellow_text
          author {
            id
            profile_url
            username
            company
            job
            age
            is_public
          }
          thumbnail
          fellow {
            id
            username
            company
            job
          }
          program {
            id
            name
            tags
          }
          status
        }
      }
      total
    }
  }
`;
export const getFeed = /* GraphQL */ `
  query GetFeed($id: ID!) {
    getFeed(id: $id) {
      id
      type
      category_id
      is_pinned
      tags
      updated_at
      created_at
      data {
        body
        title
        summary
        author {
          id
          username
          company
          job
          age
          profile_url
          username
        }
        thumbnail
        fellow {
          id
          username
          company
          job
        }
        program {
          id
          name
          tags
        }
        is_private
        status
      }
    }
  }
`;
export const listPromotions = /* GraphQL */ `
  query ListPromotions($limit: Int, $page: Int) {
    listPromotions(limit: $limit, page: $page) {
      items {
        id
        code
        stage
        title
        start_at
        end_at
        created_at
      }
    }
  }
`;

export const getPromotion = /* GraphQL */ `
  query GetPromotion($id: ID!) {
    getPromotion(id: $id) {
      id
      code
      contents {
        background_color
        id
        order
        rich_text
        snippet
        image_url_pc
        image_url_mobile
        image_title
        image_scroll
        data
        memo
      }
      end_at
      programs {
        discount_price
        id
        name
        type
        original_price
        register_available
        register_start_at
        register_end_at
      }
      stage
      start_at
      title
      is_final
      promotion_info
    }
  }
`;

export const listSerializedCoupons = /* GraphQL */ `
  query ListSerializedCoupons($filter: TableSerializedCouponFilterInput) {
    listSerializedCoupons(filter: $filter) {
      items {
        id
        label
        components {
          coupon {
            type
            expire_at
            coupon_name
            tags
          }
          amount
        }
        created_at
        is_valid
        used_at
        used_user_id
      }
      total
    }
  }
`;

export const listSerializedCouponLabels = /* GraphQL */ `
  query ListSerializedCouponLabels {
    listSerializedCouponLabels {
      items
      total
    }
  }
`;

export const listB2Bs = /* GraphQL */ `
  query ListB2Bs($filter: TableB2BFilterInput) {
    listB2Bs(filter: $filter) {
      items {
        id
        code
        name
        created_at
        url
      }
      total
    }
  }
`;

export const getB2BWithUsers = /* GraphQL */ `
  query GetB2BWithUsers($id: ID!) {
    getB2BWithUsers(id: $id) {
      id
      code
      name
      created_at
      url
      users {
        id
        username
        email
        phone
        company
        job
        gender
        created_at
      }
    }
  }
`;

export const listCommunities = /* GraphQL */ `
  query ListCommunities($filter: TableCommunityFilterInput, $limit: Int, $nextToken: String) {
    listCommunities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        root
        created_at
        deleted_at
        depth
        is_pinned
        data {
          author {
            id
            profile_url
            nickname
          }
          body
        }
        user_id
      }
      nextToken
      total
    }
  }
`;

export const getCommunity = /* GraphQL */ `
  query GetCommunity($id: ID!) {
    getCommunity(id: $id) {
      id
      root
      parent_comment_id
      created_at
      updated_at
      deleted_at
      depth
      is_pinned
      data {
        author {
          id
          profile_url
          nickname
        }
        body
      }
    }
  }
`;

export const listAppSettings = /* GraphQL */ `
  query ListAppSettings($filter: TableAppSettingFilterInput, $limit: Int, $nextToken: String) {
    listAppSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        type
        start_at
        end_at
        created_at
        updated_at
        data
      }
      nextToken
      total
    }
  }
`;

export const getAppSetting = /* GraphQL */ `
  query GetAppSetting($id: ID!) {
    getAppSetting(id: $id) {
      id
      group
      type
      start_at
      end_at
      created_at
      updated_at
      data
    }
  }
`;
