import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import { adminAxios } from 'helpers/axios';

const useStyles = makeStyles({
  tagContainer: {
    '& > *': {
      margin: '0 3px',
    },
  },
});

const filterList = {
  marketing: '마케팅 수신 동의',
  has_agreed_push: '푸시 수신 동의',
  is_staff: '스태프만',
  last_login_in_range: '일동안 방문한 적 있는 사람만',
  last_login_out_range: '일동안 방문한 적 없는 사람만',
};

const loginRange = [
  { id: 'in', name: '방문한 적 있는 사람만' },
  { id: 'out', name: '방문한 적 없는 사람만' },
];

const getReceiverList = async (inOut, range, pushStartAt, isStaff) => {
  let queryParams = '?marketing=true&has_agreed_push=true&stage=exclude_5';
  if (inOut && range) {
    queryParams += `&last_login_${inOut}_range=${range}&push_start_at=${pushStartAt}`;
  }
  if (isStaff) {
    queryParams += '&is_staff=true';
  }
  const { data } = await adminAxios({
    method: 'GET',
    uri: '/users/',
    query: queryParams,
  });
  return {
    data: data,
  };
};

const FilterField = ({ record }) => {
  const classes = useStyles();
  const filters = record.filter.split('&');
  return (
    <div>
      {filters.map((item) => {
        const filterItem = item.split('=');
        return (
          <span className={classes.tagContainer}>
            <Chip
              color="secondary"
              label={(filterItem[0] === 'last_login_in_range' || filterItem[0] === 'last_login_out_range' ? filterItem[1] : '') + filterList[filterItem[0]]}
              icon={<DoneIcon />}
            />
          </span>
        );
      })}
    </div>
  );
};

export { filterList, FilterField, loginRange, getReceiverList };
