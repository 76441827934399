import React, { cloneElement, useState } from 'react';
import {
  useShowController,
  AppBar,
  ArrayField,
  ImageField,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  TabbedShowLayout,
  Tab,
  Labeled,
  EditButton,
  TopToolbar,
  FunctionField,
  BooleanField,
} from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { EditWebinar } from '../Webinar';
import WebinarAttendeesList from './WebinarAttendeesList';
import Typography from '@material-ui/core/Typography';
import OperatingBox from './OperatingBox';
import Tooltip from '@material-ui/core/Tooltip';

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const PostShowActions = ({ basePath, data, record }) => (
  <TopToolbar>
    <EditButton basePath="/webinars" record={record} />
    {/* Add your custom actions */}
    {/* <Button color="primary" onClick={customAction}>
      Custom Action
    </Button> */}
  </TopToolbar>
);

const useStyles = makeStyles({
  button: { color: 'orange', padding: '1px' },
  link: { color: 'orange', padding: '2px' },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > .left': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
  },
});

const ShowWebinar = (props) => {
  const classes = useStyles();

  const {
    basePath, // deduced from the location, useful for action buttons
    loading, // return true if loading to fetch data
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version, // integer used by the refresh feature
  } = useShowController(props);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className={classes.header}>
        <div className="left">
          <Button
            component={Link}
            to={{
              pathname: '/webinars',
            }}
          >
            <ArrowBackIcon />
          </Button>
          <h3>{'웨비나 상세 정보'}</h3>
        </div>
        <EditButton basePath="/webinars" record={record} />
      </div>
      {cloneElement(props.children, {
        basePath,
        record,
        resource,
        version,
      })}
    </div>
  );
};

const DetailWebinar = (props) => {
  const classes = useStyles();
  const [webinarUrl, setWebinarUrl] = useState('');
  const [programUrl, setProgramUrl] = useState('');

  return (
    <ShowWebinar actions={<PostShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="웨비나 정보">
          <FunctionField
            label="ID"
            render={(record) => {
              setWebinarUrl(process.env.REACT_APP_WEB_URL + record['url']);
              setProgramUrl(process.env.REACT_APP_WEB_URL + record.notice['program_url']);
              return record['id'];
            }}
          />
          <CopyToClipboard text={webinarUrl}>
            <Tooltip title="URL 복사" arrow>
              <Button className={classes.button} {...props}>
                시청 URL &nbsp;
                <FileCopyOutlinedIcon style={{ fontSize: 15 }} />
              </Button>
            </Tooltip>
          </CopyToClipboard>
          <Box p="0.5em"></Box>
          <BooleanField label="웨비나 신청하지 않아도 시청 가능" source="is_all_user_access" defaultValue={false} />
          <TextField label="웨비나 타입" source="type" />
          <TextField label="웨비나 제목" source="notice.title" />
          <TextField label="웨비나 설명" source="notice.subtitle" />
          <DateField showTime="true" label="생성일" source="created_at" />
          <DateField showTime="true" label="진행일" source="scheduled_at" />
          <Box p="0.5em"></Box>
          <ArrayField label="연결 프로그램 리스트" source="programs">
            <Datagrid>
              <TextField source="id" />
              <TextField source="name" />
            </Datagrid>
          </ArrayField>
          <Box p="0.5em"></Box>
          <Labeled label="프로그램 상세 URL">
            <a className={classes.link} {...props} style={{ textDecoration: 'none' }} target="_blank" title="새창으로 열기" href={programUrl}>
              프로그램 상세 페이지로 이동 &nbsp;
              <OpenInNewIcon style={{ fontSize: 14 }} />
            </a>
          </Labeled>
          <Box p="0.5em"></Box>
          <ArrayField label="연사 리스트" source="speakers">
            <Datagrid>
              <ImageField label="연사 프로필" source="profile_url" />
              <TextField label="강연 주제" source="title" />
              <TextField label="연사 정보" source="subtitle" />
            </Datagrid>
          </ArrayField>
          <ArrayField label="호스트 리스트" source="operators">
            <Datagrid>
              <NumberField label="User ID" source="id" />
              <TextField label="이름" source="username" />
              <TextField label="이메일" source="email" />
              <TextField label="휴대폰 번호" source="phone" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="웨비나 운영" path="manage">
          <OperatingBox />
        </Tab>
        <Tab label="신청자 리스트" path="attendeesList">
          <WebinarAttendeesList {...props} />
        </Tab>
      </TabbedShowLayout>
    </ShowWebinar>
  );
};

export default DetailWebinar;
