import { adminAxios } from 'helpers/axios';

const pushProvider = {
  getList: async (resource, params) => {
    const { filter, pagination, sort } = params;
    const offset = (pagination.page - 1) * pagination.perPage;
    let queryParams = '?offset=' + offset + '&limit=' + pagination.perPage;
    if (filter.search) {
      queryParams += '&search=' + filter.search;
    }
    queryParams += `&ordering=${sort.order === 'DESC' ? '-' : ''}${sort.field}`;
    const { data } = await adminAxios({
      method: 'GET',
      uri: '/pushes/',
      query: queryParams,
    });

    return {
      data: data.results,
      total: data.count,
    };
  },
  getOne: async (resource, params) => {
    const { data } = await adminAxios({
      method: 'GET',
      uri: '/pushes/',
      query: params.id + '/',
    });
    const filters = data.filter.split('&');
    filters.map((item) => {
      const filterItem = item.split('=');
      data[filterItem[0]] = filterItem[1];
      if (filterItem[0] === 'last_login_in_range') {
        data['in_out'] = 'in';
        data['range'] = filterItem[1];
      }
      if (filterItem[0] === 'last_login_out_range') {
        data['in_out'] = 'out';
        data['range'] = filterItem[1];
      }
    });
    return {
      data: data,
    };
  },
};

export default pushProvider;
