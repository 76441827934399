import React, { useEffect, useState, useRef } from 'react';
import { useQuery, gql } from '@apollo/client';
import { getPromotion, listPrograms, listPromotions } from 'graphql/queries';
import { client } from 'graphql/providers';
import { updatePromotion } from 'graphql/mutations';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import 'react-quill/dist/quill.snow.css';
import { addImageTag, replaceImageTag, emptyContent, hasALLDAYProgram, PROMOTION_INFO } from './CreateHelpers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ContentList from './ContentListComponent';
import ProgramList from './ProgramListConponent';
import JsonListComponent from './JsonListComponent';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    '& .MuiFormControl-marginNormal': {
      marginTop: '0px',
    },
    '& ul[class^="RaSimpleFormIterator"]': {
      marginTop: '0px',
    },
    '& div[class^="RaAutocompleteArrayInput"]': {
      flexGrow: '0',
    },
    '& .ra-rich-text-input .ql-editor': {
      backgroundColor: 'white',
      border: '1px solid #cdcdcd',
    },
  },
  leftForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    marginRight: '15px',
    padding: '15px',
  },
  rightForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    borderLeft: '1px solid #e5e5e5',
    paddingLeft: '30px',
    padding: '15px',
  },
  formLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    marginTop: '20px',
    borderTop: '1px solid #e5e5e5',
    paddingTop: '20px',
    '& > span:nth-child(2)': {
      color: 'gray',
      fontWeight: 'normal',
      marginLeft: '12px',
      fontSize: '14px',
    },
    '&.first': {
      paddingTop: '0px',
      borderTop: 'none',
    },
  },
  layout: {
    marginTop: '15px',
  },
}));

const EditPromotion = (props) => {
  const classes = useStyles();
  const { loading, data } = useQuery(
    gql`
      ${listPrograms}
    `,
    {
      variables: {
        filter: {
          opened: true,
        },
        limit: 50,
      },
    }
  );

  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [contentList, _setContentList] = useState([{ ...emptyContent }]);
  const [promotionStart, setPromotionStart] = useState('');
  const [promotionEnd, setPromotionEnd] = useState('');
  const [code, setCode] = useState('');
  const [title, setTitle] = useState('');
  const [stage, setStage] = useState('');
  const [promotionInfo, setPromotionInfo] = useState(JSON.stringify(PROMOTION_INFO));
  const [infoVisible, setInfoVisible] = useState(false);

  const [titleState, setTitleState] = useState(true);
  const [stageState, setStageState] = useState(false);
  const [isFinalPromotion, setIsFinalPromotion] = useState();

  const [promotions, setPromotions] = useState();
  const contentListRef = useRef();

  const setContentList = (value) => {
    contentListRef.current = value;
    _setContentList(value);
  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await client.query({
        query: gql`
          ${listPromotions}
        `,
        variables: {
          limit: 10,
          page: 1,
        },
      });

      if (data) {
        setPromotions([...data.listPromotions.items]);
      }

      const { data: promotionData } = await client.query({
        query: gql`
          ${getPromotion}
        `,
        variables: {
          id: props.match.params.id,
        },
      });

      if (promotionData) {
        const promotionItem = promotionData.getPromotion;
        setContentList(
          promotionItem.contents
            ? promotionItem.contents.map((content) => {
                //mData = for data migration
                const mData = {
                  image_url_pc: content?.image_url_pc,
                  image_url_mobile: content?.image_url_mobile,
                  image_title: content?.image_title,
                  image_scroll: content?.image_scroll,
                };

                return {
                  ...content,
                  data: { ...mData, ...JSON.parse(content.data) },
                  rich_text: replaceImageTag(content.rich_text),
                  orderErrorState: false,
                  isCollapsed: true,
                };
              })
            : []
        );
        setSelectedPrograms(promotionItem.programs ? promotionItem.programs : []);
        setStage(promotionItem.stage);
        setTitle(promotionItem.title);
        setCode(promotionItem.code);
        setPromotionStart(promotionItem.start_at);
        setPromotionEnd(promotionItem.end_at);
        setIsFinalPromotion(promotionItem.is_final);
        setPromotionInfo(promotionItem?.promotion_info);
      }
    }

    fetchData();
  }, []);

  if (loading) return <p>Loading ...</p>;

  const { items: programs } = data.listPrograms;

  function setPromotionDate(value) {
    var start = '';
    var end = '';
    for (var i = 0; i < value.length; i++) {
      if (start === '' || start > value[i]['register_start_at']) {
        start = value[i]['register_start_at'];
      }
      if (end === '' || end < value[i]['register_end_at']) {
        end = value[i]['register_end_at'];
      }
    }
    setPromotionStart(start);
    setPromotionEnd(end);
  }

  const handleSelectProgram = (value) => {
    setSelectedPrograms(value);
    setPromotionDate(value);
  };

  const handleContentList = (value) => {
    setContentList(value);
  };

  const handleCodeChange = (e) => {
    var code = e.target.value;
    var promotion = null;

    for (let key in promotions) {
      if (promotions[key]['code'] === code) {
        promotion = promotions[key];
        break;
      }
    }

    if (promotion) {
      setTitleState(true);
      setTitle(promotion['title']);
    } else {
      setTitleState(false);
      setTitle('');
    }
    setCode(code);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleStageChange = (e) => {
    setStage(e.target.value);

    var promotion = null;
    for (let key in promotions) {
      if (promotions[key]['code'] === code && promotions[key]['stage'] === e.target.value) {
        promotion = promotions[key];
        break;
      }
    }

    if (promotion) {
      setStageState(true);
      alert('프로모션 안에 동일한 오픈 단계가 있습니다!');
    } else setStageState(false);
  };

  async function updateData() {
    await client.mutate({
      mutation: gql`
        ${updatePromotion}
      `,
      variables: {
        input: {
          id: props.match.params.id,
          code,
          title,
          stage,
          start_at: promotionStart,
          end_at: promotionEnd,
          is_final: isFinalPromotion,
          promotion_info: promotionInfo,
          contents: contentList.map(function (item, index) {
            var contentItem = {};
            contentItem['id'] = index.toString();
            contentItem['background_color'] = item['background_color'];
            contentItem['order'] = item.order;
            contentItem['rich_text'] = addImageTag(item['rich_text']);
            contentItem['snippet'] = item['snippet'];
            contentItem['image_url_pc'] = item['image_url_pc'];
            contentItem['image_url_mobile'] = item['image_url_mobile'];
            contentItem['image_title'] = item['image_title'];
            contentItem['image_scroll'] = item['image_scroll'];
            contentItem['data'] = JSON.stringify(item['data']);
            contentItem['memo'] = item['memo'];
            return contentItem;
          }),
          programs: selectedPrograms,
        },
      },
    });

    alert('수정되었습니다!');
    window.location.reload();
  }

  const handleEndSwitchChange = (e) => {
    setIsFinalPromotion(e.target.checked);
  };

  const handleInfoChange = (e) => {
    setPromotionInfo(e.target.value);
  };

  const handleSave = () => {
    if (code !== '' && title !== '' && stage !== '' && promotionStart !== '' && promotionEnd !== '' && programs.length !== 0) {
      updateData();
    } else {
      alert('모든 항목을 채워주세요!');
    }
  };

  return (
    <div>
      <div className={`${classes.formLabel} first`}>
        <span>
          <h1>프로모션 수정하기</h1>
        </span>
        <span>
          <Button variant="outlined" onClick={handleSave}>
            수정하기
          </Button>
        </span>
      </div>

      <div className={classes.formWrapper}>
        <div className={classes.leftForm}>
          <ContentList contentList={contentList} handleContentList={handleContentList} contentListRef={contentListRef} />
        </div>
        <div className={classes.rightForm}>
          <h3 style={{ display: 'flex', alignItems: 'center' }}>
            <span>프로모션 정보(기타 옵션 추가)</span>
            <span>
              <VisibilityIcon style={{ marginLeft: '8px' }} onClick={() => setInfoVisible(!infoVisible)} />
            </span>
          </h3>
          <div style={{ fontSize: 12, marginTop: '-10px', marginBottom: '10px', lineHeight: '130%', color: 'red' }}>
            {infoVisible && <div style={{ color: 'black' }}>{promotionInfo}</div>}
            기본(필수) : version / v2
            <br />
            다크모드 : is_dark_mode / true
            <br />
            플로팅 문구 : floating_title / 티켓 긴급연장, 00% 할인
            <br />
            버튼배경색(없을시 기본 노랑) : button_color / #fddb00
            <br />
            버튼글씨색(없을 시 기본 검정) : button_text_color / #1a1a1a
            <br />
            배경색(없을 시 기본 흰색) : background_color / #ffffff
            <br />
            플로팅 버튼 ON/OFF (없을시 기본 ON, 끄고싶다면 다음과 같이 입력 ) : is_floating_mode / false
          </div>
          <JsonListComponent jsonData={promotionInfo} setJsonData={setPromotionInfo} />

          <h3>프로모션 기본 정보(일반)</h3>
          <TextField
            required
            className={classes.layout}
            variant="outlined"
            value={code}
            label="프로모션 코드"
            helperText="프로모션 url에 사용될 코드를 입력해주세요 ex) conjoyce8"
            onChange={handleCodeChange}
            error={code.length === 0}
          />
          <TextField
            required
            className={classes.layout}
            label="프로모션 타이틀"
            helperText="ex) 콘조이스 <기획이 전부다!>"
            value={title}
            error={title.length === 0}
            onChange={handleTitleChange}
            InputProps={{
              readOnly: titleState,
            }}
          />
          <TextField
            required
            className={classes.layout}
            label="프로모션 단계"
            helperText={'오픈 단계를 입력해주세요 ex) 얼리버드 1차'}
            value={stage}
            onChange={handleStageChange}
            error={stageState || stage.length === 0}
          />
          <TextField
            className={classes.layout}
            label="프로모션 시작 날짜"
            value={new Date(promotionStart).toLocaleString()}
            helperText="프로그램을 추가하면 자동으로 채워집니다."
            error={promotionStart.length === 0}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            className={classes.layout}
            label="프로모션 종료 날짜"
            value={new Date(promotionEnd).toLocaleString()}
            helperText="프로그램을 추가하면 자동으로 채워집니다."
            error={promotionStart.length === 0}
            InputProps={{
              readOnly: true,
            }}
          />
          <FormControlLabel label="프로모션 종료 이후에도 노출(최종 페이지)" control={<Switch checked={isFinalPromotion ? isFinalPromotion : false} onChange={handleEndSwitchChange} />} />
          <div className={`${classes.formLabel} first`}>
            <span>프로그램</span>
            <span>프로모션에 추가할 프로그램을 선택해주세요</span>
          </div>
          <ProgramList programs={programs} selectedPrograms={selectedPrograms} handleSelectProgram={handleSelectProgram} />
        </div>
      </div>
    </div>
  );
};

export default EditPromotion;
