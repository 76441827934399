import { client } from 'graphql/providers';
import { gql } from '@apollo/client';
import { listB2Bs, getB2BWithUsers } from 'graphql/queries';
import { createB2B as createB2BMutation } from 'graphql/mutations';

const b2bProvider = {
  getList: async (resource, params) => {
    const { data } = await client.query({
      query: gql`
        ${listB2Bs}
      `,
      variables: {
        filter: {},
      },
    });
    return {
      data: data.listB2Bs.items.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
      total: data.listB2Bs.total,
    };
  },
  getOne: async (resource, params) => {
    const { id } = params;
    const { data } = await client.query({
      query: gql`
        ${getB2BWithUsers}
      `,
      variables: {
        id,
      },
    });

    return {
      data: data.getB2BWithUsers,
    };
  },
  create: async (resource, params) => {
    const { name, code } = params.data;
    const {
      data: { createB2B },
    } = await client.mutate({
      mutation: gql`
        ${createB2BMutation}
      `,
      variables: {
        input: {
          name,
          code,
        },
      },
    });
    return {
      data: createB2B,
    };
  },
};

export default b2bProvider;
