const industryList = [
  { id: '금융/은행업', name: '금융/은행업' },
  { id: 'IT/정보통신업', name: 'IT/정보통신업' },
  { id: '판매/유통업', name: '판매/유통업' },
  { id: '제조/생산/화학업', name: '제조/생산/화학업' },
  { id: '교육업', name: '교육업' },
  { id: '건설업', name: '건설업' },
  { id: '의료/사회복지업', name: '의료/사회복지업' },
  { id: '숙박/음식점업', name: '숙박/음식점업' },
  { id: '부동산업', name: '부동산업' },
  { id: '사업 시설 관리 및 임대업', name: '사업 시설 관리 및 임대업' },
  { id: '전문/과학/기술 서비스업', name: '전문/과학/기술 서비스업' },
  { id: '문화/예술/디자인/스포츠/여가서비스업', name: '문화/예술/디자인/스포츠/여가서비스업' },
  { id: '서비스업', name: '서비스업' },
  { id: '공공행정/국방', name: '공공행정/국방' },
  { id: '기관/협회업', name: '기관/협회업' },
  { id: '농업/임업/어업/광업', name: '농업/임업/어업/광업' },
  { id: '운수 및 창고업', name: '운수 및 창고업' },
  { id: '구분 외 업종', name: '구분 외 업종' },
];

const positionList = [
  { id: '경영・관리직(대표・임원 등)', name: '경영・관리직(대표・임원 등)' },
  { id: '경영지원(HR,총무 등)', name: '경영지원(HR,총무 등)' },
  { id: '사무・행정직', name: '사무・행정직' },
  { id: '연구개발직', name: '연구개발직' },
  { id: '마케팅・광고・홍보직', name: '마케팅・광고・홍보직' },
  { id: 'IT개발・기획직', name: 'IT개발・기획직' },
  { id: '투자・컨설팅직', name: '투자・컨설팅직' },
  { id: '서비스직', name: '서비스직' },
  { id: '영업・판매직', name: '영업・판매직' },
  { id: '교육직', name: '교육직' },
  { id: '법률직', name: '법률직' },
  { id: '정부・공공・비영리직', name: '정부・공공・비영리직' },
  { id: '종교직', name: '종교직' },
  { id: '경찰・소방・군인・특수직', name: '경찰・소방・군인・특수직' },
  { id: '보건・의료직', name: '보건・의료직' },
  { id: '디자인직', name: '디자인직' },
  { id: '예술・미디어・방송직', name: '예술・미디어・방송직' },
  { id: '스포츠직', name: '스포츠직' },
  { id: '물류・유통・무역직', name: '물류・유통・무역직' },
  { id: '건설직', name: '건설직' },
  { id: '생산・제조직', name: '생산・제조직' },
  { id: '시장조사・리서치직', name: '시장조사・리서치직' },
  { id: '농림어업직', name: '농림어업직' },
  { id: '구분 외 직종', name: '구분 외 직종' },
];

export { industryList, positionList };
