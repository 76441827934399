import React from 'react';
import { Edit, SimpleForm, TextField, ImageField, SelectInput, TopToolbar, ShowButton } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

const PostEditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const status = [
  { id: 'pending', name: '승인 대기' },
  { id: 'accepted', name: '승인' },
  { id: 'rejected', name: '거절' },
];

const AnswerSection = ({ record }) => {
  const answers = record?.data?.body?.split('q1=')[1]?.split('q2=');
  return (
    <div>
      <p>
        <b>Q1.펠로우로서 하고 싶은 활동은?</b>
        <br />
        {answers[0].replace('[', '').replace('],', '').replace(']', '')}
      </p>
      <p>
        <b>Q2.내가 만들고 싶은 콘텐츠는?</b>
        <br />
        {answers[1].replace('}', '')}
      </p>
    </div>
  );
};

const EditApply = (props) => (
  <Edit mutationMode="pessimistic" title="펠로우 지원 관리" actions={<PostEditActions />} {...props}>
    <SimpleForm submitOnEnter={false}>
      <TextField label="ID" source="data.author.id"></TextField>
      <TextField label="이름" source="data.author.username"></TextField>
      <TextField label="나이" source="data.author.age"></TextField>
      <TextField label="회사" source="data.author.company"></TextField>
      <TextField label="하는 일" source="data.author.job"></TextField>
      <ImageField label="프로필사진" source="data.author.profile_url"></ImageField>
      <AnswerSection />
      <SelectInput label="승인 상태" source="data.status" choices={status} />
    </SimpleForm>
  </Edit>
);

export default EditApply;
