import React from 'react';
import useAdminApi from 'hooks/useAdminApi';
import { Edit, SimpleForm, TextInput, SelectInput, BooleanInput, required, TopToolbar, ShowButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { CustomEditor } from 'components/CustomEditor';

const PostEditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '50ch',
  },
}));

const EditFeed = (props) => {
  const classes = useStyles();

  const { data: categories } = useAdminApi({
    method: 'GET',
    url: '/categories/',
  });

  const { data: fellows } = useAdminApi({
    method: 'GET',
    url: '/users/?stage=20&limit=200&offset=0',
  });

  if (!(categories && fellows)) {
    return <p>Loading</p>;
  }

  return (
    <Edit undoable={false} title="피드 편집하기" actions={<PostEditActions />} {...props}>
      <SimpleForm submitOnEnter={false}>
        <SelectInput className={classes.layout} validate={[required()]} label="타입" source="type" choices={[{ id: 'qna', name: 'Q&A' }]} defaultValue="qna" variant="outlined" />
        <TextInput className={classes.layout} validate={[required()]} label="한줄 질문" source="data.title" variant="standard" />
        <CustomEditor
          variant="standard"
          label="답변"
          source="data.body"
          toolbar={[
            [{ header: [1, 2, 3, 4, 5, 6, false] }, 'bold', 'italic', 'underline', { align: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
          ]}
        />
        <BooleanInput source="is_pinned" label="메인 노출" initialValue={true} />
      </SimpleForm>
    </Edit>
  );
};

export default EditFeed;
