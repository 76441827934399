import { adminAxios } from 'helpers/axios';

const USER_COUNT_LIMIT = 25;

const searchUserProvider = {
  getList: async (resource, params) => {
    const offset = (params.pagination.page - 1) * 1;
    const searchParams = '&search=' + (Object.prototype.hasOwnProperty.call(params.filter, 'q') ? params.filter.q : '');

    const { data } = await adminAxios({
      method: 'GET',
      uri: '/users/',
      query: params.filter.search ? '?search=' + params.filter.search : '' + '?limit=' + USER_COUNT_LIMIT + '&offset=' + offset + searchParams,
    });

    let initialData = data.results;

    // 선택된 유저가 리스트에 들어가 있지 않은 경우 검색해서 추가해줌
    if (Object.prototype.hasOwnProperty.call(params.filter, 'users')) {
      for await (const userId of params.filter.users) {
        if (!initialData.some((user) => user.id === userId)) {
          const { data } = await adminAxios({
            method: 'GET',
            uri: `/users/${userId.toString()}`,
            query: '',
          });

          if (data) {
            initialData.push(data);
          }
        }
      }
    }

    return {
      data: initialData,
      total: data.count,
    };
  },
};

export default searchUserProvider;
