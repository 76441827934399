const EVENT = Object.freeze({
  webinar: 'WEBINAR',
});

const ACTIONS = Object.freeze({
  terminated: 'TERMINATED',
  teachableRegistered: 'TEACHABLE_REGISTERED',
});

export { EVENT, ACTIONS };
