import React, { useState, useCallback, useRef } from 'react';
import { apiAxios } from 'helpers/axios';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { makeStyles } from '@material-ui/core/styles';
import { BlockPicker, TwitterPicker } from 'react-color';
import { IMAGETYPE, DEFAULT_COLOR, emptyContent } from './CreateHelpers';

import 'react-quill/dist/quill.snow.css';

const snippets = Object.freeze({
  DEFAULT: { key: 'DEFAULT', menu: '기본' },
  IMAGE: { key: 'IMAGE', menu: 'PC/모바일 이미지 구분' },
  PROGRAM_ALLDAY: { key: 'PROGRAM_ALLDAY', menu: '프로그램 ALL DAY' },
  PROGRAM_ONEDAY: { key: 'PROGRAM_ONEDAY', menu: '프로그램 ONE DAY' },
  NEWIMAGE: { key: 'NEWIMAGE', menu: '뉴이미지' },
  HEADER: { key: 'HEADER', menu: '헤더이미지' },
  QUESTION: { key: 'QUESTION', menu: '문의하기' },
  SHARE: { key: 'SHARE', menu: '공유하기' },
  BENEFIT: { key: 'BENEFIT', menu: '회돈내산' },
});

const useStyles = makeStyles((theme) => ({
  formLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    marginTop: '20px',
    borderTop: '1px solid #e5e5e5',
    paddingTop: '20px',
    '& > span:nth-child(2)': {
      color: 'gray',
      fontWeight: 'normal',
      marginLeft: '12px',
      fontSize: '14px',
    },
    '&.first': {
      paddingTop: '0px',
      borderTop: 'none',
    },
  },
  backgroundLabel: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold',
    marginTop: '15px',
  },
  basicText: {
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '30px 10px 10px 0px',
    float: 'center',
  },
  subText: {
    fontSize: '14px',
    color: 'gray',
    float: 'center',
  },
  rightButtonForm: {
    float: 'right',
  },
  paperWrapper: {
    margin: '16px 0px',
  },
  orderInput: {
    width: '70px',
    paddingLeft: '10px',
  },
}));

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  border-radius: 4px;

  div {
    display: flex;
    align-items: center;
  }

  svg:first-child {
    width: 20px;
    height: 20px;
    &:hover {
      cursor: move;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

const ContentBody = styled.div`
  display: block;
  padding: 18px;
  border-top: 1px solid #ededed;

  &.collapsed {
    display: none;
  }
`;

const ImageItem = styled(ListItem)`
  img {
    max-width: 70%;
  }
`;

const ContentItemArea = styled.div`
  display: flex;
  flex-direction: column;

  textarea {
    width: 95%;
    margin: 0;
    padding: 10px;
    font-size: 14px;
  }
`;

const ButtonsArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px 10px 10px;
  padding: 8px;
  font-size: 14px;
  border: solid;

  div {
    margin: 10px 0;
  }

  input {
    padding: 8px;
  }
`;

const MemoArea = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  div {
    margin-right: 8px;
  }
`;

const NEW_SNIPPET = ['NEWIMAGE', 'SHARE', 'BENEFIT', 'HEADER'];

const NEW_IMAGE_DATA = Object.freeze({
  imageScroll: 'image_scroll',
  imageTitle: 'image_title',
  imageUrlPC: 'image_url_pc',
  imageUrlMobile: 'image_url_mobile',
  backgroundColor: 'background_color',
});

const SHARE_DATA = Object.freeze({
  kakaoThumbnail: 'kakao_thumbnail',
  kakaoTitle: 'kakao_title',
  kakaoText: 'kakao_text',
  linkText: 'link_text',
});

const BENEFIT_DATA = Object.freeze({
  benefitText: 'benefit_text',
  benefitTitle: 'benefit_title',
  benefitIllustration: 'benefit_illustration',
  benefitButton: { buttonText: 'button_text', buttonLink: 'button_link' },
});

const SNIPPET_DATA = Object.freeze({
  NEW_IMAGE_DATA,
  SHARE_DATA,
  BENEFIT_DATA,
});

const NewImageContent = ({ index, data = {}, setData, newFileInput }) => {
  const handleChange = (event, key) => {
    data[key] = event.target.value;
    setData(index, data);
  };

  const handleSwitchChange = (event, key) => {
    data[key] = event.target.checked;
    setData(index, data);
  };

  return (
    <ContentItemArea>
      <FormControlLabel label="모바일 스크롤" control={<Switch checked={data?.[NEW_IMAGE_DATA.imageScroll]} onChange={(e) => handleSwitchChange(e, NEW_IMAGE_DATA.imageScroll)} />} />
      <h3> {'배경색(선택)'}</h3>
      <textarea value={data?.[NEW_IMAGE_DATA.backgroundColor]} placeholder={'#FFFFFF'} onChange={(e) => handleChange(e, NEW_IMAGE_DATA.backgroundColor)} />
      <h3> {'타이틀(선택)'}</h3>
      <textarea
        value={data?.[NEW_IMAGE_DATA.imageTitle]}
        placeholder={'이미지 상단 타이틀을 입력해주세요.\n(스페이스 입력시 여백을 줄 수 있습니다.)'}
        onChange={(e) => handleChange(e, NEW_IMAGE_DATA.imageTitle)}
      />
      <h3> {'NEW PC 이미지'}</h3>
      <input onChange={(e) => newFileInput(index, e, NEW_IMAGE_DATA.imageUrlPC)} type="file" accept="image/*" />
      <h3>{'NEW Mobile 이미지'}</h3>
      <input onChange={(e) => newFileInput(index, e, NEW_IMAGE_DATA.imageUrlMobile)} type="file" accept="image/*" />
      <h3>PC</h3>
      <ImageItem>
        <img src={data?.[NEW_IMAGE_DATA.imageUrlPC]} alt="" />
      </ImageItem>
      <h3>Mobile</h3>
      <ImageItem>
        <img src={data?.[NEW_IMAGE_DATA.imageUrlMobile]} alt="" />
      </ImageItem>
    </ContentItemArea>
  );
};

const ShareContent = ({ index, data = {}, setData, newFileInput }) => {
  const KAKAO_TITLE_PLACEHOLDER = '헤이조이스 온라인 컨퍼런스 <커리어를 넓히는 5대 원칙>';
  const KAKAO_TEXT_PLACEHOLDER = '변화를 주어야 할까? 이대로 적응해야 할까? 나만의 커리어 액션 플랜을 세워보아요!';
  const LINK_PLACEHOLDER =
    '평생 직장은 사라졌지만 50년 이상은 일해야 하는 시대!\n나만의 커리어 세계관이 있다면\n끊임없는 변화와 적응 사이에서도\n나의 커리어를 발전시킬 수 있어요.\n커리어 세계관을 확장하는 방법은 사람마다 달라요.\n나에게 맞는 포지션으로 커리어 전환부터\n사이드 프로젝트로 스스로 성장하는 법까지!\n7명의 여성 프로페셔널이\n나만의 세계에서 커리어를 지키고 확장하는 노하우와 경험을 공유합니다.';

  const handleChange = (event, key) => {
    data[key] = event.target.value;
    setData(index, data);
  };

  return (
    <ContentItemArea>
      <h3>{'카카오 공유하기 썸네일'}</h3>
      <input onChange={(e) => newFileInput(index, e, SHARE_DATA.kakaoThumbnail)} type="file" accept="image/*" />
      <ImageItem>
        <img src={data?.[SHARE_DATA.kakaoThumbnail]} alt="" />
      </ImageItem>
      <h3>카카오 공유하기 제목</h3>
      <textarea value={data?.[SHARE_DATA.kakaoTitle]} placeholder={KAKAO_TITLE_PLACEHOLDER} onChange={(e) => handleChange(e, SHARE_DATA.kakaoTitle)}></textarea>
      <h3>카카오 공유하기 본문</h3>
      <textarea value={data?.[SHARE_DATA.kakaoText]} placeholder={KAKAO_TEXT_PLACEHOLDER} onChange={(e) => handleChange(e, SHARE_DATA.kakaoText)}></textarea>
      <h3>링크 공유하기</h3>
      <textarea style={{ height: 200 }} value={data?.[SHARE_DATA.linkText]} placeholder={LINK_PLACEHOLDER} onChange={(e) => handleChange(e, SHARE_DATA.linkText)} />
    </ContentItemArea>
  );
};

const BenefitContent = ({ index, data = { benefit_buttons: [] }, setData, newFileInput }) => {
  const [buttonCount, setButtonCount] = useState(data?.benefit_buttons?.length || 0);

  const BENEFIT_TITLE_PLACEHOLDER = '‘회돈내산’ 하세요!';
  const BENEFIT_TEXT_PLACEHOLDER = '회사 돈으로 내가 산다.\n법인카드 결제 • 지출 증빙용 현금영수증 발급 가능.\n버튼을 눌러 프로그램 결제&증빙 가이드를 확인해 주세요.';
  const BUTTON_TEXT_PLACEHOLDER = '결제&증빙가이드 확인하기';
  const BUTTON_LINK_PLACEHOLDER = 'https://heyjoyce.com/notice/59';

  const handleChange = (event, key) => {
    data[key] = event.target.value;
    setData(index, data);
  };

  const handleButtonChange = (event, i, key) => {
    data.benefit_buttons[i][key] = event.target.value;
    setData(index, data);
  };

  const handleAddButton = () => {
    data.benefit_buttons = data?.benefit_buttons ? [...data?.benefit_buttons, { button_text: '', button_link: '' }] : [{ button_text: '', button_link: '' }];
    setData(index, data);
    setButtonCount(data.benefit_buttons.length);
  };

  const handlePopButton = () => {
    if (buttonCount <= 0) return;

    data.benefit_buttons = data.benefit_buttons.filter((_, i) => i !== buttonCount - 1);
    setData(index, data);
    setButtonCount(data.benefit_buttons.length);
  };

  return (
    <ContentItemArea>
      <h3>일러스트(정사각 이미지 jpg/png로 올려주세요)</h3>
      <input onChange={(e) => newFileInput(index, e, BENEFIT_DATA.benefitIllustration)} type="file" accept="image/*" />
      {data?.[BENEFIT_DATA.benefitIllustration] && (
        <ImageItem style={{ width: 300, height: 300 }}>
          <img src={data?.[BENEFIT_DATA.benefitIllustration]} alt="" />
        </ImageItem>
      )}
      <h3>제목</h3>
      <textarea value={data?.[BENEFIT_DATA.benefitTitle]} placeholder={BENEFIT_TITLE_PLACEHOLDER} onChange={(e) => handleChange(e, BENEFIT_DATA.benefitTitle)}></textarea>
      <h3>본문</h3>
      <textarea style={{ height: 65 }} value={data[BENEFIT_DATA.benefitText]} placeholder={BENEFIT_TEXT_PLACEHOLDER} onChange={(e) => handleChange(e, BENEFIT_DATA.benefitText)}></textarea>
      <h3 style={{ display: 'flex', alignItems: 'center' }}>
        <span>버튼(옵션)</span>
        <span style={{ display: 'flex', alignItems: 'center' }} onClick={handleAddButton} size="small">
          <AddCircleOutlineIcon />
        </span>
        <span> </span>
        <span style={{ display: 'flex', alignItems: 'center' }} onClick={handlePopButton}>
          <RemoveCircleOutlineIcon />
        </span>
      </h3>

      {buttonCount ? (
        data?.benefit_buttons?.map((button, i) => (
          <ButtonsArea key={i}>
            <div>버튼 텍스트</div>
            <input
              value={button?.[BENEFIT_DATA.benefitButton.buttonText]}
              placeholder={BUTTON_TEXT_PLACEHOLDER}
              onChange={(e) => handleButtonChange(e, i, BENEFIT_DATA.benefitButton.buttonText)}
            ></input>
            <div>버튼 링크</div>
            <input
              value={button?.[BENEFIT_DATA.benefitButton.buttonLink]}
              placeholder={BUTTON_LINK_PLACEHOLDER}
              onChange={(e) => handleButtonChange(e, i, BENEFIT_DATA.benefitButton.buttonLink)}
            ></input>
          </ButtonsArea>
        ))
      ) : (
        <div>버튼 없음</div>
      )}
    </ContentItemArea>
  );
};

const ContentList = (props) => {
  const { contentList, handleContentList, contentListRef } = props;

  const draggingItem = useRef();
  const dragOverItem = useRef();
  const contentListWrapper = useRef();

  const classes = useStyles();
  const [colorList, setColorList] = useState(DEFAULT_COLOR);
  const [selectedColor, setSelectedColor] = useState('#FDDB00');
  const [isPaletteCollapsed, setIsPaletteCollapsed] = useState(true);

  const modules = {
    toolbar: [[{ header: [1, 2, 3, false] }], [{ color: colorList }], ['bold', 'italic', 'underline', 'strike'], [{ align: [] }], ['link', 'video'], ['clean']],
  };

  const formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'link', 'video', 'align', 'color', 'image', 'clean'];

  const handlePalletInput = (color) => {
    setSelectedColor(color['hex']);
  };

  const handleAdd = () => {
    const addedList = contentListRef.current.concat({ ...emptyContent, data: {}, isCollapsed: false });
    addedList[addedList.length - 1]['order'] = addedList.length;
    handleContentList(addedList);

    const positionY = contentListWrapper.current.offsetTop + contentListWrapper.current.offsetHeight - 50; //50 is navigation height
    window.scrollTo({ top: positionY, behavior: 'smooth' });
  };

  const handleDelete = (indexToDelete) => (content) => {
    let deletedList = contentListRef.current.slice();
    deletedList.splice(indexToDelete, 1);
    handleContentList(deletedList);
  };

  const handleChangeInput = (index, value) => {
    let updatedList = contentListRef.current.slice();
    updatedList[index]['rich_text'] = value;
    handleContentList(updatedList);
  };

  const handleBackgroundInput = (color, event, index) => {
    let updatedList = contentListRef.current.slice();
    updatedList[index]['background_color'] = color.hex;
    handleContentList(updatedList);
  };

  const handleSnippetChange = (index, event) => {
    let updatedList = contentListRef.current.slice();
    updatedList[index]['snippet'] = event.target.value;
    handleContentList(updatedList);
  };

  const handleMemoChange = (index, event) => {
    let updatedList = contentListRef.current.slice();
    updatedList[index]['memo'] = event.target.value;
    handleContentList(updatedList);
  };

  const handleUploadNewImage = useCallback(async (index, image, filename) => {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('source', 'promotion');

    const { data } = await apiAxios({ method: 'POST', uri: '/images/', data: formData });

    var item = {};
    item[filename] = data['image'];

    let updatedList = contentListRef.current.slice();
    updatedList[index].data = { ...updatedList[index].data, ...item };
    handleContentList(updatedList);
  });

  const handleNewFileInput = useCallback(
    async (index, e, filename) => {
      await handleUploadNewImage(index, e.target.files[0], filename);
    },
    [handleUploadNewImage]
  );

  const handleColor = () => {
    var clist = colorList.slice();
    clist.push(selectedColor);
    setColorList(clist);
  };
  // function hasOrderError(index, value) {
  //   if (value < 1 || value > contentList.length) return true;

  //   for (let i = 0; i < contentList.length; i++) {
  //     if (i !== index && contentList[i]['order'] === value) {
  //       return true;
  //     }
  //   }

  //   return false;
  // }

  // function getErrorCheckedList(index, value) {
  //   let checkedList = contentListRef.current.slice();
  //   checkedList[index]['order'] = value;

  //   for (let i = 0; i < contentList.length; i++) {
  //     checkedList[i]['orderErrorState'] = hasOrderError(i, checkedList[i]['order']);
  //   }

  //   return checkedList;
  // }

  // const handleOrderChange = (index, e) => {
  //   let updatedList = getErrorCheckedList(index, Number(e.target.value));
  //   handleContentList(updatedList);
  // };

  const handleClickContentHeader = (index) => {
    const updatedList = [...contentList];
    updatedList[index].isCollapsed = !updatedList[index].isCollapsed;
    handleContentList(updatedList);
  };

  const getContentHeaderTitle = (item) => {
    const { snippet: snippetKey = '', image_title = '', memo = '' } = item;
    return `[${snippets[snippetKey].menu}] ${memo || ''} ${image_title || ''} `;
  };

  const handleDragStart = (e, position) => {
    draggingItem.current = position;
  };

  const handleDragEnter = (e, position) => {
    dragOverItem.current = position;

    const copiedList = [...contentListRef.current];
    const draggingItemContent = copiedList[draggingItem.current];
    copiedList.splice(draggingItem.current, 1);
    copiedList.splice(dragOverItem.current, 0, draggingItemContent);
    draggingItem.current = dragOverItem.current;
    dragOverItem.current = null;

    handleContentList(copiedList);
  };

  const setData = (index, data) => {
    let updatedList = contentListRef.current.slice();
    updatedList[index].data = data;
    handleContentList(updatedList);
  };

  const ContentArea = (index, handleNewFileInput) => {
    switch (contentList[index]['snippet']) {
      case snippets.NEWIMAGE.key:
        return <NewImageContent index={index} data={contentList[index]?.data} setData={setData} newFileInput={handleNewFileInput} />;
      case snippets.HEADER.key:
        return <NewImageContent index={index} data={contentList[index]?.data} setData={setData} newFileInput={handleNewFileInput} />;
      case snippets.SHARE.key:
        return <ShareContent index={index} data={contentList[index]?.data} setData={setData} newFileInput={handleNewFileInput} />;
      case snippets.BENEFIT.key:
        return <BenefitContent index={index} data={contentList[index]?.data} setData={setData} newFileInput={handleNewFileInput} />;
      default:
        return (
          <>
            <div className={classes.backgroundLabel}>
              <span className={classes.basicText}>{'배경색 👉'}</span>
              <TwitterPicker triangle={'hide'} color={contentList[index]['background_color']} colors={colorList} onChange={(color, event) => handleBackgroundInput(color, event, index)} />
            </div>
            <ReactQuill
              theme="snow"
              value={contentList[index]['rich_text'] || ''}
              modules={modules}
              formats={formats}
              readOnly={false}
              onChange={(e) => handleChangeInput(index, e)}
              style={{
                backgroundColor: contentList[index]['background_color'],
              }}
            />
          </>
        );
    }
  };

  const handlePaletteClick = () => {
    setIsPaletteCollapsed(!isPaletteCollapsed);
  };

  return (
    <div>
      <div onClick={() => handlePaletteClick()}>
        <div className={classes.basicText}>프로모션 파레트 {isPaletteCollapsed ? '🔽' : '🔼'}</div>
        <div className={classes.subText}>프로모션에서 사용할 텍스트의 색상을 파레트에 추가해 사용합니다.</div>
      </div>
      <ContentBody className={isPaletteCollapsed ? 'collapsed' : ''}>
        <div className={`${classes.formLabel} first`} style={{ flexDirection: 'column', alignItems: 'normal' }}>
          <BlockPicker triangle={'hide'} color={selectedColor} colors={colorList} onChange={handlePalletInput} />
          <Button style={{ width: '170px' }} onClick={handleColor}>
            색깔 추가하기
          </Button>
        </div>
      </ContentBody>

      <div className={`${classes.formLabel} first`}>
        <span>콘텐츠</span>
        <Button variant="outlined" onClick={handleAdd}>
          콘텐츠 추가하기
        </Button>
      </div>
      <div ref={contentListWrapper}>
        {contentList != null &&
          contentList.length > 0 &&
          contentList.map((content, index) => (
            <div
              key={index}
              onDragStart={(e) => handleDragStart(e, index)}
              onDragEnter={(e) => handleDragEnter(e, index)}
              onDragOver={(e) => e.preventDefault()}
              onDragEnd={(e) => e.preventDefault()}
              draggable
            >
              <Paper className={classes.paperWrapper} variant="outlined">
                <ContentHeader onClick={() => handleClickContentHeader(index)}>
                  <div>
                    <DragIndicatorIcon />
                    {getContentHeaderTitle(content)}
                  </div>
                  {content.isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </ContentHeader>
                <ContentBody className={content.isCollapsed ? 'collapsed' : ''}>
                  <div className={classes.rightButtonForm}>
                    <Button variant="contained" color="primary" onClick={handleDelete(index)}>
                      <DeleteIcon />
                    </Button>
                  </div>
                  <div>
                    <FormControl variant="outlined" className={classes.dropdown}>
                      <InputLabel>영역 타입</InputLabel>
                      <Select onChange={(e) => handleSnippetChange(index, e)} label="Snippet" value={contentList[index]['snippet']}>
                        <MenuItem value={snippets.DEFAULT.key}>{snippets.DEFAULT.menu}</MenuItem>
                        <MenuItem value={snippets.NEWIMAGE.key}>{snippets.NEWIMAGE.menu}</MenuItem>
                        <MenuItem value={snippets.HEADER.key}>{snippets.HEADER.menu}</MenuItem>
                        <MenuItem value={snippets.SHARE.key}>{snippets.SHARE.menu}</MenuItem>
                        <MenuItem value={snippets.BENEFIT.key}>{snippets.BENEFIT.menu}</MenuItem>
                        {/* <MenuItem value={snippets.IMAGE.key}>{snippets.IMAGE.menu}</MenuItem> */}
                        {/* <MenuItem value={snippets.PROGRAM_ALLDAY.key}>{snippets.PROGRAM_ALLDAY.menu}</MenuItem> */}
                        {/* <MenuItem value={snippets.PROGRAM_ONEDAY.key}>{snippets.PROGRAM_ONEDAY.menu}</MenuItem> */}
                        <MenuItem value={snippets.QUESTION.key}>{snippets.QUESTION.menu}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <MemoArea>
                    <div> {'메모 (관리자 식별용) '}</div>
                    <textarea value={contentList[index]['memo']} placeholder={'메모를 입력해주세요'} onChange={(e) => handleMemoChange(index, e)} />
                  </MemoArea>
                  {ContentArea(index, handleNewFileInput)}
                </ContentBody>
              </Paper>
            </div>
          ))}
      </div>
    </div>
  );
};
export default ContentList;
