import React from 'react';
import { Create, SimpleForm, TextInput, required, RadioButtonGroupInput, useRedirect, BooleanInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { CustomEditor, toolbarOptions } from 'components/CustomEditor';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '50ch',
  },
  richText: {
    '& .ql-editor': {
      minHeight: '700px',
      width: '1000px',
    },
    '& .ra-rich-text-input .ql-editor p': {
      marginBottom: '0px',
    },
    '& .ql-snow .ql-editor h2': {
      fontSize: '28px',
    },
    '& .ql-snow .ql-editor h3': {
      fontSize: '20px',
    },
    '& .ql-snow .ql-editor h4': {
      fontSize: '20px',
    },
    '& .ql-snow .ql-editor h5': {
      fontSize: '1em',
    },
    '& .ql-editor ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    '& .ql-editor li': {
      paddingLeft: '1em',
      textIndent: '-1em',
    },
    '& .ql-editor ul li:before': {
      content: '',
      paddingRight: '5px',
    },
  },
}));

const CreateFAQ = (props) => {
  const redirect = useRedirect();
  const classes = useStyles();

  const onSuccess = () => {
    redirect('/faqs');
  };

  return (
    <Create title="FAQ 생성" {...props} onSuccess={onSuccess}>
      <SimpleForm submitOnEnter={false}>
        <RadioButtonGroupInput
          label="타입"
          source="type"
          choices={[
            { id: '전체', name: '전체' },
            { id: 'event', name: '이벤트' },
            { id: 'digitalcontents', name: 'VOD' },
            { id: 'moim', name: '모임' },
            { id: 'member', name: '멤버' },
          ]}
        />
        <TextInput className={classes.layout} label="질문" source="question" validate={[required()]} />
        <div className={classes.richText}>
          <CustomEditor toolbar={toolbarOptions} variant="standard" label="대답" source="answer" />
        </div>
        <BooleanInput label="홈페이지 노출" source="opened" />
      </SimpleForm>
    </Create>
  );
};

export default CreateFAQ;
