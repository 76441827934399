import userProvider from 'graphql/provider/users';
import feedProvider from 'graphql/provider/feeds';
import blogProvider from 'graphql/provider/blogs';
import reivewProvider from 'graphql/provider/reviews';
import webinarProvider from 'graphql/provider/webinars';
import applyProvider from 'graphql/provider/applies';
import programProvider from 'graphql/provider/programs';
import searchUserProvider from 'graphql/provider/searchUsers';
import couponProviders from 'graphql/provider/coupons';
import participationProviders from 'graphql/provider/participations';
import webinarAttendeeProviders from 'graphql/provider/webinarAttendees';
import pushProvider from 'graphql/provider/pushes';
import bannerProvider from 'graphql/provider/banners';
import orderProvider from 'graphql/provider/orders';
import serializedCouponProvider from 'graphql/provider/serializedCoupons';
import b2bProvider from 'graphql/provider/b2bs';
import communityProvider from 'graphql/provider/communities';
import interviewProvider from 'graphql/provider/interviews';
import noticeProvider from 'graphql/provider/notices';
import faqProvider from 'graphql/provider/faqs';
import appSettingProvider from 'graphql/provider/appSettings';

const providerContainer = {
  searchUsers: searchUserProvider,
  users: userProvider,
  feeds: feedProvider,
  blogs: blogProvider,
  reviews: reivewProvider,
  applies: applyProvider,
  webinars: webinarProvider,
  programs: programProvider,
  coupons: couponProviders,
  participations: participationProviders,
  webinarAttendees: webinarAttendeeProviders,
  pushes: pushProvider,
  banners: bannerProvider,
  orders: orderProvider,
  serializedCoupons: serializedCouponProvider,
  b2bs: b2bProvider,
  communities: communityProvider,
  interviews: interviewProvider,
  notices: noticeProvider,
  faqs: faqProvider,
  appSettings: appSettingProvider,
};

export default providerContainer;
