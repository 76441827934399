import React from 'react';
import { Create, SimpleForm, TextInput, required, useNotify, useRefresh, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '50ch',
  },
}));

const CreateB2B = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('생성 완료');
    redirect('/b2bs');
    refresh();
  };

  return (
    <Create title="B2B 생성" {...props} onSuccess={onSuccess}>
      <SimpleForm submitOnEnter={false}>
        <TextInput className={classes.layout} validate={[required()]} label="b2b 구분 이름(이름에 따라 url이 정해집니다.)" source="name" />
        <TextInput className={classes.layout} validate={[required()]} label="b2b 회사 코드 (기존에 생성된 코드는 사용 불가능 합니다.)" source="code" />
      </SimpleForm>
    </Create>
  );
};

export default CreateB2B;
