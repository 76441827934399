import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));
const ProgramList = (props) => {
  const { programs, selectedPrograms, handleSelectProgram } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const classes = useStyles();

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDelete = (selectedToDelete) => () => {
    const filterd = selectedPrograms.filter((program) => program.id !== selectedToDelete.id);
    handleSelectProgram(filterd);
  };

  const handleInsert = (selectedToInsert) => () => {
    if (!selectedPrograms.includes(selectedToInsert)) {
      const concat = selectedPrograms.concat(selectedToInsert);
      handleSelectProgram(concat);
    }
  };

  React.useEffect(() => {
    const results = programs ? programs.filter((program) => (program['id'] + program['name']).toLowerCase().includes(searchTerm)) : [];
    setSearchResults(results);
  }, [searchTerm]);

  return (
    <div>
      <Paper>
        {selectedPrograms.length > 0 &&
          selectedPrograms.map((program) => (
            <div key={program.id}>
              <Chip size="small" label={program.id + program.name} onDelete={handleDelete(program)} className={classes.chip} />
            </div>
          ))}
      </Paper>
      <TextField type="text" placeholder="Search" value={searchTerm} onChange={handleChange} />
      <List component="nav" aria-label="secondary mailbox folders">
        {searchResults.length > 0 &&
          searchResults.map((program, index) => (
            <ListItem key={index} onClick={handleInsert(program)}>
              {program['id'] + program['name']}
            </ListItem>
          ))}
      </List>
    </div>
  );
};

export default ProgramList;
