import React, { useEffect, useState } from 'react';
import { Create, SimpleForm, SelectInput, DateTimeInput, TextInput, ArrayInput, SimpleFormIterator, BooleanInput, required } from 'react-admin';
import { gql } from '@apollo/client';
import { listAppSettings } from 'graphql/queries';
import { client } from 'graphql/providers';
import { defaultNavSetting, APPSETTING_GROUP, APPSETTING_TYPE, NAV_PATHNAME } from 'helpers/constants';

const CreateAppSetting = (props) => {
  const [enabledAppSettings, setEnabledAppSettings] = useState([]);

  useEffect(() => {
    // 현재 노출 중인 설정 및 앞으로 노출 예정인 설정 불러오기
    const fetchData = async () => {
      const { loading, data } = await client.query({
        query: gql`
          ${listAppSettings}
        `,
        variables: {
          filter: {
            group: 'main',
            is_enabled: true,
          },
        },
      });

      if (!loading && data) {
        setEnabledAppSettings([...data.listAppSettings.items]);
      }
    };

    fetchData();
  }, []);

  const CustomValidation = (values) => {
    const errors = {};
    const now = new Date();

    // 현재 노출 중인 설정과 노출 예정인 설정이 있을 때 모두 불러와서 기간 중복 체크
    if (enabledAppSettings.length !== 0) {
      enabledAppSettings.map((setting) => {
        // 새로 생성하려는 설정의 노출 기간이 기존 설정의 노출 기간을 포함할 때
        if (new Date(values.start_at) <= new Date(setting.start_at) && new Date(values.end_at) >= new Date(setting.end_at)) {
          errors.start_at = '노출 기간은 중복될 수 없습니다.';
          errors.end_at = '노출 기간은 중복될 수 없습니다.';
        }

        // 새로 생성하려는 설정의 노출 기간이 기존 설정의 노출 기간과 일부 겹치거나 기간 안에 포함될 때
        if (new Date(values.start_at) >= new Date(setting.start_at) && new Date(values.start_at) <= new Date(setting.end_at)) {
          errors.start_at = '노출 기간은 중복될 수 없습니다.';
        }

        if (new Date(values.end_at) >= new Date(setting.start_at) && new Date(values.end_at) <= new Date(setting.end_at)) {
          errors.end_at = '노출 기간은 중복될 수 없습니다.';
        }
      });
    }

    // 노출 시작 일시가 현재 시각보다 이전일 때
    if (new Date(values.start_at) < now) {
      errors.start_at = '노출 시작 일시는 현재 시각 이후여야 합니다.';
    }

    // 노출 종료 일시가 현재 시각보다 이전일 때
    if (new Date(values.end_at) < now) {
      errors.end_at = '노출 종료 일시는 현재 시각 이후여야 합니다.';
    }

    // 노출 시작 일시가 종료 일시와 일치하거나 더 나중일 때
    if (new Date(values.start_at) >= new Date(values.end_at)) {
      errors.start_at = '노출 시작 일시는 종료 일시보다 이전이어야 합니다.';
      errors.end_at = '노출 종료 일시는 시작 일시보다 나중이어야 합니다.';
    }

    return errors;
  };

  return (
    <Create title="서비스 설정 생성" {...props}>
      <SimpleForm validate={CustomValidation} submitOnEnter={false} redirect="list">
        <div>
          <h3>설정 분류</h3>
        </div>
        <SelectInput validate={[required()]} label="대분류" source="group" choices={APPSETTING_GROUP} defaultValue={APPSETTING_GROUP[0].id} />
        <SelectInput validate={[required()]} label="분류" source="type" choices={APPSETTING_TYPE} defaultValue={APPSETTING_TYPE[0].id} />
        <div>
          <h3>노출 기간 설정</h3>
        </div>
        <DateTimeInput validate={[required()]} label="노출 시작 일시" source="start_at" variant="standard" />
        <DateTimeInput validate={[required()]} label="노출 종료 일시" source="end_at" variant="standard" />
        <>
          <div>
            <h3>내비게이션 바 설정 생성</h3>
            <p>'점 찍기' 설정을 켜면 해당 메뉴 이름 우상단에 빨간 점이 생깁니다</p>
            <img src="https://heyjoyce.com/image/event/%E1%84%8C%E1%85%A5%E1%86%B7-%E1%84%8D%E1%85%B5%E1%86%A8%E1%84%80%E1%85%B5-%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5.png" width="480" />
          </div>
          <ArrayInput source="data" label="" defaultValue={defaultNavSetting}>
            <SimpleFormIterator>
              <TextInput validate={[required()]} source="name" label="메뉴 이름" />
              <SelectInput
                validate={[required()]}
                source="path"
                label="연결 페이지"
                choices={NAV_PATHNAME}
                optionText="name"
                onCreate={() => {
                  const url = prompt('https://heyjoyce.com 뒤의 url을 넣어주세요.\n예 - /promotion/promotionmba3');
                  const newPathname = { id: url, name: url };
                  NAV_PATHNAME.push(newPathname);
                  return newPathname;
                }}
              />
              <BooleanInput defaultValue={false} source="is_highlighted" label="점 찍기" />
            </SimpleFormIterator>
          </ArrayInput>
        </>
      </SimpleForm>
    </Create>
  );
};

export default CreateAppSetting;
