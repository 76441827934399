import React, { useRef, useCallback } from 'react';
import { apiAxios } from 'helpers/axios';
import RichTextInput from 'ra-input-rich-text';

const CustomEditor = (props) => {
  const inputFileRef = useRef(null);
  const editorRef = useRef(null);

  const { toolbar, label, source, defaultValue } = props;

  const handleUploadImage = useCallback(async (image) => {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('source', 'admin');
    const { data } = await apiAxios({ method: 'POST', uri: '/images/', data: formData });

    if (data) {
      var range = editorRef.current.getSelection();
      var firstLength = editorRef.current.getLength();

      if (range) {
        editorRef.current.insertEmbed(range.index, 'image', data['image']);
        var newLength = editorRef.current.getLength();
        editorRef.current.setSelection(range.index + (newLength - firstLength)); //이미지 입력 후 커서 뒤로 이동하게 하기 위해서 필요
      } else {
        editorRef.current.insertEmbed(firstLength, 'image', data['image']);
      }
    }
  });

  const handleFileInput = useCallback(
    async (e) => {
      await handleUploadImage(e.target.files[0]);
    },
    [handleUploadImage]
  );

  const configureQuill = (quill) => {
    editorRef.current = quill;

    quill.getModule('toolbar').addHandler('image', function (value) {
      inputFileRef.current.click();
    });

    quill.getModule('toolbar').addHandler('header', function (headingNumber) {
      const range = editorRef.current.getSelection();
      if (headingNumber === '2') {
        this.quill.format('bold', true);
        this.quill.format('header', 2);
      } else if (headingNumber === '3') {
        this.quill.format('bold', true);
        this.quill.format('header', 3);
      } else if (headingNumber === '4') {
        this.quill.insertText(range, '| ', {
          color: '#a7a7a7',
          bold: true,
        });
        this.quill.format('bold', true);
        this.quill.format('header', 4);
      } else if (headingNumber === '5') {
        this.quill.insertText(range, 'ⓘ ', {
          color: '#424242',
        });
        this.quill.format('header', 5);
      }
    });
  };

  return (
    <div>
      <input ref={inputFileRef} onChange={handleFileInput} type="file" accept="image/*" style={{ display: 'none' }} />
      <RichTextInput toolbar={toolbar} label={label} source={source} configureQuill={configureQuill} defaultValue={defaultValue} />
    </div>
  );
};

const toolbarOptions = [
  [{ header: [2, 3, 4, 5, false] }],
  ['bold', 'italic', 'underline', 'strike', 'link', 'image', 'video'], // toggled buttons
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ align: [] }],
  [
    { color: [false, '#FDDB00', '#F04E44', '#2A3384', '#005950', '#FFE1E3', '#8CE7DA', '#222222', '#444444', '#666666', '#888888', '#aaaaaa', '#cccccc', '#eeeeee'] },
    {
      background: [
        false,
        '#FDDB00',
        '#F04E44',
        '#2A3384',
        '#005950',
        '#FFE1E3',
        '#8CE7DA',
        'black',
        '#fddb0030',
        '#ff524717',
        '#4959ea21',
        '#00bfac30',
        '#ffe9ea7d',
        '#a1fff252',
        '#222222',
        '#444444',
        '#666666',
        '#888888',
        '#aaaaaa',
        '#cccccc',
        '#eeeeee',
      ],
    },
  ],
  ['clean'], // remove formatting button
];

export { CustomEditor, toolbarOptions };
