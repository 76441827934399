import { client } from 'graphql/providers';
import { gql } from '@apollo/client';
import { getWebinar } from 'graphql/queries';
import { createWebinar, updateWebinar } from 'graphql/mutations';
import { apiAxios } from 'helpers/axios';

const webinarProvider = {
  getOne: async (resource, params) => {
    const { id } = params;
    const { data } = await client.query({
      query: gql`
        ${getWebinar}
      `,
      variables: {
        id,
      },
    });
    return {
      data: {
        ...data.getWebinar,
        is_all_user_access: data.getWebinar?.is_all_user_access ? true : false,
      },
    };
  },
  create: async (resource, params) => {
    const { type, notice, scheduled_at, is_all_user_access = false, program_ids, programs, speakers } = params.data;
    if (!speakers) {
      throw Error('speakers is required');
    }
    // convert values
    notice.prequestion_url = notice.prequestion_url.replace(/\https:\/\//g, '');
    notice.feedback_url = notice.feedback_url.replace(/\https:\/\//g, '');
    const convertedPrograms = programs
      .map((item) => {
        delete item['__typename'];
        return item;
      })
      .filter((item) => program_ids.includes(item.id));
    // upload images for speakers
    const covertedSpeakers = await Promise.all(
      speakers.map(async (item) => {
        const { images } = item;
        const formData = new FormData();
        formData.append('image', images);
        formData.append('source', 'webinar');
        const { data } = await apiAxios({ method: 'POST', uri: '/images/', data: formData });
        return {
          title: item.title,
          subtitle: item.subtitle,
          body: item.body,
          profile_url: data.image,
        };
      })
    );

    const { data } = await client.mutate({
      mutation: gql`
        ${createWebinar}
      `,
      variables: {
        input: {
          type,
          notice,
          scheduled_at,
          is_all_user_access,
          programs: convertedPrograms,
          speakers: covertedSpeakers,
        },
      },
    });
    return {
      data: data.createWebinar,
    };
  },
  update: async (resource, params) => {
    const { notice, type, scheduled_at, is_all_user_access = false, programs = [], allPrograms = [], speakers } = params.data;
    const { id } = params;
    delete notice.__typename;
    // convert values
    notice.prequestion_url = notice.prequestion_url.replace(/\https:\/\//g, '');
    notice.feedback_url = notice.feedback_url.replace(/\https:\/\//g, '');

    const covertedSpeakers = await Promise.all(
      speakers.map(async (item) => {
        delete item['__typename'];
        if (item.images !== undefined) {
          const formData = new FormData();
          formData.append('image', item.images);
          formData.append('source', 'webinar');
          const { data } = await apiAxios({ method: 'POST', uri: '/images/', data: formData });
          return {
            title: item.title,
            subtitle: item.subtitle,
            body: item.body,
            profile_url: data.image,
          };
        }
        delete item['images'];
        return item;
      })
    );

    const { data } = await client.mutate({
      mutation: gql`
        ${updateWebinar}
      `,
      variables: {
        input: {
          id,
          type,
          notice,
          is_all_user_access,
          scheduled_at,
          speakers: covertedSpeakers,
          programs: allPrograms
            .filter((item) => programs.find((program) => program.id === item.id))
            .map((item) => {
              delete item['__typename'];
              return item;
            }),
        },
      },
    });
    return {
      data: data.updateWebinar,
    };
  },
};

export default webinarProvider;
