import React from 'react';
import { Edit, SimpleForm, SelectInput, ImageInput, ImageField, TextInput, DateTimeInput, BooleanInput, required, NumberInput, FormDataConsumer, useNotify } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '50ch',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  fieldTitle: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8px',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

const locationRange = [
  { id: 'MAIN_TOP', name: '메인 상단' },
  { id: 'MAIN_BOTTOM', name: '메인 하단' },
];

const EditBanner = (props) => {
  const notify = useNotify();
  const classes = useStyles();

  const handleClickChip = (e) => {
    const copiedText = e.target.innerText;
    const tempElem = document.createElement('textarea');
    tempElem.value = copiedText;
    document.body.appendChild(tempElem);
    tempElem.select();
    document.execCommand('copy');
    document.body.removeChild(tempElem);
    notify('클립보드에 복사되었습니다.');
  };

  return (
    <Edit title="배너 수정" {...props} mutationMode="pessimistic">
      <SimpleForm submitOnEnter={false}>
        <SelectInput label="배너 타입" source="banner_type" choices={locationRange} variant="outlined" validate={[required()]} />
        <TextInput label="배너 설명 (관리자 식별용)" className={classes.layout} source="description" variant="standard" validate={[required()]} />
        <ImageField label="기존 배너 이미지" className={classes.bannerImage} source="image_url" />
        <ImageInput label="새 배너 이미지" className={classes.layout} source="new_image_url" variant="standard">
          <ImageField className={classes.bannerImage} />
        </ImageInput>
        <ImageField label="기존 모바일 배너 이미지" className={classes.bannerImage} source="m_image_url" />
        <ImageInput label="새 모바일 배너 이미지" className={classes.layout} source="new_m_image_url" variant="standard">
          <ImageField className={classes.bannerImage} />
        </ImageInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.banner_type === 'MAIN_TOP' && (
              <>
                <div>
                  <TextInput label="태그" source="tag" variant="standard" />
                </div>
                <div>
                  <Chip className={classes.chip} label="라이브" size="small" onClick={handleClickChip} />
                  <Chip className={classes.chip} label="VOD" size="small" onClick={handleClickChip} />
                  <Chip className={classes.chip} label="콘조이스" size="small" onClick={handleClickChip} />
                  <Chip className={classes.chip} label="오프라인" size="small" onClick={handleClickChip} />
                </div>
              </>
            )
          }
        </FormDataConsumer>
        <TextInput label="타이틀 텍스트 영역" className={classes.layout} source="title" variant="standard" multiline />
        <TextInput label="서브타이틀 텍스트 영역" className={classes.layout} source="subtitle" variant="standard" multiline />
        <TextInput label="랜딩 URL" className={classes.layout} source="link" variant="standard" validate={[required()]} />
        <DateTimeInput label="노출 시작 일시" className={classes.layout} source="start_date" variant="standard" validate={[required()]} />
        <DateTimeInput label="노출 종료 일시" className={classes.layout} source="end_date" variant="standard" validate={[required()]} />
        <NumberInput label="노출 가중치(클수록 먼저 노출)" source="priority" variant="outlined" validate={[required()]} />
        <div className={classes.fieldTitle}>노출 대상</div>
        <BooleanInput label="비로그인" source="target_guest" />
        <BooleanInput label="로그인" source="target_associate" />
        <BooleanInput label="Test" source="test" defaultValue={true} />
      </SimpleForm>
    </Edit>
  );
};

export default EditBanner;
