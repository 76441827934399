import { adminAxios } from 'helpers/axios';

const participationProviders = {
  getList: async (resource, params) => {
    const { programId, order } = params.filter;

    const { data } = await adminAxios({
      method: 'GET',
      uri: `/programs/${programId}/participations`,
      query: order ? `?orderStatus=${order.status}` : '',
    });

    return {
      data,
      total: data.length,
    };
  },
  getOne: async (resource, params) => {
    const { id: participationId } = params;

    const { data } = await adminAxios({
      method: 'GET',
      uri: `/participations/${participationId}`,
      query: '',
    });

    return {
      data,
    };
  },
  create: async (resource, params) => {
    let orderData;
    let participationData;

    const { created_at, imp_uid, merchant_uid, method, price, programId, status, user, imp_uid_exist } = params.data;

    if (!imp_uid_exist) {
      ({ orderData } = await adminAxios({
        method: 'POST',
        uri: '/orders/',
        query: '',
        data: { created_at, imp_uid, merchant_uid, method, price, programId, status, user_id: user },
      }));
    }

    try {
      ({ participationData } = await adminAxios({
        method: 'POST',
        uri: '/participations/',
        query: '',
        data: { created_at, user_id: user, order_id: imp_uid_exist || imp_uid, program_id: programId },
      }));
    } catch (error) {
      alert('imp_uid 번호를 확인해주세요!');
    }

    return {
      data: {
        ...params.data,
        user: {
          username: '',
          email: '',
          phone_number: '',
          company: '',
          job: '',
          industry: '',
          position: '',
        },
        order: {
          status,
          method,
          price,
          user,
        },
      },
    };
  },
  update: async (resource, params) => {
    const {
      id,
      program,
      user,
      order: { status, price, method, imp_uid, merchant_uid },
    } = params.data;

    await adminAxios({
      method: 'PATCH',
      uri: `/participations/${id}/`,
      data: {
        program: program.id,
        user,
      },
    });

    await adminAxios({
      method: 'PATCH',
      uri: `/orders/${imp_uid}/`,
      data: {
        status,
        price,
        method,
        imp_uid,
        merchant_uid,
      },
    });

    return {
      data: {
        program,
        user,
        order: {
          status,
          price,
          method,
          imp_uid,
          merchant_uid,
        },
      },
    };
  },
};

export default participationProviders;
