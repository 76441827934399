import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  SelectInput,
  NumberField,
  DateField,
  BooleanField,
  ImageField,
  ChipField,
  Filter,
  BooleanInput,
  TextInput,
  Pagination,
  EditButton,
  DeleteWithConfirmButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
  },
  editButton: {
    fontWeight: 'bold',
  },
  thumbnailImage: {
    '& img': {
      maxWidth: '200px',
    },
    textAlign: 'center',
  },
});

const BlogFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="타입"
      source="tags"
      choices={[
        { id: '브랜드 스토리', name: '브랜드 스토리' },
        { id: '가이드', name: '가이드' },
        { id: '인터뷰', name: '인터뷰' },
      ]}
      alwaysOn
    />
    <BooleanInput source="is_pinned" label="메인노출" initialValue={false} alwaysOn />
    <TextInput label="🔎 키워드 검색" source="search" alwaysOn resettable />
  </Filter>
);

const BlogPagination = (props) => <Pagination rowsPerPageOptions={[20, 50, 100, 200]} {...props} />;

const EditField = (props) => (
  <>
    <EditButton {...props} />
    <DeleteWithConfirmButton {...props} confirmContent="🚨 정말로 삭제하시려면 [✔확인]을 눌러주세요." confirmTitle={`"${props.record.data.title}" 삭제`} />
  </>
);

const ListBlog = (props) => {
  const classes = useStyles();

  return (
    <List {...props} title="블로그 리스트" pagination={<BlogPagination />} bulkActionButtons={false} filters={<BlogFilter />} exporter={false}>
      <Datagrid classes={{ headerCell: classes.headerCell }} rowClick="show">
        <NumberField label="뷰" source="view_count" sortable={false} />
        <TextField label="제목" source="data.title" sortable={false} />
        <ChipField label="블로그 타입" source="tags" />
        <ImageField label="썸네일" className={classes.thumbnailImage} source="data.thumbnail" />
        <DateField label="작성일" source="created_at" showTime={true} />
        <DateField label="수정일" source="updated_at" showTime={true} />
        <BooleanField label="메인노출" source="is_pinned" sortable={false} />
        <EditField />
      </Datagrid>
    </List>
  );
};

export default ListBlog;
