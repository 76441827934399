const customMessages = {
  ra: { navigation: { skip_nav: '넘기기' }, action: { unselect: '체크해제', clear_input_value: '입력값 초기화' } },
};

const defaultNavSetting = [
  {
    name: '홈',
    path: '/',
  },
  {
    name: '라이브',
    path: '/program/event',
  },
  {
    name: 'VOD',
    path: '/program/digitalcontents',
  },
  {
    name: '성장패키지',
    path: '/package',
  },
  {
    name: '인스파이러',
    path: '/community/inspirer',
  },
  {
    name: '블로그',
    path: '/blog/list',
  },
];

const APPSETTING_GROUP = [{ id: 'main', name: '메인' }];
const APPSETTING_TYPE = [{ id: 'nav', name: '내비게이션 바 설정' }];
const NAV_PATHNAME = [
  { id: '/', name: '/' },
  { id: '/conjoyce', name: '/conjoyce' },
  { id: '/program/event', name: '/program/event' },
  { id: '/program/digitalcontents', name: '/program/digitalcontents' },
  { id: '/blog/list', name: '/blog/list' },
  { id: '/package', name: '/package' },
  { id: '/community/inspirer', name: '/community/inspirer' },
];

export { customMessages, defaultNavSetting, APPSETTING_GROUP, APPSETTING_TYPE, NAV_PATHNAME };
