import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { gql } from '@apollo/client';
import { client } from 'graphql/providers';
import { invokeOperatorEvent } from 'graphql/mutations';
import { EVENT, ACTIONS } from 'event';

const WEBINAR_TYPE = {
  ivs: 'ivs',
  pagecall: 'pagecall',
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
  },
  videoArea: {
    width: '400px',
    height: '300px',
    padding: '12px',
    backgroundColor: 'lightgrey',
  },
  controlArea: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '12px',
  },
  terminateBtn: {
    marginTop: '10px',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const OperatingBox = ({ record }) => {
  const { id, type, notice, ivs, pagecall, attendees, is_terminated } = record;

  const classes = useStyles();
  const [modalStyle] = useState({
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  });
  const [terminatedModal, setTerminatedModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!record || !ivs) {
      return;
    }
    if (type === WEBINAR_TYPE.ivs && window.IVSPlayer && window.IVSPlayer.isPlayerSupported) {
      try {
        const player = window.IVSPlayer.create();
        player.attachHTMLVideoElement(document.getElementById('video-player'));
        player.load(record.ivs.playback_url);
        player.play();
      } catch (err) {
        console.log('Failed to initialize video resources');
      }
    }
  }, [type]);

  const handleTerminate = async () => {
    const terminateConfirm = window.confirm('웨비나를 종료하시겠습니까?');
    if (terminateConfirm) {
      setLoading(true);
      setTerminatedModal(true);
      const { data } = await client.mutate({
        mutation: gql`
          ${invokeOperatorEvent}
        `,
        variables: {
          payload: JSON.stringify({
            id,
            event: {
              category: EVENT.webinar,
              action: ACTIONS.terminated,
            },
          }),
        },
      });
      setLoading(false);
      return data;
    }
    return {};
  };

  const handleClose = () => setTerminatedModal(false);

  return (
    <>
      {type === WEBINAR_TYPE.ivs && (
        <div className={classes.wrapper}>
          <div className={classes.videoArea}>
            <video id="video-player" width="100%" playsinine="true">
              Your browser does not support the video tag. I suggest you upgrade your browser.
            </video>
          </div>
          <div className={classes.controlArea}>
            <CopyToClipboard text={ivs.stream_endpoint}>
              <Button>OBS 서버URL 복사하기</Button>
            </CopyToClipboard>
            <CopyToClipboard text={ivs.stream_key}>
              <Button>OBS 스트림키 복사하기</Button>
            </CopyToClipboard>
            <Button onClick={handleTerminate} className={classes.terminateBtn} variant="contained" color="primary" disabled={is_terminated || terminatedModal ? true : false}>
              {is_terminated || terminatedModal ? '종료된 웨비나' : '웨비나 종료'}
            </Button>
          </div>
        </div>
      )}
      {type === WEBINAR_TYPE.pagecall && (
        <div>
          <iframe title={notice.title} src={`https://app.pagecall.net/${pagecall.id}`} allow="camera;microphone" style={{ width: '100%', height: '200vh', border: 0 }} />
        </div>
      )}
      <Modal open={terminatedModal} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <div style={modalStyle} className={classes.paper}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <h2 id="simple-modal-title">{notice.title} 종료</h2>
              <p id="simple-modal-description">✅ {attendees.length}명의 출석 정보가 업데이트 되었습니다!</p>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default OperatingBox;
