import { adminAxios } from 'helpers/axios';

const noticeProvider = {
  getList: async (resource, params) => {
    const {
      filter: { opened },
      pagination: { page, perPage },
    } = params;
    const offset = (page - 1) * perPage;
    const {
      data: { count, results },
    } = await adminAxios({
      method: 'GET',
      uri: '/notices/',
      query: `?limit=${perPage}&offset=${offset}${opened ? `&opened=${opened}` : ''}`,
    });

    return {
      data: results,
      total: count,
    };
  },
  create: async (resource, params) => {
    const {
      data: { writer, subject = '', content = '', opened = false },
    } = params;
    console.log('writer', writer);
    const { data } = await adminAxios({
      method: 'POST',
      uri: '/notices/',
      data: { writer, subject, content, opened },
    });

    return {
      data: data,
    };
  },
  getOne: async (resource, params) => {
    const { data } = await adminAxios({
      method: 'GET',
      uri: '/notices/',
      query: params.id + '/',
    });
    return {
      data: data,
    };
  },
  update: async (resource, params) => {
    const { id, writer, subject = '', content = '', opened = false } = params.data;

    const { data } = await adminAxios({
      method: 'PATCH',
      uri: '/notices/',
      data: { writer, subject, content, opened },
      query: id + '/',
    });

    return {
      data: data,
    };
  },
  delete: async (resource, params) => {
    const { id } = params;
    const { data } = await adminAxios({
      method: 'DELETE',
      uri: '/notices/' + id + '/',
    });

    return {
      data: data,
    };
  },
};

export default noticeProvider;
