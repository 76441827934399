import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin';
import styled from 'styled-components';

import { youtubeParser } from '../../helpers/index.js';
import Quote from '../../images/quote_icon.png';

const Content = styled.div`
  height: auto;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.8 !important;

  & > *:first-child {
    margin-top: 1em;
  }

  * {
    letter-spacing: -0.2px;
    word-spacing: 0.5px;
    margin: 0;
    word-break: break-all;
  }

  h2 {
    font-size: 28px;
    margin: 1em 0;
  }

  h3,
  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 1em;
  }

  img,
  figure img {
    max-width: 100%;
    margin: 16px auto;
    display: block;
    border-radius: 4px;
  }

  a {
    color: #2a3384;
  }
  a:hover {
    cursor: pointer;
  }
  a:after {
    font-size: small;
    content: '🔗';
    padding-left: 2px;
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 12px 15px;
    box-sizing: border-box;
  }

  li {
    padding-left: 1.5em;
    text-indent: -1.5em;
    margin-bottom: 5px;
  }

  li:before {
    content: '✔️';
    padding-right: 8px;
  }

  blockquote {
    position: relative;
    margin-top: 60px;
    text-align: center;
    color: #424242;
    padding: 0px 10px;
  }

  blockquote:before {
    content: '';
    position: absolute;
    background: url(${Quote}); /*url of image*/
    left: calc(50% - 16px);
    top: -42px;
    font-size: 100px;
    width: 32px;
    height: 28px;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-center {
    text-align: center;
  }

  width: 100%;

  .ql-video {
    width: 420px;
    max-width: 90%;
    height: 260px;
  }
  .ql-video.ql-align-center {
    display: flex;
    margin: 0 auto;
  }
  .ql-video.ql-align-right {
    display: flex;
    margin-left: auto;
  }
`;

const ContentPanel = ({ record }) => (
  <Content>
    <div dangerouslySetInnerHTML={{ __html: youtubeParser(record.content) }} />
  </Content>
);

const DetailNotice = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="id" source="id" />
      <TextField label="제목" source="subject" />
      <TextField label="작성자" source="writer" />
      <DateField label="생성 일자" source="created_at" />
      <ContentPanel />
    </SimpleShowLayout>
  </Show>
);

export default DetailNotice;
