import React, { useEffect, useState } from 'react';
import {
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  Edit,
  SimpleForm,
  SelectInput,
  DateTimeInput,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  required,
  FormDataConsumer,
} from 'react-admin';
import { gql } from '@apollo/client';
import { listAppSettings, getAppSetting } from 'graphql/queries';
import { client } from 'graphql/providers';
import { APPSETTING_GROUP, APPSETTING_TYPE, NAV_PATHNAME } from 'helpers/constants';

const AppSettingEditToolbar = (props) => (
  <Toolbar>
    <SaveButton {...props} label="저장" submitOnEnter={false} />
    <DeleteWithConfirmButton {...props} confirmContent="🚨 정말로 삭제하시려면 [✔확인]을 눌러주세요." confirmTitle={'설정 삭제'} />
  </Toolbar>
);

const EditAppSetting = (props) => {
  const [enabledAppSettings, setEnabledAppSettings] = useState([]);
  const [currentAppSetting, setCurrentAppSetting] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      // 현재 노출 중인 설정 및 앞으로 노출 예정인 설정 불러오기
      const { loading, data } = await client.query({
        query: gql`
          ${listAppSettings}
        `,
        variables: {
          filter: {
            group: 'main',
            is_enabled: true,
          },
        },
      });

      if (!loading && data) {
        setEnabledAppSettings([...data.listAppSettings.items]);
      }

      // 기존에 저장한 값에서 달라졌을 때만 유효성 검사를 실시하기 위해 기존 설정값을 불러옴
      const { loading: loadingCurrentData, data: currentData } = await client.query({
        query: gql`
          ${getAppSetting}
        `,
        variables: {
          id: props.match.params.id,
        },
      });

      if (!loadingCurrentData && currentData) {
        setCurrentAppSetting({ ...currentData.getAppSetting });
      }
    };
    fetchData();
  }, []);

  const CustomValidation = (values) => {
    const errors = {};
    const now = new Date();
    // 기존에 저장한 값에서 달라졌을 때만 유효성 검사를 실시하기 위해 기존 설정값을 변수에 할당
    const currentStartAt = currentAppSetting.start_at;
    const currentEndAt = currentAppSetting.end_at;

    // 현재 노출 중인 설정과 노출 예정인 설정이 있을 때 모두 불러와서 기간 중복 체크
    if (enabledAppSettings.length !== 0) {
      enabledAppSettings.map((setting) => {
        // 노출 중인 설정의 기간을 바꿀 때는 자기자신과는 기간 중복 체크를 하지 않도록 함
        if (setting.id !== props.id) {
          // 수정하려는 설정의 노출 기간이 기존 다른 설정의 노출 기간을 포함할 때
          if (new Date(values.start_at) <= new Date(setting.start_at) && new Date(values.end_at) >= new Date(setting.end_at)) {
            errors.start_at = '노출 기간은 중복될 수 없습니다.';
            errors.end_at = '노출 기간은 중복될 수 없습니다.';
          }

          // 수정하려는 설정의 노출 기간이 기존 설정의 노출 기간과 일부 겹치거나 기간 안에 포함될 때
          if (new Date(values.start_at) >= new Date(setting.start_at) && new Date(values.start_at) <= new Date(setting.end_at)) {
            errors.start_at = '노출 기간은 중복될 수 없습니다.';
          }

          if (new Date(values.end_at) >= new Date(setting.start_at) && new Date(values.end_at) <= new Date(setting.end_at)) {
            errors.end_at = '노출 기간은 중복될 수 없습니다.';
          }
        }
      });
    }

    // 변경하려는 노출 시작 일시가 현재 시각보다 이전일 때
    if (new Date(values.start_at) < now && new Date(currentStartAt).valueOf() !== new Date(values.start_at).valueOf()) {
      errors.start_at = '노출 시작 일시는 현재 시각 이후여야 합니다.';
    }

    // 변경하려는 노출 종료 일시가 현재 시각보다 이전일 때
    if (new Date(values.end_at) < now && new Date(currentEndAt).valueOf() !== new Date(values.end_at).valueOf()) {
      errors.end_at = '노출 종료 일시는 현재 시각 이후여야 합니다.';
    }

    // 노출 시작 일시가 종료 일시와 일치하거나 더 나중일 때
    if (new Date(values.start_at) >= new Date(values.end_at)) {
      errors.start_at = '노출 시작 일시는 종료 일시보다 이전이어야 합니다.';
      errors.end_at = '노출 종료 일시는 시작 일시보다 나중이어야 합니다.';
    }

    return errors;
  };

  return (
    <Edit title="서비스 설정 편집" {...props}>
      <SimpleForm validate={CustomValidation} toolbar={<AppSettingEditToolbar />} submitOnEnter={false} redirect="show">
        <div>
          <h3>설정 분류</h3>
        </div>
        <SelectInput validate={[required()]} label="대분류" source="group" choices={APPSETTING_GROUP} defaultValue={APPSETTING_GROUP[0].id} />
        <SelectInput validate={[required()]} label="분류" source="type" choices={APPSETTING_TYPE} />
        <div>
          <h3>노출 기간 설정</h3>
        </div>
        <DateTimeInput validate={[required()]} label="노출 시작 일시" source="start_at" variant="standard" />
        <DateTimeInput validate={[required()]} label="노출 종료 일시" source="end_at" variant="standard" />
        <>
          <div>
            <h3>내비게이션 바 설정 생성</h3>
            <p>'점 찍기' 설정을 켜면 해당 메뉴 이름 우상단에 빨간 점이 생깁니다</p>
            <img src="https://heyjoyce.com/image/event/%E1%84%8C%E1%85%A5%E1%86%B7-%E1%84%8D%E1%85%B5%E1%86%A8%E1%84%80%E1%85%B5-%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5.png" width="480" />
          </div>
          <FormDataConsumer>
            {({ formData }) =>
              formData &&
              formData.data.forEach((paths) => {
                // SelectInput 옵션에 중복으로 추가되지 않도록 항목 중복 검사
                if (paths && !NAV_PATHNAME.find((item) => item.name === paths.path)) {
                  NAV_PATHNAME.push({ id: paths.path, name: paths.path });
                }
              })
            }
          </FormDataConsumer>
          <ArrayInput source="data" label="">
            <SimpleFormIterator>
              <TextInput validate={[required()]} source="name" label="메뉴 이름" />
              <SelectInput
                validate={[required()]}
                source="path"
                label="연결 페이지"
                choices={NAV_PATHNAME}
                onCreate={() => {
                  const url = prompt('https://heyjoyce.com 뒤의 url을 넣어주세요.\n예 - /promotion/promotionmba3');
                  const newPathname = { id: url, name: url };
                  NAV_PATHNAME.push(newPathname);
                  return newPathname;
                }}
              />
              <BooleanInput defaultValue={false} source="is_highlighted" label="점 찍기" />
            </SimpleFormIterator>
          </ArrayInput>
        </>
      </SimpleForm>
    </Edit>
  );
};

export default EditAppSetting;
