const getProgramTypeName = (type) => {
  if (type === 'event') {
    return '이벤트';
  } else if (type === 'digitalcontents') {
    return '디지털 콘텐츠';
  } else {
    return '모임';
  }
};

const getProgramTypeValue = (type) => {
  if (type === 'event') {
    return 'event';
  } else if (type === 'digitalcontents') {
    return 'digitalcontents';
  } else {
    return 'moim';
  }
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const payStatusDict = {
  paid: {
    name: '결제 완료',
    color: '#0b867a',
  },
  cancelled: {
    name: '결제 취소',
    color: '#F04E44',
  },
  ready: {
    name: '결제 대기',
    color: '#FDDB00',
  },
};

const payMethodDict = {
  notimp: {
    name: '🕊무료결제',
  },
  coupon: {
    name: '🎟쿠폰결제',
  },
  card: {
    name: '💳신용카드',
  },
  trans: {
    name: '💵실시간 계좌이체',
  },
  vbank: {
    name: '🏧가상계좌이체',
  },
  kakaopay: {
    name: '💳카카오페이',
  },
  kakaomoney: {
    name: '💰카카오페이머니',
  },
};

const getFormattedDatetime = (timestamp) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours() % 12 || 12;
  const minute = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  const second = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();

  return `${year}년 ${month}월 ${day}일 ${date.getHours() < 12 ? '오전 ' : '오후 '} ${hour}시 ${minute}분 ${second}초`;
};

const youtubeParser = (content) => {
  var yt_prefix = '{{youtube:';
  var yt_appendix = '}}';
  var yt_length = content.match('{{youtube:');

  if (yt_length) {
    yt_length = content.match(/{{youtube:/g).length || 0;
    for (var i = 0; i < yt_length; i++) {
      var yt_id = content.split(yt_prefix)[1].split(yt_appendix)[0];
      var yt_target = yt_prefix + yt_id + yt_appendix;
      var yt_iframe = '<iframe class="youtube_block" src="https://www.youtube.com/embed/' + yt_id + '" frameborder="0" allowfullscreen></iframe>';
      content = content.replace(yt_target, yt_iframe);
    }
  }

  return content;
};

function leftPad(value) {
  if (value >= 10) {
    return value;
  }
  return `0${value}`;
}

function getTimestampSerial(timestamp) {
  var date = new Date(timestamp);
  var year = date.getFullYear();
  var month = leftPad(date.getMonth() + 1);
  var day = leftPad(date.getDate());
  var hour = leftPad(date.getHours());
  var minute = leftPad(date.getMinutes());
  var second = leftPad(date.getSeconds());
  return `${year}${month}${day}${hour}${minute}${second}`;
}

export { getProgramTypeName, getProgramTypeValue, numberWithCommas, payStatusDict, payMethodDict, getFormattedDatetime, youtubeParser, getTimestampSerial };
