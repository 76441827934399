import React from 'react';
import { List, Datagrid, Pagination, BooleanField, Filter, TextInput, SelectInput, CloneButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { getProgramTypeName, numberWithCommas } from 'helpers';
import { Link } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';

const useStyles = makeStyles({
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
    wordBreak: 'keep-all',
  },
  editButton: {
    fontWeight: 'bold',
  },
  layout: {
    width: '50ch',
  },
  showButton: {
    fontWeight: 'bold',
  },
  filterLayout: {
    width: '30ch',
  },
  programId: {
    color: '#1cad8c',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  programName: {
    display: 'block',
    color: '#1cad8c',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    '@media (max-width:1400px)': {
      minWidth: '250px',
    },
  },
  programMeets: {
    '& > span': {
      display: 'block',
      wordBreak: 'keep-all',
      minWidth: '200px',
    },
  },
  programCount: {
    span: {
      display: 'block',
      wordBreak: 'keep-all',
    },
  },
  testProgram: {
    color: '#f04e44',
  },
  tag: {
    backgroundColor: '#e8e8e8',
    padding: '2px 6px',
    borderRadius: '15px',
    fontSize: '13px',
    wordBreak: 'keep-all',
  },
  programType: {
    wordBreak: 'keep-all',
  },
  participationIcon: {
    color: '#f04e44',
  },
  copyButton: {
    color: '#f04e44',
  },
});

const ProgramPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const ProgramFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SelectInput
        className={classes.filterLayout}
        label="타입"
        source="type"
        choices={[
          { id: 'all', name: '전체' },
          { id: 'event', name: '이벤트' },
          { id: 'moim', name: '모임' },
          { id: 'digitalcontents', name: '디지털콘텐츠' },
        ]}
        allowEmpty={false}
        alwaysOn
      />
      <TextInput label="🔎 프로그램 ID,이름으로 검색" source="search" alwaysOn className={classes.layout} />
    </Filter>
  );
};

const ProgramIdLink = ({ record, source }) => {
  const classes = useStyles();
  const progarmUrl = `${process.env.REACT_APP_ENV === 'production' ? 'https://heyjoyce.com/program/' : 'http://dev.heyjoyce.com/program/'}${record.type}/${record[source]}`;
  return (
    <span className={`${classes.programId}`} onClick={() => window.open(progarmUrl, '_blank')}>
      {record[source]}
    </span>
  );
};

const ProgramNameLink = ({ record, source }) => {
  const classes = useStyles();
  return (
    <a className={`${classes.programName} ${record.test ? classes.testProgram : ''}`} href={`#/programs/${record.id}/show`}>
      {record[source]}
    </a>
  );
};

const ProgramTypeField = ({ record, source }) => {
  const classes = useStyles();
  return (
    <span className={classes.programType} href={record[source]}>
      {getProgramTypeName(record[source])}
    </span>
  );
};

const ProgramTagsField = ({ record, source }) => {
  const classes = useStyles();
  return (
    <div>
      {record[source].map((tag) => (
        <span className={classes.tag} key={tag.id}>
          {tag.tag}
        </span>
      ))}
    </div>
  );
};

const ProgramMeetsField = ({ record, source }) => {
  const classes = useStyles();
  const meets = record[source];

  if (meets.length === 0) {
    return <>일정이 없습니다</>;
  }

  const startAt = new Date(meets[0].start_at);
  const endAt = new Date(meets[meets.length - 1].end_at);
  const startAtString = `${startAt.toLocaleString()}`;
  let endAtString = `${endAt.toLocaleString()}`;

  // 연, 월, 일이 같은 경우
  if (startAt.getFullYear() === endAt.getFullYear() && startAt.getMonth() === endAt.getMonth() && startAt.getDate() === endAt.getDate()) {
    endAtString = `${endAt.toLocaleTimeString()}`;
  }

  return (
    <div className={classes.programMeets}>
      <span>{startAtString}</span>
      <span>{`~ ${endAtString}`}</span>
    </div>
  );
};

const ParticipationButton = ({ record, source }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.participationIcon}
      component={Link}
      to={{
        pathname: `/programs/${record.id}/show/participationList`,
      }}
    >
      <PeopleIcon />
    </Button>
  );
};

const ProgramCountField = ({ record, source }) => {
  const classes = useStyles();
  return (
    <div classes={classes.programCount}>
      <span>{record.current_associate_member_count}</span>
      <span>{`/${record.associate_member_limit === 999999 ? '무제한' : record.associate_member_limit}`}</span>
    </div>
  );
};

const PriceField = ({ record, source }) => <span>{numberWithCommas(record[source])}</span>;

const CustomCloneButton = ({ record }) => {
  const classes = useStyles();

  const recordForClone = {
    ...record,
    ...(record?.tags && {
      tags: record.tags?.map((item) => ({ name: item.tag })),
    }),
    ...(record?.meets && {
      programmeets: record.meets.map((item) => ({ start_at: item.start_at, end_at: item.end_at })),
    }),
    thumbnails: null,
  };

  return (
    <Button
      className={classes.copyButton}
      component={Link}
      to={{
        pathname: '/programs/create',
        state: {
          record: recordForClone,
        },
      }}
    >
      복사
    </Button>
  );
};

const ListProgram = (props) => {
  const classes = useStyles();
  return (
    <List {...props} pagination={<ProgramPagination />} bulkActionButtons={false} filters={<ProgramFilter />} perPage={25}>
      <Datagrid classes={{ headerCell: classes.headerCell }}>
        <ProgramIdLink label="ID" source="id" sortable={false} />
        <ProgramTypeField label="타입" source="type" sortable={false} />
        <ProgramNameLink label="프로그램명" source="name" sortable={false} />
        <ProgramMeetsField label="미팅일자" source="meets" sortable={false} />
        <BooleanField label="신청가능" source="register_available" sortable={false} />
        <ProgramCountField label="신청인원" sortable={false} textAlign={'right'} />
        <PriceField label="판매가" source="associate_member_price" sortable={false} textAlign={'right'} />
        <PriceField label="정가" source="member_price" sortable={false} textAlign={'right'} />
        <BooleanField label="관리자만 노출" source="test" sortable={false} />
        <CustomCloneButton />
        <ParticipationButton label="신청 현황" />
      </Datagrid>
    </List>
  );
};

export default ListProgram;
