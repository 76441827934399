import React, { useState, useEffect } from 'react';
import { Link } from 'react-admin';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper } from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { gql } from '@apollo/client';
import { listAppSettings } from 'graphql/queries';
import { client } from 'graphql/providers';
import { defaultNavSetting } from 'helpers/constants';

const useStyles = makeStyles({
  topToolBar: {
    margin: '10px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  filterSelect: {
    width: '150px',
    height: '45px',
    marginRight: '10px',
    padding: '10px',
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    fontSize: '1rem',
    '&:focus': {
      outline: 'none',
    },
  },
  filterInput: {
    width: '150px',
    height: '24px',
    marginRight: '10px',
    padding: '10px',
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    fontSize: '1rem',
    '&:focus': {
      outline: 'none',
    },
  },
  filterSearch: {
    width: '350px',
    height: '24px',
    marginRight: '10px',
    padding: '10px',
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    fontSize: '1rem',
    '&:focus': {
      outline: 'none',
    },
  },
  controlRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    color: '#F04E44',
  },
  headerCell: {
    color: 'white',
    backgroundColor: '#3a3a3a',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  column: {
    maxWidth: '300px',
    textAlign: 'center',
  },
  columnLeft: {
    maxWidth: '300px',
    textAlign: 'left',
  },
  columnbodyMaxWidth: {
    display: 'block',
    maxWidth: '300px',
  },
  statusDelete: {
    padding: '2px 6px',
    borderRadius: '15px',
    color: 'black',
    backgroundColor: '#FFE1E3',
    fontSize: '13px',
    wordBreak: 'keep-all',
  },
  link: {
    textDecoration: 'none',
    color: '#1cad8c',
    '&:hover': {
      color: '#F04E44',
      cursor: 'pointer',
      transitionProperty: 'color',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'ease-out',
    },
  },
  edit: {
    color: '#F04E44',
    textDecorationLine: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  pagination: {
    alignSelf: 'center',
  },
});

const TYPE = { nav: '내비게이션 바' };

const AppSettingPagination = ({ nextToken, setNextToken, page, setPage }) => {
  const classes = useStyles();

  return (
    <div className={classes.pagination}>
      {nextToken && (
        <Button
          color="primary"
          key="next"
          onClick={() => {
            setNextToken(nextToken);
            setPage(++page);
          }}
        >
          더 보기
          <ExpandMoreIcon />
        </Button>
      )}
    </div>
  );
};

const AppSettingDetailLink = ({ appSettingId, appSettingValue }) => {
  const classes = useStyles();

  return (
    <a className={classes.link} href={`#/appSettings/${appSettingId}/show`}>
      {appSettingValue}
    </a>
  );
};

const AppSettingEditLink = ({ appSettingId }) => {
  const classes = useStyles();

  return (
    <a className={classes.edit} href={`#/appSettings/${appSettingId}`}>
      <EditIcon />
      수정
    </a>
  );
};

const ListAppSetting = () => {
  const now = new Date();
  const classes = useStyles();

  const [appSettings, setAppSettings] = useState([]);
  const [nextToken, setNextToken] = useState('');
  const [page, setPage] = useState(0);
  const [filterType, setFilterType] = useState('');
  const [filterIsRunning, setFilterIsRunning] = useState(false);

  const fetchData = async () => {
    const { data } = await client.query({
      query: gql`
        ${listAppSettings}
      `,
      variables: {
        filter: {
          group: 'main',
          ...(filterIsRunning ? { is_running: true } : {}),
        },
        ...(filterIsRunning ? {} : { limit: 10 }),
        ...(filterIsRunning ? {} : { nextToken }),
      },
    });
    return data;
  };

  useEffect(() => {
    fetchData().then((data) => {
      if (page === 0) {
        // 최근에 생성한 순으로 정렬 (생성일 기준 내림차순)
        setAppSettings(
          data.listAppSettings.items.sort(function (a, b) {
            return new Date(b.start_at) - new Date(a.start_at);
          })
        );
      } else {
        setAppSettings(
          appSettings.concat(data.listAppSettings.items).sort(function (a, b) {
            return new Date(b.start_at) - new Date(a.start_at);
          })
        );
      }
      setNextToken(data.listAppSettings.nextToken);
    });
  }, [page, filterIsRunning]);

  const handleChangeType = (event) => {
    setFilterType(event.target[event.target.selectedIndex].value);
    setNextToken('');
  };

  return (
    <>
      <div className={classes.topToolBar}>
        <div>
          <select className={classes.filterSelect} value={filterType} onChange={handleChangeType}>
            <option value="navbar">내비게이션 바</option>
          </select>
          <input type="checkbox" onChange={(e) => setFilterIsRunning(e.target.checked)} /> {'노출 중인 설정만'}
        </div>
        <div className={classes.controlRow}>
          <Button
            className={classes.actionButton}
            component={Link}
            to={{
              pathname: '/appsettings/create',
            }}
          >
            <AddIcon />
            설정 생성
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>분류</TableCell>
              <TableCell className={classes.headerCell}>노출 시작일</TableCell>
              <TableCell className={classes.headerCell}>노출 종료일</TableCell>
              <TableCell className={classes.headerCell}>생성일</TableCell>
              <TableCell className={classes.headerCell}>설정값</TableCell>
              <TableCell className={classes.headerCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* 기본값 상단 고정 */}
            <TableCell className={classes.column}>내비게이션 바</TableCell>
            <TableCell className={classes.column}></TableCell>
            <TableCell className={classes.column}>기본값</TableCell>
            <TableCell className={classes.column}></TableCell>
            <TableCell className={classes.column}>{defaultNavSetting.map((value, i) => `${++i}. ${value.name} `)}</TableCell>
            <TableCell className={classes.column}></TableCell>
            {/* 진짜 설정값 리스트 */}
            {appSettings.map((row) => (
              <TableRow key={row.id} style={{ backgroundColor: new Date(row.start_at) <= now && new Date(row.end_at) >= now ? '#FDDB00' : '#FFFFFF' }}>
                <TableCell className={classes.column}>{TYPE[row.type]}</TableCell>
                <TableCell className={classes.column}>{new Date(row.start_at).toLocaleString()}</TableCell>
                <TableCell className={classes.column}>{new Date(row.end_at).toLocaleString()}</TableCell>
                <TableCell className={classes.column}>{new Date(row.created_at).toLocaleString()}</TableCell>
                <TableCell className={classes.columnLeft}>
                  <AppSettingDetailLink appSettingId={row.id} appSettingValue={JSON.parse(row.data).map((value, i) => `${++i}. ${value.name} `)}></AppSettingDetailLink>
                </TableCell>
                <TableCell className={classes.column}>
                  <AppSettingEditLink appSettingId={row.id}></AppSettingEditLink>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {filterIsRunning ? '' : <AppSettingPagination nextToken={nextToken} setNextToken={setNextToken} page={page} setPage={setPage} />}
    </>
  );
};

export default ListAppSetting;
