import { getProgramTypeValue } from 'helpers';
import { adminAxios } from 'helpers/axios';
import { getMinutes, setSeconds } from 'date-fns';

const programProvider = {
  getList: async (resource, params) => {
    const { filter, pagination } = params;
    const offset = (pagination.page - 1) * pagination.perPage;

    let queryParams = '?limit=' + pagination.perPage + '&offset=' + offset;

    if (filter.type && filter.type !== 'all') {
      queryParams += '&type=' + (filter.type === 'moim' ? 'clubjoyce&type=projectjoyce' : filter.type);
    }

    if (filter.search) {
      queryParams += '&search=' + filter.search;
    }

    const { data } = await adminAxios({
      method: 'GET',
      uri: '/programs/',
      query: queryParams,
    });
    return {
      data: data.results,
      total: data.count,
    };
  },
  getOne: async (resource, params) => {
    const supporterArray = [];

    const { data } = await adminAxios({
      method: 'GET',
      uri: '/programs/',
      query: params.id + '/',
    });

    if (data.supporter) {
      const { data: supporterData } = await adminAxios({
        method: 'GET',
        uri: '/users/',
        query: data.supporter + '/',
      });
      supporterArray.push(supporterData);
    }

    const activeTab = {
      leader_info: data?.data?.detail?.activeTab?.leader_info === undefined ? true : data?.data?.detail?.activeTab?.leader_info,
      notice: data?.data?.detail?.activeTab?.notice === undefined ? true : data?.data?.detail?.activeTab?.notice,
      review: data?.data?.detail?.activeTab?.review === undefined ? true : data?.data?.detail?.activeTab?.review,
      sale: data?.data?.detail?.activeTab?.sale === undefined ? true : data?.data?.detail?.activeTab?.sale,
    };

    return {
      data: {
        ...data,
        associate_member_no_limit: data.associate_member_limit >= 999999 ? true : false,
        member_no_limit: data.member_limit >= 999999 ? true : false,
        type: getProgramTypeValue(data.type),
        leaders: data.leaders.map((l) => l.id),
        leaderObjects: data.leaders,
        supporterObject: supporterArray,
        data: {
          ...data.data,
          detail: {
            ...data?.data?.detail,
            activeTab,
          },
        },
      },
    };
  },
  create: async (resource, params) => {
    let programId;
    const {
      associate_member_no_limit,
      course_id,
      member_no_limit,
      name,
      summary,
      description,
      type,
      category_ids,
      test,
      register_available,
      opened,
      register_start_at,
      register_end_at,
      associate_member_limit,
      member_limit,
      member_price,
      associate_member_price,
      supporter,
      tags,
      thumbnails,
      leaders,
      programmeets,
      data: jsonData,
      alimtalk,
    } = params.data;

    // 새 프로그램 등록
    const { data: programResult } = await adminAxios({
      method: 'POST',
      uri: '/programs/',
      query: '',
      data: {
        name,
        summary,
        description,
        category_ids,
        test,
        register_available,
        opened,
        member_price,
        associate_member_price,
        supporter,
        leaders,
        register_start_at,
        register_end_at: getMinutes(register_end_at) === 59 ? setSeconds(register_end_at, 59) : register_end_at,
        type: type === 'moim' ? 'clubjoyce' : type, // moim 선택 시 clubjoyce로 저장
        member_limit: member_no_limit ? 999999 : member_limit, // 무제한으로 설정 시 값을 999999로 설정
        associate_member_limit: associate_member_no_limit ? 999999 : associate_member_limit, // 무제한으로 설정 시 값을 999999로 설정
        associate_member_available: true, // 모든 멤버를 associate로 보고 있음 (2021.01.10 기준)
        lite_member_available: true,
        standard_member_available: true,
        premium_member_available: true,
        data: { teachable: { course_id: course_id || null }, ...jsonData }, // course_id는 data에 담김
        alimtalk,
      },
    });

    if (!programResult || !Object.prototype.hasOwnProperty.call(programResult, 'id')) {
      throw new Error('create program is failed!');
    }

    alert('프로그램 등록 완료!');
    programId = programResult.id;

    try {
      // programtags
      if (tags) {
        for await (const tag of tags) {
          await adminAxios({
            method: 'POST',
            uri: '/programTags/',
            query: '',
            data: {
              tag: tag.name,
              program: programId,
            },
          });
        }
        console.log('프로그램 태그 등록 완료');
      }
    } catch (error) {
      alert('프로그램 태그 등록 에러: ');
      return { data: {} };
    }

    try {
      // programmeets
      if (programmeets) {
        for await (const programmeet of programmeets) {
          await adminAxios({
            method: 'POST',
            uri: '/programMeets/',
            query: '',
            data: {
              program: programId,
              start_at: programmeet.start_at,
              end_at: programmeet.end_at,
            },
          });
        }
        console.log('프로그램 일정 등록 완료');
      }
    } catch (error) {
      alert('프로그램 일정 등록 에러: ');
      return { data: {} };
    }

    try {
      // programthumbnails
      for await (const thumbnail of thumbnails) {
        // 새 썸네일 등록
        var formData = new FormData();
        formData.append('program', programId);
        formData.append('thumbnail', thumbnail instanceof File ? thumbnail : thumbnail.rawFile);
        await adminAxios({
          method: 'POST',
          uri: '/programThumbnails/',
          query: '',
          data: formData,
        });
      }

      console.log('프로그램 썸네일 등록 완료');
    } catch (error) {
      alert('프로그램 썸네일 등록 에러: ');
      return { data: {} };
    }

    return {
      data: programResult,
    };
  },
  update: async (resource, params) => {
    const { id, data, previousData } = params;

    const {
      associate_member_limit,
      associate_member_no_limit,
      associate_member_price,
      category_ids,
      data: jsonData,
      leaders,
      supporter,
      description,
      member_limit,
      member_no_limit,
      member_price,
      type,
      name,
      opened,
      register_available,
      register_end_at,
      register_start_at,
      summary,
      test,
      alimtalk,
    } = data;

    // 프로그램 수정
    const { data: programResult } = await adminAxios({
      method: 'PATCH',
      uri: `/programs/${id}/`,
      query: '',
      data: {
        name,
        summary,
        description,
        category_ids,
        test,
        register_available,
        opened,
        member_price,
        associate_member_price,
        leaders,
        supporter,
        register_start_at,
        register_end_at: getMinutes(register_end_at) === 59 ? setSeconds(register_end_at, 59) : register_end_at,
        type: type === 'moim' ? 'clubjoyce' : type, // moim 선택 시 clubjoyce로 저장
        member_limit: member_no_limit ? 999999 : member_limit, // 무제한으로 설정 시 값을 999999로 설정
        associate_member_limit: associate_member_no_limit ? 999999 : associate_member_limit, // 무제한으로 설정 시 값을 999999로 설정
        data: jsonData,
        alimtalk,
      },
    });

    if (!programResult || !Object.prototype.hasOwnProperty.call(programResult, 'id')) {
      throw new Error('update program is failed!');
    }

    alert('프로그램 수정 완료!');

    try {
      // programtags
      if (JSON.stringify(data.tags) !== JSON.stringify(previousData.tags)) {
        alert('프로그램 태그가 수정되었습니다');
        // programtags
        for await (const tag of previousData.tags) {
          await adminAxios({
            method: 'DELETE',
            uri: `/programTags/${tag.id}`,
            query: '',
          });
        }
        for await (const tag of data.tags) {
          await adminAxios({
            method: 'POST',
            uri: '/programTags/',
            query: '',
            data: {
              tag: tag.tag,
              program: id,
            },
          });
        }
        console.log('프로그램 태그 등록 완료');
      }
    } catch (error) {
      alert('프로그램 태그 등록 에러: ');
      return { data: {} };
    }

    try {
      // programmeets
      if (JSON.stringify(data.meets) !== JSON.stringify(previousData.meets)) {
        alert('프로그램 일정이 수정되었습니다');
        for await (const meet of previousData.meets) {
          await adminAxios({
            method: 'DELETE',
            uri: `/programMeets/${meet.id}`,
            query: '',
          });
        }
        for await (const programmeet of data.meets) {
          await adminAxios({
            method: 'POST',
            uri: '/programMeets/',
            query: '',
            data: {
              program: id,
              start_at: programmeet.start_at,
              end_at: programmeet.end_at,
            },
          });
        }
      }
    } catch (error) {
      alert('프로그램 일정 등록 에러: ');
      return { data: {} };
    }

    try {
      // programthumbnails
      if (JSON.stringify(data.thumbnails) !== JSON.stringify(previousData.thumbnails)) {
        alert('썸네일이 수정되었습니다');

        // 삭제된 썸네일 처리
        for await (const thumbnail of previousData.thumbnails) {
          if (!data.thumbnails.some((i) => i.id === thumbnail.id)) {
            await adminAxios({
              method: 'DELETE',
              uri: `/programThumbnails/${thumbnail.id}`,
              query: '',
            });
          }
        }

        if (data.thumbnails) {
          for await (const thumbnail of data.thumbnails) {
            // 새 썸네일 등록
            if (!previousData.thumbnails.some((i) => i.id === thumbnail.id)) {
              var formData = new FormData();
              formData.append('program', id);
              formData.append('thumbnail', thumbnail instanceof File ? thumbnail : thumbnail.rawFile);
              await adminAxios({
                method: 'POST',
                uri: '/programThumbnails/',
                query: '',
                data: formData,
              });
            }
          }
        }
        console.log('프로그램 썸네일 등록 완료');
      }
    } catch (error) {
      alert('프로그램 썸네일 등록 에러: ');
      return { data: {} };
    }

    return {
      data: programResult,
    };
  },
  delete: async (resource, params) => {
    const result = await adminAxios({
      method: 'DELETE',
      uri: '/programs/' + params.id,
      query: '',
    });
    return {
      ...result,
    };
  },
};

export default programProvider;
