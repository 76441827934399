import React from 'react';
import { List, Datagrid, TextField, DateField, BooleanField, SelectInput, BooleanInput, Filter, TextInput, Pagination, EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
  },
  editButton: {
    fontWeight: 'bold',
  },
});

const FeedFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="타입"
      source="type"
      choices={[
        { id: 'qna', name: 'Q&A' },
        { id: 'ask', name: 'ASK' },
      ]}
      alwaysOn
    />
    <BooleanInput source="is_pinned" label="메인노출" initialValue={false} alwaysOn />
    <TextInput label="🔎 키워드 검색" source="search" alwaysOn resettable />
  </Filter>
);

const FeedPagination = (props) => <Pagination rowsPerPageOptions={[20, 50, 100, 200]} {...props} />;

const EditFeedButton = (props) => {
  const classes = useStyles();
  const { type = 'qna' } = props.record;

  return type === 'qna' ? <EditButton className={classes.editButton} {...props} /> : <div />;
};

const DescriptionPanel = ({ record }) => (
  <div>
    <div dangerouslySetInnerHTML={{ __html: record.data.body }} />
  </div>
);

const ListFeed = (props) => {
  const classes = useStyles();

  return (
    <List {...props} pagination={<FeedPagination />} bulkActionButtons={false} filters={<FeedFilter />}>
      <Datagrid expand={<DescriptionPanel />} classes={{ headerCell: classes.headerCell }}>
        <TextField label="타입" source="type" sortable={false} />
        <TextField label="카테고리" source="category_id" sortable={false} />
        <TextField label="펠로우지정" source="data.fellow_text" sortable={false} />
        <TextField label="제목" source="data.title" sortable={false} />
        <TextField label="작성자ID" source="data.author.id" sortable={false} />
        <TextField label="작성자" source="data.author.username" sortable={false} />
        <TextField label="회사" source="data.author.company" sortable={false} />
        <TextField label="직업" source="data.author.job" sortable={false} />
        <TextField label="연령" source="data.author.age" sortable={false} />
        <BooleanField label="프로필공개" source="data.author.is_public" sortable={false} />
        <DateField label="작성일" source="created_at" showTime={true} />
        <BooleanField label="메인노출" source="is_pinned" sortable={false} />
        <EditFeedButton label="편집" />
      </Datagrid>
    </List>
  );
};

export default ListFeed;
