import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  DateTimeInput,
  required,
  SaveButton,
  Toolbar,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  useRefresh,
  useRedirect,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { Button, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '50ch',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  inputRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > span:first-child': {
      width: '130px',
      marginBottom: '10px',
      color: '#484848',
      fontWeight: 'bold',
      justifyContent: 'flex-end',
      textAlign: 'right',
      padding: '20px',
    },
    '& .MuiFormControl-root': {
      minWidth: '250px',
    },
  },
  stageTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    paddingBottom: '8px',
    width: '100%',
    backgroundColor: '#3e3e3e',
    color: 'white',
    padding: '4px 12px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    marginTop: '25px',
    marginBottom: '20px',
  },
  radioButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '5px 12px',
    fontWeight: 'bold',
    fontSize: '1.1em',
    marginBottom: '15px',
    '& div': {
      display: 'flex',
      flexDirection: 'row',
    },
    '& div:last-child': {
      marginLeft: '15px',
    },
    '& input[type="radio"], input[type="radio"]:checked': {
      appearance: 'none',
      width: '1.1rem',
      height: '1.1rem',
      borderRadius: '100%',
    },
    '& input[type="radio"]': {
      backgroundColor: 'white',
      border: '2px solid gray',
      margin: '2px 5px 2px 2px',
    },
    '& input[type="radio"]:checked': {
      backgroundColor: '#fddb00',
    },
    '& input[type="radio"]:focus': {
      outline: 'none',
    },
    '& input[type="radio"]:hover, label:hover': {
      cursor: 'pointer',
    },
  },
  orderInputWrapper: {
    width: '100%',
  },
}));

const IMP_UID_DEFAULT = 'imp_adm_';
const MERCHANT_UID_DEFAULT = 'program_adm_';

const userOptionRenderer = (choice) => (choice ? (choice.id ? `${choice.id}/${choice.username}/${choice.company}/${choice.job}/${choice.email}` : '❌선택안함') : '');

const CreateParticipationActions = ({ basePath, data, resource, programId }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <TopToolbar className={classes.header}>
      {programId && (
        <div>
          <Button onClick={() => history.push(`/programs/${programId}/show/participationList`)}>
            <ArrowBackIcon />
          </Button>
          <b>{`프로그램 #${programId}`}</b> 에 참석자 등록하기
        </div>
      )}
    </TopToolbar>
  );
};

const CreateParticipationToolbar = (props) => {
  const { programId } = props;
  const refresh = useRefresh();
  const redirect = useRedirect();

  const createRedirect = (basePath, id, data) => `/participations/create/?programId=${programId}`;

  const handleSuccessSave = () => {
    redirect(`/programs/${programId}/show/participationList`);
    refresh();
  };

  return (
    <Toolbar {...props}>
      <SaveButton label="저장" onSuccess={handleSuccessSave} submitOnEnter={true} />
      <SaveButton label="저장하고 계속 생성하기" redirect={createRedirect} submitOnEnter={false} variant="text" />
    </Toolbar>
  );
};

const CreateParticipation = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [isCreateOrder, setIsCreateOrder] = useState(true);

  const { programId } = parse(history.location.search);

  const redirect = (basePath, id, data) => `/programs/${programId}/show/participationList`;

  const handleClickRadioButton = (e) => {
    setIsCreateOrder(!isCreateOrder);
  };

  return (
    <Create title="참석자 생성하기" actions={<CreateParticipationActions programId={programId} />} {...props}>
      <SimpleForm toolbar={<CreateParticipationToolbar programId={programId} />} redirect={redirect}>
        <div className={classes.inputRow}>
          <span>프로그램 아이디</span>
          <TextInput source="programId" label=" " defaultValue={programId} validate={[required()]} variant="standard" />
        </div>
        <Typography variant="h6" className={classes.stageTitle}>
          {'1️⃣ 결제 정보 입력'}
        </Typography>
        <div className={classes.radioButtonWrapper}>
          <div>
            <input type="radio" name="orderCreateType" value={true} checked={isCreateOrder === true} onChange={handleClickRadioButton} />
            <label onClick={handleClickRadioButton}>결제 데이터 직접 생성</label>
          </div>
          <div>
            <input type="radio" name="orderCreateType" value={false} checked={isCreateOrder === false} onChange={handleClickRadioButton} />
            <label onClick={handleClickRadioButton}>기존 결제 데이터 입력</label>
          </div>
        </div>
        {isCreateOrder ? (
          <div className={classes.orderInputWrapper}>
            <div className={classes.inputRow}>
              <span>결제 유저 ID</span>
              <NumberInput source="user" label=" " validate={[required()]} variant="standard" helperText="이메일 말고 숫자로 된 ID 입력" />
            </div>
            <div className={classes.inputRow}>
              <span>결제 일시</span>
              <DateTimeInput source="created_at" label=" " validate={[required()]} variant="standard" />
            </div>
            <div className={classes.inputRow}>
              <span>Imp uid</span>
              <TextInput
                source="imp_uid"
                label=" "
                validate={[required()]}
                variant="standard"
                helperText="비결제방식은 imp_adm_연연연연월월일일시시분분초초 형식으로 임의 생성 후 입력, 유료 결제는 아임포트 콘솔에서 imp_uid 확인 후 입력"
              />
            </div>
            <div className={classes.inputRow}>
              <span>Merchant uid</span>
              <TextInput
                source="merchant_uid"
                label=" "
                validate={[required()]}
                variant="standard"
                defaultValue={MERCHANT_UID_DEFAULT}
                helperText="program_adm_U유저아이디T연연월월일일시시분분초초로 임의 생성 (예 - program_adm_U10170T211101152300)"
              />
            </div>
            <div className={classes.inputRow}>
              <span>결제 금액</span>
              <TextInput source="price" label=" " validate={[required()]} variant="standard" />
            </div>
            <div className={classes.inputRow}>
              <span>결제 수단</span>
              <SelectInput
                source="method"
                choices={[
                  { id: 'notimp', name: '비결제방식' },
                  { id: 'card', name: '신용카드' },
                  { id: 'vbank', name: '가상계좌' },
                  { id: 'trans', name: '실시간계좌이체' },
                  { id: 'kakaopay', name: '카카오페이' },
                  { id: 'kakaomoney', name: '카카오페이머니' },
                ]}
                validate={[required()]}
                label=" "
                variant="standard"
              />
            </div>
            <div className={classes.inputRow}>
              <span>결제 상태</span>
              <SelectInput
                source="status"
                choices={[
                  { id: 'paid', name: '결제 완료' },
                  { id: 'ready', name: '입금 대기' },
                  { id: 'cancelled', name: '취소' },
                ]}
                validate={[required()]}
                label=" "
                variant="standard"
              />
            </div>
          </div>
        ) : (
          <>
            <div className={classes.inputRow}>
              <span>Imp uid</span>
              <TextInput source="imp_uid_exist" label=" " validate={[required()]} variant="standard" defaultValue={IMP_UID_DEFAULT} />
            </div>
          </>
        )}
        <Typography variant="h6" className={classes.stageTitle}>
          {'2️⃣ 참석 정보 입력'}
        </Typography>
        <div className={classes.inputRow}>
          <span>참석자 선택</span>
          <ReferenceInput label=" " source="user" reference="searchUsers" variant="standard" fullWidth={true} allowEmpty>
            <AutocompleteInput optionText={userOptionRenderer} helperText="이메일로 검색" />
          </ReferenceInput>
        </div>
      </SimpleForm>
    </Create>
  );
};

export default CreateParticipation;
