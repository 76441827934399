import { useState, useEffect } from 'react';
import defaultAxios from 'axios';
import { AUTH_KEY } from 'graphql/providers';

const useAdminApi = (opts, axiosInstance = defaultAxios) => {
  const [state, setState] = useState({
    loading: true,
    error: null,
    data: null
  });

  const { method, url, data } = opts;
  if (!url) {
    throw new Error('url is required');
  }

  useEffect(() => {
    let isSubscribed = true;
    const token = localStorage.getItem(AUTH_KEY);

    axiosInstance({
      method,
      url: process.env.REACT_APP_ADMIN_API_URL + url,
      data,
      ...(token && {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(token).access
        }
      })
    })
      .then(({ data }) => {
        if (isSubscribed) {
          setState({
            ...state,
            loading: false,
            data
          });
        }
      })
      .catch(async error => {
        setState({ ...state, loading: false, error });
      });
    // clean up
    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method, url, data]);

  return { ...state };
};

export default useAdminApi;
