import React from 'react';
import { List, Datagrid, TextField, TextInput, Pagination, Filter, SelectField, SelectInput, DateField, DateInput, NumberField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { payStatusDict } from 'helpers';

const useStyles = makeStyles({
  search: {
    width: '50ch',
  },
  expandArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '& > div': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    '& > Button': {
      backgroundColor: '#999999',
      color: '#FFFFFF',
      fontWeight: 'bold',
    },
  },
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
    textAlign: 'center',
  },
  gridColumn: {
    display: 'flex',
    flexDirection: 'column',
    color: 'gray',
    '& > span:first-child': {
      color: 'black',
    },
  },
  statusChip: {
    padding: '2px 6px',
    borderRadius: '4px',
    width: 'max-content',
    color: 'white',
  },
  link: {
    textDecoration: 'none',
    color: '#1cad8c',
    '&:hover': {
      color: '#F04E44',
      cursor: 'pointer',
      transitionProperty: 'color',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'ease-out',
    },
  },
});

const methods = [
  { id: 'notimp', name: '🕊무료결제' },
  { id: 'card', name: '💳신용카드' },
  { id: 'trans', name: '💵실시간 계좌이체' },
  { id: 'vbank', name: '🏧가상계좌이체' },
  { id: 'kakaopay', name: '💳카카오페이' },
  { id: 'kakaomoney', name: '💰카카오페이머니' },
];

const OrderFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SelectInput label="결제수단" source="method" choices={methods} alwaysOn />
      <SelectInput
        label="결제상태"
        source="status"
        choices={[
          { id: 'paid', name: '결제 완료' },
          { id: 'cancelled', name: '결제 취소' },
          { id: 'ready', name: '결제 대기' },
        ]}
        alwaysOn
      />
      <DateInput label="검색기간 (시작일)" source="range_start" alwaysOn />
      <DateInput label="검색기간 (종료일)" source="range_end" alwaysOn />
      <TextInput label="🔎 유저 이름, 이메일, 휴대폰 번호, imp_uid로 검색" source="search" resettable={true} alwaysOn className={classes.search} />
    </Filter>
  );
};

const OrderPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

const OrderStatusField = ({ record }) => {
  const classes = useStyles();

  if (!record.status) {
    return <div />;
  }

  let { name, color } = payStatusDict[record.status];

  if (record.refund_price && record.price > record.refund_price && record.status === 'cancelled') {
    name = '부분 취소';
    color = '#E69D00';
  }

  return (
    <div className={classes.gridColumn}>
      <span className={classes.statusChip} style={{ backgroundColor: color, color: 'white' }}>
        {name}
      </span>
    </div>
  );
};

const UserNameLink = ({ record }) => {
  const classes = useStyles();

  return (
    <a className={classes.link} href={`#/users/${record ? record.user.id : ''}/show`} target="_blank" rel="noopener noreferrer">
      {record.user.username}
    </a>
  );
};

const ListOrder = (props) => {
  const classes = useStyles();
  const now = new Date();
  const formatted_now = now.toISOString().split('T')[0];
  return (
    <List
      {...props}
      pagination={<OrderPagination />}
      filters={<OrderFilter />}
      filterDefaultValues={{ range_start: formatted_now, range_end: formatted_now }}
      sort={{ field: 'create_at', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid classes={{ headerCell: classes.headerCell }} rowClick="show">
        <TextField label="유저 ID" textAlign="center" source="user.id" sortable={true} />
        <UserNameLink label="이름" textAlign="center" source="user.username" sortable={false} />
        <TextField label="이메일" textAlign="center" source="user.email" sortable={false} />
        <TextField label="결제 ID (imp_uid)" textAlign="center" source="id" sortable={false} />
        <NumberField label="실결제금액" textAlign="center" source="price" sortable={false} options={{ style: 'currency', currency: 'KRW' }} />
        <NumberField label="환불한 금액" textAlign="center" source="refund_price" sortable={true} options={{ style: 'currency', currency: 'KRW' }} />
        <SelectField label="결제수단" textAlign="center" source="method" choices={methods} sortable={false} />
        <TextField label="사용한 쿠폰 ID" textAlign="center" source="participation.used_coupon" sortable={false} />
        <DateField label="결제 일시" textAlign="center" showTime={true} source="created_at" sortable={true} />
        <OrderStatusField label="결제상태" textAlign="center" source="status" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default ListOrder;
