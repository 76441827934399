import React from 'react';
import {
  List,
  Datagrid,
  SelectField,
  TextField,
  ImageField,
  EditButton,
  DeleteWithConfirmButton,
  TextInput,
  Pagination,
  Filter,
  BooleanField,
  BooleanInput,
  SelectInput,
  NumberField,
  DateField,
  UrlField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  search: {
    width: '50ch',
  },
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
    textAlign: 'center',
  },
  bannerType: {
    display: 'block',
    width: '60px',
    textAlign: 'center',
  },
  bannerDescription: {
    display: 'block',
    minWidth: '100px',
    maxWidth: '150px',
  },
  bannerImage: {
    '& img': {
      maxWidth: '150px',
    },
    textAlign: 'center',
  },
  filterLayout: {
    width: '150px',
  },
});

const locationRange = [
  { id: 'MAIN_TOP', name: '메인 상단' },
  { id: 'MAIN_BOTTOM', name: '메인 하단' },
];

const BannerFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SelectInput
        className={classes.filterLayout}
        label="배너 타입"
        source="banner_type"
        alwaysOn
        choices={[
          { id: 'MAIN_TOP', name: '메인 상단' },
          { id: 'MAIN_BOTTOM', name: '메인 하단' },
        ]}
      />
      <SelectInput
        className={classes.filterLayout}
        label="노출 대상"
        source="target"
        alwaysOn
        choices={[
          { id: 'target_guest', name: '비로그인' },
          { id: 'target_associate', name: '로그인' },
          { id: 'test', name: 'Test' },
        ]}
      />
      <TextInput label="🔎 id, 배너 설명, 또는 타이틀 텍스트로 검색" source="search" resettable={true} alwaysOn className={classes.search} />
      <BooleanInput source="is_active" label="노출 중인 배너만" initialValue={false} alwaysOn />
    </Filter>
  );
};

const BannerPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

const EditField = (props) => (
  <>
    <EditButton {...props} />
    <DeleteWithConfirmButton {...props} confirmContent="🚨 정말로 삭제하시려면 [✔확인]을 눌러주세요." confirmTitle={`"${props.record.title}" 삭제`} />
  </>
);

const ListBanner = (props) => {
  const classes = useStyles();
  return (
    <List {...props} pagination={<BannerPagination />} filters={<BannerFilter />} sort={{ field: 'priority', order: 'DESC' }} bulkActionButtons={false}>
      <Datagrid classes={{ headerCell: classes.headerCell }} rowClick="show">
        <TextField label="ID" textAlign="center" source="id" sortable={true} />
        <SelectField label="배너 타입" className={classes.bannerType} source="banner_type" choices={locationRange} sortable={true} />
        <TextField label="배너 설명 (관리자 식별용)" className={classes.bannerDescription} source="description" sortable={false} />
        <ImageField label="배너 이미지" className={classes.bannerImage} source="image_url" />
        <UrlField label="랜딩 URL" source="link" sortable={false} />
        <DateField label="노출 시작 일시" showTime={true} source="start_date" sortable={true} />
        <DateField label="노출 종료 일시" showTime={true} source="end_date" sortable={true} />
        <NumberField label="노출 가중치" textAlign="center" source="priority" sortable={true} />
        <BooleanField label="비로그인" source="target_guest" />
        <BooleanField label="로그인" source="target_associate" />
        <BooleanField label="Test" source="test" />
        <EditField />
      </Datagrid>
    </List>
  );
};

export default ListBanner;
