import * as React from 'react';
import {
  useEditController,
  BooleanInput,
  Edit,
  SimpleForm,
  FormDataConsumer,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  DateTimeInput,
  TopToolbar,
  ShowButton,
  SelectInput,
  Toolbar,
  SaveButton,
  AutocompleteArrayInput,
  useAuthProvider,
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { industryList, positionList } from 'helpers/categories';
import useAdminApi from 'hooks/useAdminApi';

const PostEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'auto',
  },
  subContainer: {
    width: 'calc(50% - 30px)',
    flexDirection: 'column',
  },
  miniContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5',
  },
  indentedContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
    backgroundColor: '#dfdfdf',
  },
  divider: {
    margin: '15px 0px 30px 0px',
    borderTop: 0,
    borderWidth: 1,
    borderColor: '#dfdfdf',
  },
  fellowHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '& > span': {
      color: 'gray',
      fontSize: '14px',
    },
  },
  arrayInput: {
    width: '100%',
  },
  textInput: {
    '.Mui-disabled': {
      background: 'green',
    },
  },
  infoTitle: {
    fontWeight: 'bold',
  },
  stageDescription: {
    color: 'rgb(122,122,122)',
    fontSize: '12px',
    position: 'relative',
    top: '-7px',
  },
  content: {
    width: '70%',
  },
}));

const EditUser = (props) => {
  const classes = useStyles();
  const authProvider = useAuthProvider();
  const [currentUser, setCurrentUser] = React.useState(null);

  React.useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const user = await authProvider.getPermissions();
        setCurrentUser(user);
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    fetchCurrentUser();
  }, [authProvider]);

  const { data: categories } = useAdminApi({
    method: 'GET',
    url: '/categories/',
  });

  const { loading } = useEditController(props);

  if (loading) {
    return <p>Loading ...</p>;
  }

  const validateFellowData = (values) => {
    const errors = {};
    const { data } = values;
    if (!data || !data.fellow) {
      return errors;
    }
    return errors;
  };

  const CustomToolbar = (props) => (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
    </Toolbar>
  );

  const NoticeBox = () => (
    <div style={{ backgroundColor: '#ff000017' }}>
      <p style={{ fontSize: '14px' }}>
        <span style={{ fontSize: '16px', fontWeight: 700 }}>{' ⚠️유저 상태 변경 주의 사항'}</span>
        <p>
          1. 활성 계정을 탈퇴시킬 때<br />
          {'계정 활성화(ON->OFF)로 변경&저장 해주세요.'}
        </p>
        <p>
          2. 휴면 계정을 탈퇴시킬 때<br />
          {'계정 활성화(OFF->ON)로 변경&저장 후 비활성화(ON->OFF)로 다시 변경&저장 해주세요.'}
        </p>
        <p>
          * 유저 상태를 변경할 수 있는 경우는 활성 유저를 탈퇴처리하거나, 휴면 계정을 탈퇴 처리 할 때 뿐입니다.
          <br />
          {'* 탈퇴 유저 복구 금지🚫'}
        </p>
      </p>
    </div>
  );

  return (
    <Edit title="User 정보 수정" actions={<PostEditActions />} {...props}>
      <SimpleForm submitOnEnter={false} validate={validateFellowData} toolbar={<CustomToolbar />}>
        <Box className={classes.mainContainer}>
          <Box className={classes.subContainer} pr="1em">
            <Typography variant="h6" className={classes.infoTitle} gutterBottom>
              기본 정보
            </Typography>
            <Box className={classes.miniContainer}>
              <TextInput label="이름" source="username" variant="outlined" />
              <TextInput label="닉네임" source="nickname" variant="outlined" />
              <DateTimeInput label="생년월일" source="birth_date" variant="outlined" />
              <TextInput label="phone_number" source="phone_number" variant="outlined" />
              <TextInput label="email" source="email" variant="outlined" />
              <TextInput label="소개" source="introduce" variant="outlined" />
              <TextInput label="주소" source="adress" variant="outlined" />
              <Box>
                <TextInput label="우편번호" source="postcode" variant="outlined" />
                <TextInput label="상세 주소" source="adress_detail" variant="outlined" />
              </Box>
              {/* admin@heyjoyce.com만 볼 수 있는 항목. @heyjoyce.com인 계정만 관리자로 등록할 수 있습니다. */}
              <FormDataConsumer>
                {({ formData }) => currentUser.username === 'admin@heyjoyce.com' && formData.email.includes('@heyjoyce.com') && <BooleanInput source="is_staff" label="관리자(플래너) 지정하기" />}
              </FormDataConsumer>
            </Box>
            <Box mt="1em" />
            <hr className={classes.divider} />
            <Box className={classes.miniContainer}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                직업 정보
              </Typography>
              <TextInput label="회사" source="company" variant="outlined" />
              <TextInput label="직업" source="job" variant="outlined" />
              <SelectInput label="업종" source="industry" choices={industryList} variant="outlined" />
              <SelectInput label="직무" source="position" choices={positionList} variant="outlined" />
            </Box>
            <hr className={classes.divider} />
            <Box className={classes.miniContainer}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                카테고리
              </Typography>
              {categories && <AutocompleteArrayInput source="category_ids" label="카테고리" choices={categories?.results} />}
            </Box>
            <hr className={classes.divider} />
            <Typography variant="h6" className={classes.infoTitle} gutterBottom>
              SNS 정보
            </Typography>
            <Box className={classes.miniContainer}>
              <TextInput label="Facebook" source="facebook" variant="outlined" />
              <TextInput label="Instagram" source="instagram" variant="outlined" />
              <TextInput label="Linkedin" source="linkedin" variant="outlined" />
            </Box>
          </Box>
          <Box className={classes.subContainer}>
            <Box className={classes.miniContainer}>
              <div className={classes.fellowHeader}>
                <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                  펠로우, 인스파이러 정보 (*펠로우, 인스파이러 전용 섹션)
                </Typography>
                <span>
                  {'📣 펠로우, 인스파이러 등록'} <br />
                  1. <b>필수 정보</b>를 꼭 입력해 주세요
                  <br /> 2. 하단 <b>유저 유형을 펠로우, 인스파이러로</b> 수정해주세요.
                </span>
              </div>
              <Box className={classes.miniContainer}>
                <ArrayInput source="data.fellow.careers" label="추가 이력 (필수)">
                  <SimpleFormIterator>
                    <TextInput className={classes.arrayInput} label="회사" source="company" />
                    <TextInput className={classes.arrayInput} label="직책" source="position" />
                  </SimpleFormIterator>
                </ArrayInput>
                <TextInput label="개인 사이트" source="data.fellow.website" variant="outlined" />
              </Box>
            </Box>
            <hr className={classes.divider} />
            <Typography variant="h6" className={classes.infoTitle} gutterBottom>
              계정 정보
            </Typography>
            <Box className={classes.miniContainer}>
              <div className={classes.stageDescription}>
                * 펠로우 유저 설정 시 펠로우 정보를{' '}
                <b>
                  <u>반드시</u>
                </b>{' '}
                입력해주세요!
              </div>
              <SelectInput
                source="stage"
                choices={[
                  { id: '0', name: '0 - 콘솔로 생성한 유저' },
                  { id: '10', name: '10 - 일반 유저' },
                  { id: '20', name: '20 - 펠로우 유저' },
                  { id: '30', name: '30 - 인스파이러 유저' },
                ]}
                className={classes.content}
                label="유저 유형"
                variant="outlined"
              />
            </Box>
            <div style={{ border: 'solid 2px red', padding: '4px' }}>
              <Box className={classes.miniContainer}>
                <NoticeBox />
                <BooleanInput source="yesno" label="📛 계정 활성 여부 수정하기" />
                <FormDataConsumer>
                  {({ formData }) =>
                    formData.yesno && (
                      <BooleanInput className={classes.indentedContainer} source="is_active" label="계정 활성화 여부 (ON: 활성 계정/ OFF: 비활성(탈퇴,휴면) 계정)" initialValue={true} />
                    )
                  }
                </FormDataConsumer>
              </Box>
            </div>
            <hr className={classes.divider} />
            <Typography variant="h6" className={classes.infoTitle} gutterBottom>
              메모
            </Typography>
            <Box className={classes.miniContainer}>
              <TextInput label="✏️" source="memo" variant="outlined" />
            </Box>
            <BooleanInput label="콘조이스 연사 여부" source="data.is_conjoyce_speaker" variant="outlined" />
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default EditUser;
