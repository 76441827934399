import React from 'react';
import { List, Datagrid, EditButton, BooleanInput, TextField, NumberField, DateField, DeleteWithConfirmButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
  },
  editButton: {
    fontWeight: 'bold',
  },
});

const StageField = ({ record }) => (record.opened ? <span>⭕️</span> : null);

const EditField = (props) => (
  <>
    <EditButton {...props} />
    <DeleteWithConfirmButton {...props} confirmContent="🚨 정말로 삭제하시려면 [✔확인]을 눌러주세요." confirmTitle={`"${props.record.subject}" 삭제`} />
  </>
);

const filters = [<BooleanInput label="홈페이지 노출" source="opened" alwaysOn />];

const ListNotice = (props) => {
  const classes = useStyles();

  return (
    <List {...props} title="공지사항 리스트" bulkActionButtons={false} filters={filters} exporter={false}>
      <Datagrid classes={{ headerCell: classes.headerCell }} rowClick="show">
        <NumberField lable="ID" source="id" sortable={false} />
        <TextField label="제목" source="subject" sortable={false} />
        <TextField label="작성자" source="writer" sortable={false} />
        <DateField label="생성 일시" source="created_at" sortable={false} showTime={true} />
        <StageField label="홈페이지 노출" source="opened" sortable={false} />
        <EditField />
      </Datagrid>
    </List>
  );
};

export default ListNotice;
