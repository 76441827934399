import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import { client } from 'graphql/providers';
import { gql } from '@apollo/client';
import { listPromotions } from 'graphql/queries';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import CopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  createButton: {
    textDecoration: 'none',
  },
  editButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    padding: '5px',
    backgroundColor: 'transparent',
    color: '#ff5946',
    whiteSpace: 'pre',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  tableContainer: {
    backgroundColor: 'white',
    '& thead th': {
      backgroundColor: '#3a3a3a',
      color: 'white',
      fontWeight: 'bold',
    },
    '& tbody tr:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  spacePre: {
    whiteSpace: 'pre',
  },
  filterInput: {
    border: 'none',
    borderBottom: '1px solid gray',
    fontSize: '18px',
    padding: '10px',
    width: '350px',
    '&:focus': {
      outline: 'none',
    },
  },
});

const ListPromotion = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [promotions, setPromotions] = useState([]);
  const [filter, setFilter] = useState('');

  if (location.state && location.state.update) {
    window.location.reload();
  }

  function isOpened(start, end) {
    const now = new Date();
    if (now > new Date(start) && now < new Date(end)) {
      return '오픈';
    } else if (now > new Date(end)) {
      return '종료';
    } else {
      return '';
    }
  }

  useEffect(() => {
    async function fetchData() {
      const { data } = await client.query({
        query: gql`
          ${listPromotions}
        `,
      });

      if (data) {
        const now = new Date();
        setPromotions(
          data.listPromotions.items.map((items) => ({
            ...items,
            start_at: new Date(items.start_at).toLocaleString(),
            end_at: new Date(items.end_at).toLocaleString(),
            created_at: new Date(items.created_at),
            is_opened: isOpened(items.start_at, items.end_at), //now > new Date(items.start_at) && now < new Date(items.end_at) ? '✔️' : '',
          }))
        );
      }
    }

    fetchData();
  }, []);

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <div>
      <div className={classes.headerContainer}>
        <h2>프로모션 리스트</h2>
        <Link to="/promotions/create" style={{ textDecoration: 'none' }}>
          <Button variant="outlined" color="primary" className={classes.createButton}>
            프로모션 생성하기
          </Button>
        </Link>
      </div>
      <div>
        <input className={classes.filterInput} type="text" value={filter} placeholder="🔎 프로모션 제목으로 검색" onChange={handleChange} />
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="프로모션 리스트">
          <TableHead>
            <TableRow>
              {/* <TableCell>Id</TableCell> */}
              <TableCell align="center">프로모션 코드</TableCell>
              <TableCell align="center">단계</TableCell>
              <TableCell align="center">제목</TableCell>
              <TableCell align="center">프로모션 시작일시</TableCell>
              <TableCell align="center">프로모션 종료일시</TableCell>
              <TableCell align="center">생성일시</TableCell>
              <TableCell align="center">오픈</TableCell>
              <TableCell align="center">액션</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {promotions
              .filter((item) => item.title.includes(filter) || item.code.includes(filter))
              .map((row) => (
                <TableRow key={row.id} style={{ backgroundColor: row.is_opened === '오픈' ? '#FDDB00' : '#FFFFFF' }}>
                  <TableCell component="th" align="center">
                    {row.code}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {row.stage}
                  </TableCell>
                  <TableCell align="center">{row.title}</TableCell>
                  <TableCell align="center">{row.start_at.toLocaleString()}</TableCell>
                  <TableCell align="center">{row.end_at.toLocaleString()}</TableCell>
                  <TableCell align="center">{row.created_at.toLocaleString()}</TableCell>
                  <TableCell align="center" className={classes.spacePre}>
                    {row.is_opened}
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={{
                        pathname: '/promotions/create',
                        state: { copyId: row.id },
                      }}
                    >
                      <button className={classes.editButton}>
                        <CopyIcon />
                        복사
                      </button>
                    </Link>
                    <Link to={`/promotions/${row.id}/edit`} style={{ textDecoration: 'none' }}>
                      <button className={classes.editButton}>
                        <CreateIcon />
                        수정
                      </button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default ListPromotion;
