import React, { cloneElement, useState } from 'react';
import {
  useShowController,
  Filter,
  List,
  AppBar,
  SimpleShowLayout,
  TabbedShowLayout,
  Tab,
  EditButton,
  TopToolbar,
  TextField,
  ImageField,
  RichTextField,
  BooleanField,
  DateField,
  NumberField,
  ArrayField,
  Datagrid,
  SelectInput,
  useDataProvider,
  useListContext,
  useNotify,
  SingleFieldList,
  useRefresh,
} from 'react-admin';
import { Link, useHistory } from 'react-router-dom';
import useAdminApi from 'hooks/useAdminApi';
import jsonExport from 'jsonexport/dist';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { makeStyles } from '@material-ui/core/styles';
import { payStatusDict, payMethodDict, numberWithCommas } from 'helpers';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { format, parse } from 'date-fns';
import { adminAxios } from 'helpers/axios';
import AttendeeBulkCreateModal from 'components/Modal/AttendeeBulkCreateModal';
import { useModalContext } from 'hooks/useModalContext';
import ProgramParticipationBulkCreateModal from 'components/Modal/ProgramParticipationBulkCreateModal';

const useStyles = makeStyles({
  button: { color: 'orange', padding: '1px' },
  link: { color: 'orange', padding: '2px' },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'bold',
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    backgroundColor: 'white',
  },
  section: {
    backgroundColor: 'white',
    width: '100%',
    boxSizing: 'border-box',
    '& img': {
      maxWidth: '100%',
    },
  },
  basicInfoRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  thumbnail: {
    maxWidth: '200px',
  },
  profileImage: {
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    overflow: 'hidden',
    '& > img': {
      margin: 0,
    },
  },
  inlineSection: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '50%',
    backgroundColor: 'white',
    '& > .MuiCardContent-root': {
      width: '100%',
    },
  },
  descriptionSection: {
    width: '100%',
    boxSizing: 'border-box',
    '& img': {
      maxWidth: '60%',
    },
    '& > .MuiCardContent-root': {
      width: '100%',
    },
  },
  participationTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    marginTop: '25px',
    paddingBottom: '8px',
  },
  summaryTable: {
    minWidth: '700px',
    backgroundColor: 'white',
    borderBottom: '1px solid #e0e0e0',
    textAlign: 'center',
    fontSize: '14px',
    borderSpacing: 'inherit',
    '& th': {
      color: 'white',
      fontWeight: 'bold',
      backgroundColor: '#3a3a3a',
    },
    '& td, th': {
      padding: '6px 24px 6px 16px',
    },
  },
  infoTitle: {
    '& > h6': {
      fontWeight: 'bold',
      fontSize: '1.2em',
      marginTop: '15px',
      borderBottom: '1px solid #cacaca',
      paddingBottom: '8px',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
  },
  editButton: {
    fontWeight: 'bold',
  },
  layout: {
    width: '50ch',
  },
  filterLayout: {
    width: '30ch',
  },
  topToolBar: {
    padding: '0',
  },
  gridColumn: {
    display: 'flex',
    flexDirection: 'column',
    color: 'gray',
    '& > span:first-child': {
      color: 'black',
    },
  },
  statusChip: {
    padding: '2px 6px',
    borderRadius: '4px',
    width: 'max-content',
    color: 'white',
  },
  nameLink: {
    textDecoration: 'none',
    color: 'rgb(11, 134, 122)',
    whiteSpace: 'nowrap',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ff5946',
    '& svg': {
      width: '0.8em',
      marginRight: '5px',
    },
  },
  checkboxInputRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialog: {
    '& p': {
      marginTop: 0,
      color: '#4f4f4f',
    },
    '& button': {
      borderRadius: '4px',
      fontWeight: 'bold',
      fontSize: '1em',
      margin: '0px 4px',
      '&:hover': {
        cursor: 'pointer',
        filter: 'brightness(90%)',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    '& h2': {
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  dialogTitle: {
    fontWeight: 'bold',
  },
  attendanceButton: {
    backgroundColor: '#3a3a3a',
    color: 'white',
    border: 'none',
    paddingTop: '4px',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '7px',
  },
  attendanceCancelButton: {
    color: '#3a3a3a',
    backgroundColor: 'white',
    border: '1px solid #3a3a3a',
    paddingTop: '4px',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '7px',
  },
  closeButton: {
    backgroundColor: '#949494',
    color: 'white',
    border: 'none',
    padding: '10px 15px',
  },
  bulkUpdateButton: {
    border: 'none',
    borderRadius: '4px',
    color: 'white',
    backgroundColor: '#fe5946',
    padding: '8px 12px',
    fontWeight: 'bold',
    fontSize: '1em',
    '&:hover': {
      cursor: 'pointer',
      filter: 'brightness(90%)',
    },
  },
});

const ProgramEditAction = ({ data, record }) => {
  const classes = useStyles();

  return (
    <TopToolbar className={classes.topToolBar}>
      <EditButton basePath="/programs" label="수정" record={record} />
    </TopToolbar>
  );
};

const ParticipationContact = ({ record }) => {
  const classes = useStyles();
  const { user } = record;

  return (
    <div className={classes.gridColumn}>
      <span>{user.email}</span>
      <span>{user.phone_number}</span>
    </div>
  );
};

const ParticipationJob = ({ record }) => {
  const classes = useStyles();
  const { user } = record;

  return (
    <div className={classes.gridColumn}>
      <span>{user.company}</span>
      <span>{user.job}</span>
      <span>
        {user.industry} - {user.position}
      </span>
    </div>
  );
};

const ParticipationOrderStatus = ({ record }) => {
  const classes = useStyles();
  const { order } = record;
  const { name, color } = payStatusDict[order.status];

  return (
    <div className={classes.gridColumn}>
      <span className={classes.statusChip} style={{ backgroundColor: color, color: 'white' }}>
        {name}
      </span>
    </div>
  );
};

const ParticipationOrderInfo = ({ record }) => {
  const classes = useStyles();
  const { order, used_coupon } = record;

  return (
    <div className={classes.gridColumn}>
      <span>
        {payMethodDict[order.method].name} {used_coupon && `(${payMethodDict.coupon.name})`}
      </span>
      <span>{used_coupon && used_coupon.coupon_name}</span>
      <span style={{ color: 'black', fontWeight: 'bold' }}>총 {numberWithCommas(order.price)}원 결제</span>
    </div>
  );
};

const ParticipationFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="결제 상태 필터"
      source="order.status"
      choices={[
        { id: 'paid', name: '결제 완료' },
        { id: 'cancelled', name: '결제 취소' },
        { id: 'ready', name: '결제 대기' },
      ]}
      alwaysOn
      emptyText="전체"
    />
  </Filter>
);

const ParticipationNameLink = ({ record }) => {
  const classes = useStyles();

  return (
    <a className={classes.nameLink} href={`#/users/${record ? record.user.id : ''}/show`}>
      {record.user.username}
    </a>
  );
};

const ParticipationAttendanceInput = ({ record, meets }) => {
  const classes = useStyles();
  const notify = useNotify();
  const { id: participationId, user } = record;

  const handleClickAttendance = async (e) => {
    const meetId = e.target.name;
    const isChecked = e.target.checked;
    const currentTime = new Date();

    const data = {
      created_at: currentTime,
      attendance: isChecked,
      programMeetId: meetId,
      participationId,
    };

    try {
      await adminAxios({
        method: 'POST',
        uri: '/attendances/',
        data,
        query: '',
      });
    } catch (error) {
      notify('참석 정보 업데이트에 실패했습니다', 'warning');
      return;
    }

    notify(`참석 정보 #${participationId}이 업데이트 되었습니다`);
  };

  return (
    <>
      {meets.map((meet, index) => {
        const { id: meetId, start_at } = meet;
        const formattedDate = format(parse(start_at), 'MM/DD');
        const isAttend = Object.prototype.hasOwnProperty.call(user, 'attendances') && user.attendances.includes(meetId);

        return (
          <div className={classes.checkboxInputRow}>
            <input type="checkbox" name={meetId} onClick={handleClickAttendance} defaultChecked={isAttend} />
            <label for={meetId}>{formattedDate}</label>
          </div>
        );
      })}
    </>
  );
};

const ParticipationActions = (props) => {
  const { record } = props;

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { filterValues, currentSort, exporter: exporterFromContext, total } = useListContext(props);

  const refresh = useRefresh();
  const [, dispatch, hide] = useModalContext();

  const handleClickExport = async (e) => {
    e.preventDefault();

    const { data: participationsForExports } = await dataProvider.getList('participations', {
      sort: currentSort,
      filter: { programId: record.id, ...filterValues },
      pagination: { page: 1, perPage: 500 },
    });

    jsonExport(
      participationsForExports.map((row) => ({
        신청ID: row.id,
        이름: row.user.username,
        유저ID: row.user.id,
        휴대폰번호: row.user.phone_number,
        이메일: row.user.email,
        회사: row.user.company,
        하는일: row.user.job,
        결제상태: row.order.status,
        결제금액: row.order.price,
        결제수단: row.order.method,
        출석: row.user.attendances,
        ...(row.used_coupon && { 사용한쿠폰타입: row.used_coupon.type }),
      })),
      {
        headers: ['신청ID', '유저ID', '이름', '휴대폰번호', '이메일', '회사', '하는일', '사용한쿠폰타입', '결제상태', '결제금액', '결제수단', '출석'],
      },
      (err, csv) => {
        let blob;

        var userAgent = window.navigator.userAgent;
        var isWindows = userAgent.indexOf('Windows NT');

        const fakeLink = document.createElement('a');
        fakeLink.style.display = 'none';
        document.body.appendChild(fakeLink);

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // Manage IE10+ & Edge
          blob = new Blob(['\uFEFF' + csv], { type: 'text/csv;charset=utf-8' });
          window.navigator.msSaveOrOpenBlob(blob, `${`프로그램_${record.id}_신청자리스트`}.csv`);
          return;
        } else if (isWindows) {
          blob = new Blob(['\uFEFF' + csv], { type: 'text/csv;charset=utf-8' });
        } else {
          blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        }

        fakeLink.setAttribute('href', URL.createObjectURL(blob));
        fakeLink.setAttribute('download', `${`프로그램_${record.id}_신청자리스트`}.csv`);
        fakeLink.click();
      }
    );
  };

  return (
    <TopToolbar className={classes.topToolBar}>
      <Button
        className={classes.actionButton}
        component={Link}
        to={{
          pathname: '/participations/create',
          search: `?programId=${record.id}`,
        }}
      >
        <AddIcon />
        신청자 등록하기
      </Button>
      <Button
        className={classes.actionButton}
        onClick={() =>
          dispatch({
            key: 'programParticipationBulk',
            data: {
              modalComponent: <ProgramParticipationBulkCreateModal programId={record.id} refresh={refresh} />,
            },
          })
        }
      >
        <GroupAddIcon />
        신청자 일괄등록하기 (csv)
      </Button>
      <Button className={classes.actionButton} onClick={handleClickExport}>
        <SaveAltIcon />
        내보내기
      </Button>
    </TopToolbar>
  );
};

const ParticpationBulkActionButtons = (props) => {
  const classes = useStyles();
  const { handleClick, selectedIds } = props;

  return (
    <div>
      <button className={classes.bulkUpdateButton} onClick={() => handleClick(selectedIds)}>
        출석 정보 일괄 수정
      </button>
    </div>
  );
};

const ParticipationList = (props) => {
  const classes = useStyles();
  const notify = useNotify();

  const { record } = props;

  const [isShowDialog, setIsShowDialog] = useState(false);
  const [currentSelectedIds, setCurrentSelectedIds] = useState([]);

  const { loading, error, data } = useAdminApi({
    method: 'GET',
    url: `/programs/${record.id}/summary`,
  });

  const handleClickBulkUpdate = (selectedIds) => {
    setCurrentSelectedIds(selectedIds);
    setIsShowDialog(!isShowDialog);
  };

  const handleClickUpdateAttendance = async (isAttendance, meetId) => {
    const currentTime = new Date();

    const requestData = {
      created_at: currentTime,
      attendance: isAttendance,
      programMeetId: meetId,
      participationId: '',
    };

    try {
      for await (let participationId of currentSelectedIds) {
        requestData.participationId = participationId;
        await adminAxios({
          method: 'POST',
          uri: '/attendances/',
          data: requestData,
          query: '',
        });
      }
    } catch (error) {
      setIsShowDialog(false);
      notify('참석 정보 업데이트에 실패했습니다', 'warning');
      return;
    }

    setIsShowDialog(false);
    notify(`참석 정보 ${currentSelectedIds.length}건이 업데이트 되었습니다. 새로고침 후 확인해주세요`);
  };

  return (
    <>
      <Typography variant="h6" className={classes.participationTitle}>
        {'📃 SUMMARY'}
      </Typography>
      {loading ? (
        <>Loading...</>
      ) : (
        data && (
          <table border="0" className={classes.summaryTable}>
            <thead>
              <tr>
                <th>가격</th>
                <th>정원</th>
                <th>신청자 수</th>
                <th>결제 완료 수</th>
                <th>결제 취소 수</th>
                <th>수익(실결제 금액 기준)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{`${numberWithCommas(data.associate_member_price)}원 ${data.member_price > 0 ? `(정가: ${numberWithCommas(data.member_price)}원)` : ''}`}</td>
                <td>{data.associate_member_limit == 999999 ? '무제한' : data.associate_member_limit}</td>
                <td>{data.member_count + data.associate_member_count}명</td>
                <td>{data.associate_member_paid + data.member_paid}명</td>
                <td>{data.associate_member_cancelled + data.member_cancelled}명</td>
                <td>{numberWithCommas(data.associate_member_income + data.member_income)}원</td>
              </tr>
            </tbody>
          </table>
        )
      )}
      <Typography variant="h6" className={classes.participationTitle} gutterBottom>
        {'👥 신청자 리스트'}
      </Typography>
      <Dialog className={classes.dialog} fullWidth open={isShowDialog} onClose={() => setIsShowDialog(false)} aria-label="출석 정보 일괄 수정">
        <DialogTitle>출석 정보 일괄 수정</DialogTitle>
        <DialogContent>
          <p>
            선택한 참석 정보 {currentSelectedIds.length}건의 출석 정보를 일괄 업데이트합니다. <br />
            <b>버튼 클릭 시 바로 반영됩니다.</b>
          </p>
          <div>
            {record.meets.map((meet, index) => {
              const { id: meetId, start_at } = meet;
              const formattedDate = format(parse(start_at), 'YYYY/MM/DD');
              return (
                <div style={{ marginBottom: 10 }} key={meetId}>
                  {'🗓'} <b>{formattedDate}</b>의 출석 정보{' '}
                  <button onClick={() => handleClickUpdateAttendance(true, meetId)} className={classes.attendanceButton}>
                    {'⭕️ 일괄 출석'}
                  </button>{' '}
                  <button onClick={() => handleClickUpdateAttendance(false, meetId)} className={classes.attendanceCancelButton}>
                    {'❌ 일괄 출석 해제'}
                  </button>
                </div>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <button className={classes.closeButton} onClick={() => setIsShowDialog(false)}>
            닫기
          </button>
        </DialogActions>
      </Dialog>
      <List
        {...props}
        resource="participations"
        empty={false}
        perPage={500}
        pagination={false}
        filter={{ programId: record.id }}
        filters={<ParticipationFilter />}
        actions={<ParticipationActions {...props} />}
        bulkActionButtons={<ParticpationBulkActionButtons handleClick={handleClickBulkUpdate} />}
      >
        <Datagrid classes={{ headerCell: classes.headerCell }}>
          <TextField label="신청ID" source="id" />
          <DateField label="등록 날짜" source="created_at" showTime />
          <ParticipationAttendanceInput label="출석 여부" meets={record.meets} />
          <TextField label="유저ID" source="user.id" />
          <ParticipationNameLink label="이름" source="user.username" />
          <ParticipationContact label="이메일/휴대폰번호" />
          <ParticipationJob label="회사명/하는일/업종-직무" />
          <ParticipationOrderStatus label="결제상태" />
          <ParticipationOrderInfo label="결제방법/사용 쿠폰/실결제 금액" />
        </Datagrid>
      </List>
    </>
  );
};

const ShowProgram = (props) => {
  const classes = useStyles();
  const {
    basePath, // deduced from the location, useful for action buttons
    loading, // return true if loading to fetch data
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version, // integer used by the refresh feature
  } = useShowController(props);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <AppBar {...props}>
        <Typography variant="h6" color="inherit" className={classes.title}>
          프로그램 상세 정보
        </Typography>
      </AppBar>
      <div className={classes.header}>
        <Button
          component={Link}
          to={{
            pathname: '/programs',
          }}
        >
          <ArrowBackIcon />
        </Button>
        <h3>{`프로그램 #${record.id} ${record.name}`}</h3>
      </div>
      {cloneElement(props.children, {
        basePath,
        record,
        resource,
        version,
      })}
    </div>
  );
};

const DetailProgram = (props) => {
  const classes = useStyles();

  return (
    <ShowProgram {...props}>
      <TabbedShowLayout>
        <Tab label="프로그램 상세">
          <ProgramEditAction />
          <SimpleShowLayout className={classes.section}>
            <SimpleShowLayout>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'ℹ️ 기본 정보'}
              </Typography>
              <SimpleShowLayout className={classes.basicInfoRow}>
                <ArrayField source="thumbnails" label="썸네일">
                  <SingleFieldList>
                    <ImageField className={classes.thumbnail} source="thumbnail" label={''} />
                  </SingleFieldList>
                </ArrayField>
                <SimpleShowLayout>
                  <TextField source="name" label="프로그램 이름" />
                  <TextField source="summary" label="프로그램 요약" />
                </SimpleShowLayout>
              </SimpleShowLayout>
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.inlineSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'🗓 미팅 일정'}
              </Typography>
              <ArrayField source="meets" label={''}>
                <Datagrid>
                  <TextField source="id" label="일정 ID" />
                  <DateField source="start_at" label="미팅 시작 일시" showTime />
                  <DateField source="end_at" label="미팅 종료 일시" showTime />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.inlineSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'🙋‍♀️ 리더 & 서포터'}
              </Typography>
              <ArrayField source="leaderObjects" label="리더">
                <Datagrid>
                  <TextField source="id" label="ID" />
                  <ImageField className={classes.profileImage} source="profile_image" label="프로필사진" />
                  <TextField source="username" label="이름" />
                  <TextField source="email" label="이메일" />
                  <TextField source="phone_number" label="핸드폰번호" />
                </Datagrid>
              </ArrayField>
              <ArrayField source="supporterObject" label="서포터">
                <Datagrid>
                  <TextField source="id" label="ID" />
                  <ImageField className={classes.profileImage} source="profile_image" label="프로필사진" />
                  <TextField source="username" label="이름" />
                  <TextField source="email" label="이메일" />
                  <TextField source="phone_number" label="핸드폰번호" />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.inlineSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'⚙️ 프로그램 속성'}
              </Typography>
              <TextField source="type" label="타입" />
              <TextField source="tags" label="태그" />
              <TextField source="category_ids" label="카테고리" />
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.inlineSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'🛠 상태 설정'}
              </Typography>
              <BooleanField source="test" label="테스트 모드 설정(✔️: @heyjoyce.com 계정에게만 노출)" />
              <BooleanField source="register_available" label="신청 가능 설정 (✔️: 프로그램 신청 버튼 활성화 & 신청 가능, X: 신청 불가)" />
              <BooleanField source="opened" label="프로그램 활성화 설정 (✔️: 프로그램 노출, X: 노출하지 않음)" />
              <BooleanField source="alimtalk" label="알림톡 설정 (✔️: 알림톡 발송, X: 알림톡 발송하지 않음)" />
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.inlineSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'🌝 신청 가능 기간'}
              </Typography>
              <DateField source="register_start_at" label="신청 시작 일시" showTime />
              <DateField source="register_end_at" label="신청 마감 일시" showTime />
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.inlineSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'👥 인원 & 💵 가격'}
              </Typography>
              <NumberField source="associate_member_limit" label="예비 멤버 정원" />
              <NumberField source="member_limit" label="멤버 정원" />
              <NumberField source="associate_member_price" label="판매가" />
              <NumberField source="member_price" label="정가" />
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.inlineSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'📹 티처블 정보'}
              </Typography>
              <TextField source="data.teachable.course_id" label={'티쳐블 코스 ID'} />
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.inlineSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'📹 다시보기 정보'}
              </Typography>
              <DateField source="data.replay.start_at" label="다시보기 시작 일시" />
              <DateField source="data.replay.end_at" label="다시보기 종료 일시" />
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.inlineSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'🎣 마케팅 영역 정보'}
              </Typography>
              <TextField source="data.marketing.title" label={'마케팅 영역 타이틀'} />
              <TextField source="data.marketing.subtitle" label={'마케팅 영역 부제'} />
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.inlineSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'🎣 할부 개월 표시 정보'}
              </Typography>
              <TextField source="data.marketing.installment" label={'할부 개월 수'} />
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.descriptionSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'📜 소개'}
              </Typography>
              <RichTextField source="description" label={''} />
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.descriptionSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'👩‍💼 연사소개'}
              </Typography>
              <RichTextField source="data.detail.leader_info" label={''} />
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.descriptionSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'📌 공지사항'}
              </Typography>
              <RichTextField source="data.detail.notice" label={''} />
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.descriptionSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'🎈 후기'}
              </Typography>
              <RichTextField source="data.detail.review" label={''} />
            </SimpleShowLayout>
            <SimpleShowLayout className={classes.descriptionSection}>
              <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                {'🏷 할인'}
              </Typography>
              <RichTextField source="data.detail.sale" label={''} />
            </SimpleShowLayout>
          </SimpleShowLayout>
        </Tab>
        <Tab label="프로그램 신청 현황" path="participationList">
          <ParticipationList />
        </Tab>
      </TabbedShowLayout>
    </ShowProgram>
  );
};

export default DetailProgram;
