import React, { cloneElement } from 'react';
import { Link } from 'react-router-dom';
import {
  useShowController,
  useNotify,
  Filter,
  ExportButton,
  ChipField,
  List,
  SelectInput,
  Pagination,
  AppBar,
  ImageField,
  Datagrid,
  DateField,
  NumberField,
  SimpleShowLayout,
  TextField,
  TabbedShowLayout,
  Tab,
  TextInput,
  UrlField,
  BooleanField,
  EditButton,
  TopToolbar,
  Button,
} from 'react-admin';
import { AddBox as AddBoxIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { payMethodDict, payStatusDict } from 'helpers';
import { EditUser } from '../User';
import ResetPasswordButton from './components/ResetPasswordButton';

const useStyles = makeStyles({
  button: { color: 'orange', padding: '1px' },
  link: { color: 'orange', padding: '2px' },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'bold',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  rowGroup: {
    backgroundColor: 'white',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  columnGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0',
    paddingBottom: '0',
  },
  infoTitle: {
    '& > h6': {
      fontWeight: 'bold',
      marginTop: '15px',
    },
  },
  rowInColumnGroup: {
    width: 'auto',
    display: 'flex',
    width: '100%',
    padding: '0',
  },
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
  },
  editButton: {
    fontWeight: 'bold',
  },
  layout: {
    width: '50ch',
  },
  filterLayout: {
    width: '30ch',
  },
  topToolBar: {
    padding: '0',
  },
  tagsTag: {
    color: 'black',
    backgroundColor: '#7c7c7c7c',
    padding: '2px 6px',
    marginRight: '4px',
    borderRadius: '15px',
    fontSize: '13px',
    wordBreak: 'keep-all',
  },
  invalidityTag: {
    color: 'black',
    backgroundColor: '#FFE1E3',
    padding: '2px 6px',
    borderRadius: '15px',
    fontSize: '13px',
    wordBreak: 'keep-all',
  },
  validityTag: {
    color: 'black',
    backgroundColor: '#8CE7DA',
    padding: '2px 6px',
    borderRadius: '15px',
    fontSize: '13px',
    wordBreak: 'keep-all',
  },
  wrapChip: {
    margin: '0px',
    marginLeft: '4px',
    marginBottom: '15px',
    display: 'flex',
    padding: '0',
  },
  chip: {
    display: 'inline-block',
    background: '#E0E0E0',
    borderRadius: '32px',

    fontSize: '13px',
    marginRight: '5px',
  },
  chipText: {
    marginTop: '8px',
    marginBottom: '8px',
    marginLeft: '12px',
    marginRight: '12px',
  },
  gridColumn: {
    display: 'flex',
    flexDirection: 'column',
    color: 'gray',
    '& > span:first-child': {
      color: 'black',
    },
  },
  statusChip: {
    padding: '2px 6px',
    borderRadius: '4px',
    width: 'max-content',
    color: 'white',
  },
  fellowRow: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      fontSize: '14px',
    },
  },
  fellowKey: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '17px 0px 8px',
    fontSize: '12px',
  },
  hashtag: {
    marginRight: 10,
    fontSize: '14px',
  },
  programName: {
    color: '#1cad8c',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const UserInfoEditActions = ({ record }) => {
  const classes = useStyles();
  const notify = useNotify();

  const handleChangePassword = () => notify('패스워드가 업데이트 되었습니다');

  return (
    <TopToolbar className={classes.topToolBar}>
      <ResetPasswordButton userId={record.id} onChange={handleChangePassword} />
      <EditButton basePath={EditUser} record={record} label="프로필 수정" />
    </TopToolbar>
  );
};

const CreateCouponButton = ({ userId }) => (
  <Button
    component={Link}
    to={{
      pathname: '/coupons/create',
      search: `?user_id=${userId}`,
    }}
    label="생성하기"
  >
    <AddBoxIcon />
  </Button>
);

const UserCouponActions = (props) => {
  const classes = useStyles();
  const { exporter, record, id } = props;
  return (
    <TopToolbar className={classes.topToolBar}>
      <CreateCouponButton userId={id} />
      <ExportButton exporter={exporter} />
    </TopToolbar>
  );
};

const ShowUser = (props) => {
  const classes = useStyles();

  const {
    basePath, // deduced from the location, useful for action buttons
    loading, // return true if loading to fetch data
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version, // integer used by the refresh feature
  } = useShowController(props);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <AppBar {...props}>
        <Typography variant="h6" color="inherit" className={classes.title}>
          유저 상세 정보
        </Typography>
      </AppBar>
      <h3>{`유저 #${record.id} ${record.username} (${record.email})`}</h3>
      {cloneElement(props.children, {
        basePath,
        record,
        resource,
        version,
      })}
    </div>
  );
};

const UserPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const ProgramListFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <TextInput label="🔎 프로그램 ID" source="program_id" alwaysOn className={classes.filterLayout} />
    </Filter>
  );
};

const CouponListFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <TextInput label="🔎 쿠폰 이름" source="coupon_name" alwaysOn className={classes.filterLayout} options={{ variant: 'outlined' }} />
    </Filter>
  );
};

const UserEventListFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SelectInput
        className={classes.filterLayout}
        label="🔎 카테고리"
        source="category"
        alwaysOn
        choices={[
          { id: 'COUPON', name: '쿠폰' },
          { id: 'PROFILE', name: '프로필' },
          { id: 'ORDER', name: '주문' },
          { id: 'WEBINAR', name: '웨비나' },
        ]}
      />
      <SelectInput
        className={classes.filterLayout}
        label="🔎 액션"
        source="action"
        alwaysOn
        choices={[
          { id: 'CREATED', name: '생성' },
          { id: 'UPDATED', name: '업데이트' },
          { id: 'REMOVED', name: '삭제' },
          { id: 'NOTIFIED', name: '알림' },
          { id: 'JOINED', name: '가입' },
          { id: 'INVITED', name: '초대' },
          { id: 'ATTEND', name: '출석' },
          { id: 'USED', name: '사용' },
          { id: 'PARTICIPATION', name: '참가' },
          { id: 'GENERATED_ATTENDEES', name: '참가자리스트생성' },
          { id: 'NOTIFIED_TODAY', name: '사전 알림 (오전)' },
          { id: 'NOTIFIED_HOUR_AGO', name: '사전 알림 (한시간전)' },
          { id: 'TERMINATED', name: '종료' },
        ]}
      />
      {/* <TextInput label="액션" source="action" alwaysOn className={classes.filterLayout} /> */}
    </Filter>
  );
};

const ProgramOrderPanel = ({ id, record }) => (
  <div>
    {Object.keys(record.order).map((i) => {
      if (record.order[i]) {
        return (
          <div key={i}>
            <b>{i}:</b> {record.order[i]}
          </div>
        );
      }
      return <></>;
    })}
  </div>
);

const TagsField = ({ record }) => {
  const classes = useStyles();

  return (
    <ul className={classes.wrapChip}>
      {record?.category_names &&
        record.category_names.map((item, index) => (
          <div className={classes.chip} key={index}>
            <div className={classes.chipText}>{item}</div>
          </div>
        ))}
    </ul>
  );
};

const CouponExpiredField = ({ record, source }) => {
  const classes = useStyles();
  const now = new Date().toISOString();
  return (
    <div>
      {record['participation'] ? (
        <span className={classes.invalidityTag}>사용</span>
      ) : !record[source] || record[source] < now ? (
        <span className={classes.invalidityTag}>만료</span>
      ) : (
        <span className={classes.validityTag}>유효</span>
      )}
    </div>
  );
};

const CouponTagsField = ({ record, source }) => {
  const classes = useStyles();
  const tags = {
    digitalcontents: 'VOD',
    conjoyce: '콘조이스',
    heyjoyceParty: '헤이조이스파티',
  };

  return <div>{record['tags'] && record['tags'].map((item) => <span className={classes.tagsTag}>{tags[item]}</span>)}</div>;
};

const MoreInfoField = ({ record }) => {
  const classes = useStyles();

  const info = record.data?.fellow ? record.data?.fellow : record.data?.user_info;
  const isConjoyceSpeaker = record.data?.is_conjoyce_speaker || '';

  return (
    <>
      <div className={classes.fellowRow}>
        <span className={classes.fellowKey}>커리어 패스 (회사 / 직책)</span>
        <span style={{ whiteSpace: 'break-spaces' }}>
          {info?.careers &&
            info.careers.map((career, index) =>
              career ? (
                <div key={index}>
                  -{' '}
                  <span>
                    {Object.prototype.hasOwnProperty.call(career, 'company') && career.company} / {Object.prototype.hasOwnProperty.call(career, 'position') && career.position}
                  </span>
                </div>
              ) : (
                <></>
              )
            )}
        </span>
      </div>
      <div className={classes.fellowRow}>
        <span className={classes.fellowKey}>개인 사이트</span>
        <span>{info?.website}</span>
      </div>
      <div className={classes.fellowRow}>
        <span className={classes.fellowKey}>콘조이스 연사 여부</span>
        <span>{isConjoyceSpeaker.toString()}</span>
      </div>
    </>
  );
};

const B2BField = ({ record }) => {
  const classes = useStyles();

  const b2bInfo = record.data?.b2b;
  return (
    <>
      <div className={classes.fellowRow}>
        <span className={classes.fellowKey}>회사 이름</span>
        <span style={{ whiteSpace: 'break-spaces' }}>{b2bInfo?.name}</span>
        <span className={classes.fellowKey}>회사 코드</span>
        <span style={{ whiteSpace: 'break-spaces' }}>{b2bInfo?.code}</span>
      </div>
    </>
  );
};
const ProgramLinkField = ({ record, source }) => {
  const classes = useStyles();

  if (!record[source]) {
    return <span />;
  }

  const { id, name, type } = record[source];
  const progarmUrl = `${process.env.REACT_APP_ENV === 'production' ? 'https://heyjoyce.com/program/' : 'http://dev.heyjoyce.com/program/'}${type}/${id}`;
  return (
    <span className={classes.programName} onClick={() => window.open(progarmUrl, '_blank')}>
      {name}
    </span>
  );
};

const PaymentMethodField = ({ record }) => {
  if (!record.order) {
    return <div />;
  }
  const value = record.order.method;
  return (
    <div>
      <span>{payMethodDict[value].name}</span>
    </div>
  );
};

const OrderStatusField = ({ record }) => {
  const classes = useStyles();

  if (!record.order) {
    return <div />;
  }

  const { name, color } = payStatusDict[record.order.status];

  return (
    <div className={classes.gridColumn}>
      <span className={classes.statusChip} style={{ backgroundColor: color, color: 'white' }}>
        {name}
      </span>
    </div>
  );
};

const DetailUser = (props) => {
  const classes = useStyles();

  return (
    <ShowUser {...props}>
      <TabbedShowLayout>
        <Tab label="프로필">
          <UserInfoEditActions />
          <SimpleShowLayout className={classes.group}>
            <SimpleShowLayout className={classes.rowGroup}>
              <SimpleShowLayout className={classes.columnGroup}>
                <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                  기본 정보
                </Typography>
                <ImageField label="프로필" source="profile_image" />
                <TextField label="ID" source="id" />
                <TextField label="이름" source="username" />
                <TextField label="닉네임" source="nickname" />
                <TextField label="소개" source="introduce" />
                <TextField label="email" source="email" />
                <TextField label="phone_number" source="phone_number" />
                <DateField label="생년월일" source="birth_date" />
                <TextField label="주소" source="adress" />
                <SimpleShowLayout className={classes.rowInColumnGroup}>
                  <TextField label="우편번호" source="postcode" />
                  <TextField label="상세 주소" source="adress_detail" />
                </SimpleShowLayout>
                <DateField label="마지막 활동 날짜" source="last_login" showTime />
                <DateField label="가입 날짜" source="date_joined" showTime />
                <TextField label="가입 종류" source="provider" />
                <BooleanField source="marketing" label="마케팅 수신동의" />
                <DateField label="마케팅 수신동의 일자" source="marketing_agree_at" showTime />
                <BooleanField source="is_staff" label="관리자(플래너) 계정" />
                <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                  탈퇴 정보
                </Typography>
                <DateField label="탈퇴 날짜" source="data.withdrawal_at" showTime />
                <TextField label="탈퇴 사유" source="data.withdrawal_reason" />
                <TextField label="탈퇴 사유(기타)" source="data.withdrawal_text" />
                <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                  휴면 정보
                </Typography>
                <DateField label="휴면 날짜" source="data.sleep_at" showTime />
              </SimpleShowLayout>
              <SimpleShowLayout className={classes.columnGroup}>
                <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                  직업 정보
                </Typography>
                <TextField label="회사" source="company" />
                <TextField label="직업" source="job" />
                <ChipField label="업종" source="industry" />
                <ChipField label="직무" source="position" />
                <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                  카테고리
                </Typography>
                <TagsField label="카테고리" source="category_names" />
                <Typography variant="h6" className={classes.infoTitle}>
                  추가 정보
                </Typography>
                <MoreInfoField source="data" />
                <Typography variant="h6" className={classes.infoTitle}>
                  B2B 정보
                </Typography>
                <B2BField source="data" />
              </SimpleShowLayout>
              <SimpleShowLayout className={classes.columnGroup}>
                <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                  SNS 정보
                </Typography>
                <UrlField label="facebook" source="facebook" />
                <UrlField label="instagram" source="instagram" />
                <UrlField label="linkedin" source="linkedin" />
                <Typography variant="h6" className={classes.infoTitle}>
                  멤버십
                </Typography>
                <TextField label="멤버십 타입" source="current_membership.type" />
                <DateField label="시작일" source="current_membership.start_at" />
                <DateField label="종료일" source="current_membership.end_at" />
                <TextField label="결제수단" source="current_membership.order.method" />
                <TextField label="주문ID" source="current_membership.order.imp_uid" />
                <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                  기타
                </Typography>
                <TextField label="초대한 유저 id" source="invitation_id" />
                <ChipField label="stage" source="stage" />
                <Typography variant="h6" className={classes.infoTitle} gutterBottom>
                  메모
                </Typography>
                <TextField label="✏️" source="memo" />
              </SimpleShowLayout>
            </SimpleShowLayout>
          </SimpleShowLayout>
        </Tab>
        <Tab label="프로그램 참가내역" path="participationList">
          <List {...props} pagination={<UserPagination />} filters={<ProgramListFilter />} filter={{ listType: 'user_participationList', userId: props.id }}>
            <Datagrid expand={<ProgramOrderPanel />} classes={{ headerCell: classes.headerCell }}>
              <TextField label="프로그램ID" source="program.id" />
              <ProgramLinkField label="프로그램" source="program" />
              <DateField label="진행일시" source="program.meets[0].start_at" showTime />
              <TextField label="결제ID" source="order.imp_uid" />
              <DateField label="결제일시" source="order.created_at" showTime />
              <NumberField label="결제금액" source="order.price" options={{ style: 'currency', currency: 'KRW' }} />
              <PaymentMethodField label="결제수단" source="order.method" />
              <OrderStatusField label="결제상태" />
              <TextField label="쿠폰ID" source="used_coupon" />
              <BooleanField label="출석" source="attendance" />
              <EditButton basePath="/participations" userId={props.id} />
            </Datagrid>
          </List>
        </Tab>
        <Tab label="쿠폰" path="couponList">
          <List
            {...props}
            pagination={<UserPagination {...props} />}
            filters={<CouponListFilter />}
            filter={{ listType: 'user_couponList', userId: props.id }}
            actions={<UserCouponActions {...props} />}
          >
            <Datagrid classes={{ headerCell: classes.headerCell }}>
              <CouponExpiredField label="상태" source="expire_at" />
              <TextField label="쿠폰 ID" source="id" />
              <TextField label="쿠폰 이름" source="coupon_name" />
              <TextField label="쿠폰 타입" source="type" />
              <DateField label="발행 일시" source="created_at" showTime />
              <DateField label="만료 일시" source="expire_at" showTime />
              <TextField label="사용한 프로그램" source="participation.program" />
              <CouponTagsField label="사용 제한 (전용 쿠폰)" source="tags" />
              <EditButton basePath="/coupons" userId={props.id} />
            </Datagrid>
          </List>
        </Tab>
        <Tab label="활동 내역" path="eventList">
          <List {...props} pagination={<UserPagination {...props} />} filters={<UserEventListFilter />} filter={{ listType: 'user_eventList', userId: props.id }}>
            <Datagrid classes={{ headerCell: classes.headerCell }}>
              <TextField label="ID" source="id" />
              <TextField label="uuid" source="uuid" />
              <TextField label="카테고리" source="category" />
              <TextField label="액션" source="action" />
              <DateField label="시간" source="timestamp" showTime />
              <BooleanField label="알림" source="is_notified" />
            </Datagrid>
          </List>
        </Tab>
      </TabbedShowLayout>
    </ShowUser>
  );
};

export default DetailUser;
