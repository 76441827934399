import { client } from 'graphql/providers';
import { gql } from '@apollo/client';
import { searchFeeds, listFeeds, getFeed } from 'graphql/queries';
import { createFeed as createFeedMutation, updateFeed as updateFeedMutation, deleteFeed as deleteFeedMutation } from 'graphql/mutations';
import { apiAxios } from 'helpers/axios';

const DEFAULT_TYPE = 'blog';

const blogProvider = {
  getList: async (resource, params) => {
    const { filter, pagination } = params;

    if (filter.search) {
      const { data } = await client.query({
        query: gql`
          ${searchFeeds}
        `,
        variables: {
          filter: {
            type: DEFAULT_TYPE,
            keyword: filter.search,
          },
          limit: pagination.perPage,
          page: pagination.page,
        },
      });

      return {
        data: data.searchFeeds.items,
        total: data.searchFeeds.total,
      };
    }
    const { data } = await client.query({
      query: gql`
        ${listFeeds}
      `,
      variables: {
        filter: {
          type: DEFAULT_TYPE,
          is_pinned: filter.is_pinned || false,
          ...(filter.tags ? { tags: filter.tags } : {}),
        },
        limit: pagination.perPage,
        page: pagination.page,
      },
    });

    return {
      data: data.listFeeds.items,
      total: data.listFeeds.total,
    };
  },
  getOne: async (resource, params) => {
    const { id } = params;
    const { data } = await client.query({
      query: gql`
        ${getFeed}
      `,
      variables: {
        id,
      },
    });
    return {
      data: data.getFeed,
    };
  },
  create: async (resource, params) => {
    const { user_id, is_pinned, data, thumbnail, tags } = params.data;
    const { title, body, summary } = data;
    const formData = new FormData();
    formData.append('image', thumbnail);
    formData.append('source', 'blog');
    const uploadedThumbnail = await apiAxios({ method: 'POST', uri: '/images/', data: formData });

    const {
      data: { createFeed },
    } = await client.mutate({
      mutation: gql`
        ${createFeedMutation}
      `,
      variables: {
        input: {
          type: DEFAULT_TYPE,
          user_id: parseInt(user_id),
          is_pinned,
          tags: tags,
          data: {
            title,
            summary,
            body,
            thumbnail: uploadedThumbnail.data.image,
          },
        },
      },
    });
    return {
      data: createFeed,
    };
  },
  update: async (resource, params) => {
    const { id, is_pinned, data, newThumbnail, tags } = params.data;
    const { title, body, summary } = data;
    let uploadedThumbnail = null;

    if (newThumbnail) {
      var formData = new FormData();
      formData.append('image', newThumbnail);
      formData.append('source', 'blog');
      uploadedThumbnail = await apiAxios({ method: 'POST', uri: '/images/', data: formData });
    }

    const {
      data: { updateFeed },
    } = await client.mutate({
      mutation: gql`
        ${updateFeedMutation}
      `,
      variables: {
        input: {
          id,
          is_pinned,
          tags: tags,
          data: {
            title,
            summary,
            body,
            ...(uploadedThumbnail && { thumbnail: uploadedThumbnail.data.image }),
          },
        },
      },
    });

    return {
      data: updateFeed,
    };
  },
  delete: async (resource, params) => {
    const { id } = params;

    const {
      data: { deleteFeed },
    } = await client.mutate({
      mutation: gql`
        ${deleteFeedMutation}
      `,
      variables: {
        input: {
          id,
        },
      },
    });

    return {
      data: deleteFeed,
    };
  },
};

export default blogProvider;
