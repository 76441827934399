import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, FunctionField, ArrayField, Datagrid, downloadCSV } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import jsonExport from 'jsonexport/dist';

const useStyles = makeStyles((theme) => ({
  div: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '8px 0px',
  },
  button: {
    border: '1px solid black',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F04E44',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '4px 8px',

    '&:hover': { cursor: 'pointer' },
  },
  icon: {
    marginRight: '4px',
    width: '18px',
  },
}));

const exporter = (data) => {
  const postsForExport = data?.users.map((record) => {
    let fileds = {};
    fileds['ID'] = record.id;
    fileds['이름'] = record.username;
    fileds['성별'] = record.gender;
    fileds['이메일'] = record.email;
    fileds['핸드폰 번호'] = record.phone;
    fileds['회사'] = record.company;
    fileds['직업'] = record.job;
    fileds['가입 일시'] = new Date(record.created_at).toLocaleString();
    return fileds;
  });
  jsonExport(postsForExport, (err, csv) => {
    downloadCSV(csv, `${data.name}_유저_목록`);
  });
};

const PostShowActions = ({ basePath, data, resource }) => {
  const classes = useStyles();

  return (
    <div className={classes.div}>
      <button className={classes.button} onClick={() => exporter(data)}>
        <SaveAltIcon className={classes.icon} />
        유저 목록
      </button>
    </div>
  );
};

const UserField = () => (
  <>
    <p>👥 기업 등록 유저</p>
    <ArrayField source="users" fieldKey="id">
      <Datagrid>
        <TextField label="ID" source="id" />
        <TextField label="이름" source="username" />
        <TextField label="성별" source="gender" />
        <TextField label="이메일" source="email" />
        <TextField label="핸드폰 번호" source="phone" />
        <TextField label="회사" source="company" />
        <TextField label="직업" source="job" />
        <DateField label="가입 일시" source="created_at" showTime={true} />
      </Datagrid>
    </ArrayField>
  </>
);

const DetailB2B = (props) => {
  const PostTitle = ({ record }) => <span>[기업 교육] {record ? record.name : ''}</span>;
  return (
    <Show {...props} title={<PostTitle />} actions={<PostShowActions />}>
      <SimpleShowLayout>
        <TextField label="회사 이름" source="name" sortable={false} />
        <TextField label="회사 코드" source="code" sortable={false} />
        <FunctionField label="생성 일시" sortable={false} render={(record) => new Date(record.created_at).toLocaleString()} />
        <FunctionField
          label="기업교육 등록 주소"
          render={(record) => (process.env.REACT_APP_ENV === 'production' ? `https://heyjoyce.com/b2b/${record.url}` : `https://dev.heyjoyce.com/b2b/${record.url}`)}
          sortable={false}
        />
        <UserField />
      </SimpleShowLayout>
    </Show>
  );
};

export default DetailB2B;
