import { client } from 'graphql/providers';
import { gql } from '@apollo/client';
import { listSerializedCoupons } from 'graphql/queries';
import { createSerializedCoupon as createSerializedCouponMutation } from 'graphql/mutations';

const serializedCouponProvider = {
  getList: async (resource, params) => {
    const { filter } = params;
    const { label } = filter;
    const { data } = await client.query({
      query: gql`
        ${listSerializedCoupons}
      `,
      variables: {
        ...(filter.label ? { filter: { label } } : {}),
      },
    });
    return {
      data: data.listSerializedCoupons.items.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
      total: data.listSerializedCoupons.total,
    };
  },
  create: async (resource, params) => {
    const { components = [], amount = 0, label } = params.data;
    const {
      data: { createSerializedCoupon },
    } = await client.mutate({
      mutation: gql`
        ${createSerializedCouponMutation}
      `,
      variables: {
        input: {
          components,
          amount,
          label,
        },
      },
    });
    return {
      data: createSerializedCoupon,
    };
  },
};

export default serializedCouponProvider;
