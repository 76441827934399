import React, { useEffect, useRef, useState } from 'react';
import { useCSVDownloader, usePapaParse } from 'react-papaparse';
import { useModalContext } from 'hooks/useModalContext';
import { ModalCloseButton } from './CommonModal';
import { getTimestampSerial } from 'helpers';
import { adminAxios } from 'helpers/axios';
import styled from 'styled-components';

const PARTICIPATION_CSV_COLUMNS = Object.freeze({
  '유저 아이디': 'id',
  '유저 이메일': 'email',
  '유저 이름': 'username',
});

const ADM_ORDER_DATA = {
  price: 0,
  method: 'notimp',
  status: 'paid',
};

const ModalWrapper = styled.div`
  max-width: 500px;
  min-height: unset;
  color: #1a1a1a;
  font-size: 14px;

  .middle {
    margin: 16px 0px;

    ol {
      padding-left: 18px;
    }
  }

  .warning {
    padding: 16px;
    background-color: #f2f2f2;

    h4 {
      margin: 0 0 16px 0;
    }

    p {
      margin: 0;
    }

    ul {
      padding-left: 16px;

      li {
        margin-bottom: 4px;
      }
    }

    span {
      background: #dfdfdf;
      font-size: 12px;
      padding: 4px;
      border-radius: 4px;
      color: #595959;
      font-weight: bold;
    }
  }
`;

const DownloadButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 4px;

  button {
    background: transparent;
    border: none;
    font-size: 14px;
    text-decoration: underline;
    color: #1a1a1a;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }
`;

const UploadSectionWrapper = styled.div`
  input[type='file'] {
    display: none;
  }

  button {
    background: transparent;
    border: none;
    border-radius: 4px;
    padding: 12px 24px;
    font-size: 14px;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  button.fileUpload {
    width: 100%;
    border: 1px dashed #cfcfcf;
    color: dimgray;

    &:hover {
      background: #f8f8f8;
    }
  }

  button.submit {
    background: #fddb00;
    font-weight: bold;
    width: 100%;

    &:hover {
      background: #f5d400;
    }
  }
`;

const CSVDownloadButton = () => {
  const { CSVDownloader, Type } = useCSVDownloader(); // Docs. https://react-papaparse.js.org/docs#csv-downloader
  const columnKeys = Object.keys(PARTICIPATION_CSV_COLUMNS);

  return (
    <DownloadButtonWrapper>
      <CSVDownloader
        type={Type.Button}
        filename={'신청자_일괄등록_템플릿'}
        data={[
          {
            [columnKeys[0]]: '(ex) 1',
            [columnKeys[1]]: '(ex) standard@heyjoyce.com',
            [columnKeys[2]]: '(ex) 김스탠',
          },
        ]}
      >
        신청자_일괄등록_템플릿.csv
      </CSVDownloader>
    </DownloadButtonWrapper>
  );
};

const CSVUploadSection = ({ programId, refresh }) => {
  const { readString } = usePapaParse(); // Docs. https://react-papaparse.js.org/docs#strings

  const [, , hide] = useModalContext();

  const inputRef = useRef();
  const [csvFile, setCsvFile] = useState();
  const [parsedCsvData, setParsedCsvData] = useState({ orders: [], participations: [] });
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitComplete, setIsSubmitComplete] = useState(false);

  useEffect(() => {
    if (!csvFile) {
      return;
    }

    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function (e) {
      const csvString = e.target.result;
      readString(csvString, {
        header: true,
        transformHeader: (header) => PARTICIPATION_CSV_COLUMNS[header],
        transform: (column, header) => {
          if (header === 'id' || header === 'program_id') {
            return parseInt(column);
          }
          return column.trim();
        },
        complete: ({ data = [], errors = [] }) => {
          if (errors.length > 0) {
            alert('파입 업로드 중 에러가 발생하였습니다. 개발팀에게 문의해주세요.', errors[0]);
            setCsvFile(undefined);
            return;
          }

          const now = new Date();
          const serialTime = getTimestampSerial(now);
          const isoTime = now.toISOString();

          const [orders, participations] = data.reduce(
            (result, item, index) => {
              const { id: userId } = item;

              if (!userId) {
                alert('유저 정보에 잘못된 값이 들어가있습니다. 개발팀에게 업로드한 파일을 첨부하여 문의해주세요.');
                setCsvFile(undefined);
                return false;
              }

              const impUid = `imp_adm_${serialTime}_${index}`;
              const merchantUid = `program_adm_U${userId}T${serialTime}`;

              // order
              result[0].push({
                imp_uid: impUid,
                merchant_uid: merchantUid,
                created_at: isoTime,
                user_id: userId,
                ...ADM_ORDER_DATA,
              });
              // participation
              result[1].push({
                user_id: userId,
                created_at: isoTime,
                order_id: impUid,
                program_id: programId,
              });

              return result;
            },
            [[], []]
          );

          setParsedCsvData({ orders: [...orders], participations: [...participations] });
        },
      });
    };

    reader.readAsText(file);
  }, [csvFile]);

  useEffect(() => {
    if (!isSubmitComplete) {
      return;
    }

    refresh();

    setTimeout(() => {
      hide();
    }, 2000);
  }, [isSubmitComplete]);

  const handleClickUploadButton = () => {
    inputRef.current.click();
  };

  const handleClickSubmit = async () => {
    setIsSubmitLoading(true);

    try {
      await adminAxios({
        method: 'POST',
        uri: '/bulk/participations/',
        data: parsedCsvData,
      });
      setIsSubmitComplete(true);
    } catch (error) {
      alert('참석 데이터 등록 중 에러가 발생하였습니다. 개발팀에게 업로드한 파일을 첨부하여 문의해주세요.');
    }

    setIsSubmitLoading(false);
  };

  return (
    <UploadSectionWrapper>
      <input ref={inputRef} type="file" accept=".csv" onChange={(e) => setCsvFile(e.target.files[0])} />
      <button className="fileUpload" onClick={handleClickUploadButton}>
        {csvFile?.name || '파일 업로드하기'}
      </button>
      {parsedCsvData.orders.length > 0 && (
        <>
          <p>
            프로그램#{programId}에 총 {parsedCsvData.orders.length} 명의 유저를 신청자로 등록합니다.
            <br />
            데이터가 올바르게 입력되었는지 확인 후 진행해주세요.
          </p>
          <button className="submit" onClick={handleClickSubmit} disabed={isSubmitLoading || isSubmitComplete}>
            {isSubmitComplete ? '완료되었습니다!' : isSubmitLoading ? '. . .' : '제출하기'}
          </button>
        </>
      )}
    </UploadSectionWrapper>
  );
};

const ProgramParticipationBulkCreateModal = ({ programId, refresh }) => (
  <ModalWrapper>
    <ModalCloseButton />
    <div className="top">
      <div className="title">신청자 일괄등록하기 (csv)</div>
    </div>
    <div className="middle">
      <ol>
        <li>
          <CSVDownloadButton />을 다운로드 받아주세요.
        </li>
        <li>다운 받으신 템플릿 파일에 유저 정보를 채워주세요.</li>
        <li>작성이 완료된 파일을 아래에 업로드해주세요.</li>
      </ol>
      <section className="warning">
        <h4 style={{ color: '#ff5946' }}>{'❗️주의해주세요'}</h4>
        <p>
          신청자 일괄등록 기능은 유저의 아이디만을 활용하여, 아래 규칙을 따라 <b>결제 및 신청 데이터를 일괄 생성</b>합니다
        </p>
        <ul>
          <li>
            Imp Uid : <span>imp_adm_연연연연월월일일시시분분초초_인덱스숫자</span> 형식으로 임의 생성됨
          </li>
          <li>
            Merchant Uid : <span>program_adm_U유저아이디T연연월월일일시시분분초초_인덱스숫자</span> 형식으로 임의 생성됨
          </li>
          <li>결제 금액 : 0원</li>
          <li>결제 수단 : 비결제방식</li>
          <li>결제 상태 : 결제 완료</li>
          <li>결제 일시 : 등록 시간</li>
        </ul>
      </section>
    </div>
    <CSVUploadSection programId={programId} refresh={refresh} />
  </ModalWrapper>
);

export default ProgramParticipationBulkCreateModal;
