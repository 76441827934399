import * as React from 'react';
import { List, Datagrid, TextField, Pagination, DateField, Filter, TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RemoveIcon from '@material-ui/icons/Remove';
import { FilterField } from './filterHelpers';

const useStyles = makeStyles({
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
    '& span.MuiButtonBase-root': {
      display: 'inline-block',
      padding: '0 3px',
    },
    '& span.MuiButtonBase-root:hover': {
      display: 'inline-block',
      backgroundColor: 'rgba(155,155,155,.2)',
      borderRadius: '5px',
      color: 'white',
    },
    '& .MuiTableSortLabel-active': {
      color: '#ffffff',
    },
    '& .MuiTableSortLabel-active>span': {
      padding: '0 0 0 3px',
    },
    '& .MuiSvgIcon-root': {
      filter: 'invert(98%) sepia(98%) saturate(10%) hue-rotate(210deg) brightness(104%) contrast(102%)',
      position: 'relative',
      top: '4px',
    },
  },
  smallIcon: {
    fontSize: 'small',
  },
  editButton: {
    padding: '10px 0',
  },
  content: {
    display: 'block',
    minWidth: '200px',
    maxWidth: '300px',
  },
  canceled: {
    backgroundColor: '#f04e43',
    borderRadius: '5px',
    fontWeight: '700',
    color: '#ffffff',
    fontSize: '12px',
    padding: '2px',
    textAlign: 'center',
  },
  search: {
    width: '50ch',
  },
  pushFilterItem: {
    padding: '10px',
    borderBottom: '1px solid rgb(123,123,123)',
  },
  tagContainer: {
    '& > *': {
      margin: '2px',
    },
  },
  count: {
    minWidth: '50px',
  },
});

const PushFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <TextInput label="🔎 id 또는 제목으로 검색" source="search" resettable={true} alwaysOn className={classes.search} />
    </Filter>
  );
};

const PushPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const PushCancelStatusField = ({ record }) => {
  const classes = useStyles();
  return <div>{record.canceled ? <div className={classes.canceled}>취소</div> : <RemoveIcon className={classes.smallIcon} />}</div>;
};

const SendCount = ({ record, success }) => {
  const classes = useStyles();
  const count = success ? record.success_count : record.failure_count;
  return (
    <div>
      <span>{count || count === 0 ? count : <RemoveIcon className={classes.smallIcon} />}</span>
    </div>
  );
};

const ListPush = (props) => {
  const classes = useStyles();
  return (
    <List {...props} pagination={<PushPagination />} classes={{ list: classes.list }} bulkActionButtons={false} filters={<PushFilter />} sort={{ field: 'created_at', order: 'DESC' }}>
      <Datagrid expand={<FilterField />} classes={{ headerCell: classes.headerCell }} rowClick="show">
        <TextField label="ID" source="id" />
        <TextField label="제목" source="title" />
        <TextField label="내용" source="content" sortable={false} className={classes.content} />
        <DateField label="푸시 시작 일시" showTime={true} source="start_at" />
        <DateField label="생성일시" showTime={true} source="created_at" />
        <SendCount label="전송성공" source="success_count" success={true} sortable={false} className={classes.count} />
        <SendCount label="전송실패" source="failure_count" success={false} sortable={false} classeName={classes.count} />
        <PushCancelStatusField label="취소여부" source="canceled" sortable={false} className={classes.cancelField} />
      </Datagrid>
    </List>
  );
};

export default ListPush;
