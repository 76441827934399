import React, { useEffect, useRef, useState } from 'react';
import { useCSVDownloader, usePapaParse } from 'react-papaparse';
import { bulkCreateWebinarAttendee as bulkCreateWebinarAttendeeMutation } from 'graphql/mutations';
import { useModalContext } from 'hooks/useModalContext';
import { ModalCloseButton } from './CommonModal';
import { client } from 'graphql/providers';
import { gql } from '@apollo/client';
import styled from 'styled-components';

const ATTENDEE_CSV_COLUMNS = Object.freeze({
  '유저 아이디': 'id',
  '유저 이메일': 'email',
  '유저 이름': 'username',
  '등록 프로그램 아이디': 'program_id',
});

const ModalWrapper = styled.div`
  max-width: 500px;
  min-height: unset;
  color: #1a1a1a;
  font-size: 14px;

  .middle {
    margin: 16px 0px;

    ol {
      padding-left: 18px;
    }
  }
`;

const DownloadButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 4px;

  button {
    background: transparent;
    border: none;
    font-size: 14px;
    text-decoration: underline;
    color: #1a1a1a;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }
`;

const UploadSectionWrapper = styled.div`
  input[type='file'] {
    display: none;
  }

  button {
    background: transparent;
    border: none;
    border-radius: 4px;
    padding: 12px 24px;
    font-size: 14px;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  button.fileUpload {
    width: 100%;
    border: 1px dashed #cfcfcf;
    color: dimgray;

    &:hover {
      background: #f8f8f8;
    }
  }

  button.submit {
    background: #fddb00;
    font-weight: bold;
    width: 100%;

    &:hover {
      background: #f5d400;
    }
  }
`;

const CSVDownloadButton = () => {
  const { CSVDownloader, Type } = useCSVDownloader(); // Docs. https://react-papaparse.js.org/docs#csv-downloader
  const columnKeys = Object.keys(ATTENDEE_CSV_COLUMNS);

  return (
    <DownloadButtonWrapper>
      <CSVDownloader
        type={Type.Button}
        filename={'참석자_일괄등록_템플릿'}
        data={[
          {
            [columnKeys[0]]: '(ex) 1',
            [columnKeys[1]]: '(ex) standard@heyjoyce.com',
            [columnKeys[2]]: '(ex) 김스탠',
            [columnKeys[3]]: '(ex) 1018',
          },
        ]}
      >
        참석자_일괄등록_템플릿.csv
      </CSVDownloader>
    </DownloadButtonWrapper>
  );
};

const CSVUploadSection = ({ webinarId, refresh }) => {
  const { readString } = usePapaParse(); // Docs. https://react-papaparse.js.org/docs#strings
  const [, , hide] = useModalContext();

  const inputRef = useRef();
  const [csvFile, setCsvFile] = useState();
  const [parsedCsvData, setParsedCsvData] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitComplete, setIsSubmitComplete] = useState(false);

  useEffect(() => {
    if (!csvFile) {
      return;
    }

    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function (e) {
      const csvString = e.target.result;
      readString(csvString, {
        header: true,
        transformHeader: (header) => ATTENDEE_CSV_COLUMNS[header],
        transform: (column, header) => {
          if (header === 'id' || header === 'program_id') {
            return parseInt(column);
          }
          return column.trim();
        },
        complete: ({ data = [], errors = [] }) => {
          if (errors.length > 0) {
            alert('파입 업로드 중 에러가 발생하였습니다. 개발팀에게 문의해주세요.', errors[0]);
            return;
          }
          setParsedCsvData([...data]);
        },
      });
    };

    reader.readAsText(file);
  }, [csvFile]);

  useEffect(() => {
    if (!isSubmitComplete) {
      return;
    }

    refresh();

    setTimeout(() => {
      hide();
    }, 2000);
  }, [isSubmitComplete]);

  const handleClickUploadButton = () => {
    inputRef.current.click();
  };

  const handleClickSubmit = async () => {
    setIsSubmitLoading(true);

    try {
      await client.mutate({
        mutation: gql`
          ${bulkCreateWebinarAttendeeMutation}
        `,
        variables: {
          input: {
            webinar_id: webinarId,
            users: parsedCsvData,
          },
        },
      });
    } catch (error) {
      setIsSubmitLoading(false);
    }

    setIsSubmitLoading(false);
    setIsSubmitComplete(true);
  };

  return (
    <UploadSectionWrapper>
      <input ref={inputRef} type="file" accept=".csv" onChange={(e) => setCsvFile(e.target.files[0])} />
      <button className="fileUpload" onClick={handleClickUploadButton}>
        {csvFile?.name || '파일 업로드하기'}
      </button>
      {parsedCsvData.length > 0 && (
        <>
          <p>
            총 {parsedCsvData.length} 명의 유저를 참석자로 등록합니다.
            <br />
            데이터가 올바르게 입력되었는지 확인 후 진행해주세요.
          </p>
          <button className="submit" onClick={handleClickSubmit} disabed={isSubmitLoading || isSubmitComplete}>
            {isSubmitComplete ? '완료되었습니다!' : isSubmitLoading ? '. . .' : '제출하기'}
          </button>
        </>
      )}
    </UploadSectionWrapper>
  );
};

const AttendeeBulkCreateModal = ({ webinarId, refresh }) => (
  <ModalWrapper>
    <ModalCloseButton />
    <div className="top">
      <div className="title">참석자 일괄등록하기 (csv)</div>
    </div>
    <div className="middle">
      <ol>
        <li>
          <CSVDownloadButton />을 다운로드 받아주세요.
        </li>
        <li>다운 받으신 템플릿 파일에 유저 정보를 채워주세요.</li>
        <li>작성이 완료된 파일을 아래에 업로드해주세요.</li>
      </ol>
    </div>
    <CSVUploadSection webinarId={webinarId} refresh={refresh} />
  </ModalWrapper>
);

export default AttendeeBulkCreateModal;
