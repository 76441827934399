import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, AutocompleteInput, BooleanInput, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import useAdminApi from 'hooks/useAdminApi';
import { CustomEditor } from 'components/CustomEditor';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '50ch',
  },
}));

const CreateFeed = (props) => {
  const classes = useStyles();

  const { data: categories } = useAdminApi({
    method: 'GET',
    url: '/categories/',
  });

  const { data: fellows } = useAdminApi({
    method: 'GET',
    url: '/users/?stage=20&limit=200&offset=0',
  });

  if (!(categories && fellows)) {
    return <div />;
  }

  return (
    <Create title="피드 만들기" {...props}>
      <SimpleForm submitOnEnter={false}>
        <SelectInput className={classes.layout} validate={[required()]} label="타입" source="type" choices={[{ id: 'qna', name: 'Q&A' }]} defaultValue="qna" variant="outlined" />
        <SelectInput className={classes.layout} validate={[required()]} label="카테고리" source="category_id" choices={[...categories.results]} variant="outlined" />
        <AutocompleteInput
          className={classes.layout}
          validate={[required()]}
          label="답변 작성자"
          source="user_id"
          choices={[...fellows.results]}
          optionText={(fellow) => fellow && `${fellow.username}`}
        />
        <TextInput className={classes.layout} validate={[required()]} label="한줄 질문" source="data.title" variant="standard" />
        <CustomEditor
          variant="standard"
          label="답변"
          source="data.body"
          toolbar={[
            [{ header: [1, 2, 3, 4, 5, 6, false] }, 'bold', 'italic', 'underline', { align: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
          ]}
        />
        <BooleanInput source="is_pinned" label="메인 노출" initialValue={true} />
      </SimpleForm>
    </Create>
  );
};

export default CreateFeed;
