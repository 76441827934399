import React, { createContext, useContext, useState, useCallback } from 'react';

export const MODALS = {
  action: 'action',
};

const initialState = {
  key: '',
  hasParent: false,
  hasBackdrop: true,
  data: {},
};

export const ModalContext = createContext({
  ...initialState,
});

export const useModalContext = () => useContext(ModalContext);

export const withModalProvider = (Component) =>
  function Wrapper(props) {
    const [state, setState] = useState(initialState);

    const dispatch = (updated) => {
      setState((prevState) => ({
        ...prevState,
        ...updated,
      }));
    };

    const hide = useCallback(() => {
      setState(initialState);
    }, []);

    // const handleHistoryBack = useCallback(() => {
    //   if (!window.location.hash) {
    //     hide();
    //   }
    // }, [hide]);

    // React.useEffect(() => {
    //   window.addEventListener('popstate', handleHistoryBack);
    //   return () => window.removeEventListener('popstate', handleHistoryBack);
    // }, [handleHistoryBack]);

    return (
      <ModalContext.Provider value={[state, dispatch, hide]}>
        <Component {...props} />
      </ModalContext.Provider>
    );
  };
