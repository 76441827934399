import { adminAxios } from 'helpers/axios';

const interviewProvider = {
  getList: async (resource, params) => {
    const {
      filter: { opened },
      pagination: { page, perPage },
    } = params;
    const offset = (page - 1) * perPage;
    const {
      data: { count, results },
    } = await adminAxios({
      method: 'GET',
      uri: '/interviews/',
      query: `?limit=${perPage}&offset=${offset}${opened ? `&opened=${opened}` : ''}`,
    });

    return {
      data: results,
      total: count,
    };
  },
  create: async (resource, params) => {
    const {
      data: { thumbnail_img_url, subject = '', summary = '', link = '', opened = false },
    } = params;

    const formData = new FormData();

    formData.append('thumbnail_img_url', thumbnail_img_url instanceof File ? thumbnail_img_url : thumbnail_img_url.rawFile);
    formData.append('subject', subject);
    formData.append('summary', summary);
    formData.append('link', link);
    formData.append('opened', opened);

    const { data } = await adminAxios({
      method: 'POST',
      uri: '/interviews/',
      data: formData,
    });

    return {
      data: data,
    };
  },
  getOne: async (resource, params) => {
    const { data } = await adminAxios({
      method: 'GET',
      uri: '/interviews/',
      query: params.id + '/',
    });
    return {
      data: data,
    };
  },
  update: async (resource, params) => {
    const { id, new_thumbnail_img_url, subject = '', summary = '', link = '', opened = false } = params.data;
    const formData = new FormData();

    // 이미지 교체
    if (new_thumbnail_img_url) {
      formData.append('thumbnail_img_url', new_thumbnail_img_url instanceof File ? new_thumbnail_img_url : new_thumbnail_img_url.rawFile);
    }

    formData.append('subject', subject);
    formData.append('summary', summary);
    formData.append('link', link);
    formData.append('opened', opened);

    const { data } = await adminAxios({
      method: 'PATCH',
      uri: '/interviews/',
      data: formData,
      query: id + '/',
    });

    return {
      data: data,
    };
  },
  delete: async (resource, params) => {
    const { id } = params;
    const { data } = await adminAxios({
      method: 'DELETE',
      uri: '/interviews/' + id + '/',
    });

    return {
      data: data,
    };
  },
};

export default interviewProvider;
