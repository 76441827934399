import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Admin, Resource, usePermissions } from 'react-admin';

import { createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import Dashboard from './containers/Dashboard';
import UserIcon from '@material-ui/icons/People';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import EventIcon from '@material-ui/icons/Event';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import BookIcon from '@material-ui/icons/Book';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import RateReviewIcon from '@material-ui/icons/RateReview';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import BusinessIcon from '@material-ui/icons/Business';
import InterviewIcon from '@material-ui/icons/ChromeReaderMode';
import AssignmentIcon from '@material-ui/icons/Assignment';
import QuizIcon from '@material-ui/icons/LiveHelp';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';

import { client, authProvider, dataProvider, i18nProvider } from './graphql/providers';
import { ListUser, DetailUser, EditUser, CreateCoupon, EditCoupon, EditParticipation, CreateUser } from './containers/User';
import { ListFeed, CreateFeed, EditFeed } from './containers/Feed';
import { ListBlog, CreateBlog, EditBlog, DetailBlog } from './containers/Blog';
import { ListApply, EditApply } from './containers/Apply';
import { ListWebinar, DetailWebinar, CreateWebinar, EditWebinar, CreateWebinarAttendee } from './containers/Webinar';
import { ListProgram, CreateProgram, EditProgram, DetailProgram, CreateParticipation } from 'containers/Program';
import { ListPush, DetailPush } from './containers/Push';
import { ListPromotion, EditPromotion, CreatePromotion } from './containers/Promotion';
import { ListBanner, CreateBanner, EditBanner, DetailBanner } from './containers/Banner';
import { ListOrder, DetailOrder, EditOrder } from './containers/Order';
import { ListSerializedCoupon, CreateSerializedCoupon } from './containers/SerializedCoupon';
import { ListB2B, CreateB2B, DetailB2B } from './containers/B2B';
import { ListInterview, CreateInterview, EditInterview } from './containers/Interview';
import { ListNotice, CreateNotice, EditNotice, DetailNotice } from './containers/Notice';
import { ListFAQ, CreateFAQ, EditFAQ } from './containers/FAQ';
import { ListCommunity, CreateCommunity, EditCommunity, DetailCommunity } from './containers/Community';
import { ListAppSetting, CreateAppSetting, EditAppSetting, DetailAppSetting } from './containers/AppSetting';

import { useModalContext, withModalProvider } from 'hooks/useModalContext';
import { CommonModal } from 'components/Modal/CommonModal';
import { Modal } from '@material-ui/core';
import styled from 'styled-components';
import './App.css';

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff5946',
    },
    secondary: {
      main: '#fddd00',
      contrastText: '#000',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Arial', 'sans-serif'].join(','),
  },
  shadows: ['none'],
  overrides: {
    RaAppBar: {
      menuButton: {
        'margin-left': '0.15em',
      },
    },
    RaAutocompleteArrayInput: {
      inputRoot: {
        display: 'flex',
        'min-width': '50ch',
        'flex-direction': 'column',
        'align-items': 'flex-start',
        '& > div:first-child': {
          display: 'flex',
          'flex-direction': 'column',
          'align-items': 'flex-start',
        },
      },
    },
  },
});

function App() {
  const { loaded } = usePermissions();
  const [modalState, , hide] = useModalContext();

  return loaded ? (
    <ApolloProvider client={client}>
      <>
        <Admin theme={theme} dashboard={Dashboard} dataProvider={dataProvider} authProvider={authProvider} i18nProvider={i18nProvider}>
          {(permissions) => [
            <Resource name="searchUsers" />,
            <Resource name="users" list={ListUser} create={CreateUser} show={DetailUser} edit={EditUser} icon={UserIcon} />,
            <Resource name="feeds" list={ListFeed} create={CreateFeed} edit={EditFeed} icon={QuestionAnswerIcon} />,
            <Resource name="webinars" list={ListWebinar} show={DetailWebinar} create={CreateWebinar} edit={EditWebinar} icon={OndemandVideoIcon} />,
            <Resource name="programs" list={ListProgram} show={DetailProgram} create={CreateProgram} edit={EditProgram} icon={EventIcon} />,
            <Resource name="pushes" list={ListPush} show={DetailPush} icon={NotificationsActiveIcon} />,
            <Resource name="coupons" create={CreateCoupon} edit={EditCoupon} />,
            <Resource name="participations" create={CreateParticipation} edit={EditParticipation} />,
            <Resource name="promotions" list={ListPromotion} create={CreatePromotion} edit={EditPromotion} />,
            <Resource name="webinarAttendees" create={CreateWebinarAttendee} />,
            <Resource name="banners" list={ListBanner} create={CreateBanner} edit={EditBanner} show={DetailBanner} icon={ViewCarouselIcon} />,
            <Resource name="blogs" list={ListBlog} show={DetailBlog} create={CreateBlog} edit={EditBlog} icon={BookIcon} />,
            <Resource name="applies" list={ListApply} edit={EditApply} icon={AssignmentInd} />,
            <Resource name="orders" list={ListOrder} show={DetailOrder} edit={EditOrder} icon={MonetizationOnIcon} />,
            <Resource name="communities" list={ListCommunity} create={CreateCommunity} edit={EditCommunity} show={DetailCommunity} icon={RateReviewIcon} />,
            <Resource name="serializedCoupons" list={ListSerializedCoupon} create={CreateSerializedCoupon} icon={ConfirmationNumberIcon} />,
            <Resource name="b2bs" list={ListB2B} create={CreateB2B} show={DetailB2B} icon={BusinessIcon} />,
            <Resource name="interviews" list={ListInterview} create={CreateInterview} edit={EditInterview} icon={InterviewIcon} />,
            <Resource name="notices" list={ListNotice} create={CreateNotice} edit={EditNotice} show={DetailNotice} icon={AssignmentIcon} />,
            <Resource name="faqs" list={ListFAQ} create={CreateFAQ} edit={EditFAQ} icon={QuizIcon} />,
            <Resource name="appSettings" list={ListAppSetting} create={CreateAppSetting} edit={EditAppSetting} show={DetailAppSetting} icon={PhonelinkSetupIcon} />,
          ]}
        </Admin>
        <StyledModal open={modalState.key} onClose={() => hide()}>
          <CommonModal>
            <>{modalState.key === 'webinarAttendeeBulk' && modalState.data.modalComponent}</>
            <>{modalState.key === 'programParticipationBulk' && modalState.data.modalComponent}</>
          </CommonModal>
        </StyledModal>
      </>
    </ApolloProvider>
  ) : null;
}

export default withModalProvider(App);
