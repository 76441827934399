import axios from 'axios';
import { AUTH_KEY } from 'graphql/providers';

const apiAxios = ({ method, uri, data }) => {
  const url = process.env.REACT_APP_LOGIN_API_URL;
  const token = localStorage.getItem(AUTH_KEY);
  return axios({
    method: method,
    url: url + uri,
    headers: {
      authorization: token ? `Bearer ${JSON.parse(token).access}` : '',
    },
    data,
  });
};

const adminAxios = ({ method, uri, data, query = '' }) => {
  const url = process.env.REACT_APP_ADMIN_API_URL;
  const token = localStorage.getItem(AUTH_KEY);

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      const { status = 404 } = error?.response;

      if (status === 401) {
        if (window.confirm('로그인 토큰이 만료되었습니다. 다시 로그인 해주세요.')) {
          localStorage.removeItem(AUTH_KEY);
          window.location.href = '/#/login';
        }
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );

  return axios({
    method: method,
    url: url + uri + query,
    headers: {
      authorization: token ? `Bearer ${JSON.parse(token).access}` : '',
    },
    data,
  });
};

export { apiAxios, adminAxios };
