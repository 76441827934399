import queryString from 'query-string';
import { adminAxios } from 'helpers/axios';

const WITHDRAW_REASON = {
  w_1001: 'ID 및 개인 정보를 바꾸고 싶어요',
  w_1002: '개인정보 유출/보안이 우려돼요',
  w_1003: '서비스가 만족스럽지 않아요 (콘텐츠/프로그램 가격, 내용 등)',
  w_1004: '앱/웹 사이트 사용이 불편해요',
  w_1005: '서비스 이용 빈도가 낮아요',
  w_1000: '기타',
};

const userProvider = {
  create: async (resource, params) => {
    const {
      username,
      password,
      nickname,
      phone_number,
      email,
      gender,
      is_active,
      marketing,
      company,
      job,
      industry,
      position,
      is_inspirer,
      birth_date,
      stage,
      category_ids,
      data: user_data,
    } = params.data;
    // eslint-disable-next-line no-useless-catch

    const data = await adminAxios({
      method: 'POST',
      uri: '/users/',
      data: {
        username,
        password,
        nickname,
        phone_number,
        gender,
        email,
        is_active,
        marketing,
        company,
        industry,
        position,
        job,
        is_inspirer,
        birth_date,
        stage,
        category_ids,
        ...(user_data && { data: user_data }),
      },
    });

    return { ...data };
  },
  getList: async (resource, params) => {
    const offset = (params.pagination.page - 1) * params.pagination.perPage;
    if (Object.prototype.hasOwnProperty.call(params.filter, 'listType')) {
      let result = {};
      const defaultQuery = '?user_id=' + params.filter.userId;
      if (params.filter.listType === 'user_participationList') {
        result = await adminAxios({
          method: 'GET',
          uri: '/participations/',
          query: defaultQuery + (params.filter.program_id ? '&program_id=' + params.filter.program_id : '') + '&limit=' + params.pagination.perPage + '&offset=' + offset,
        });
      }
      if (params.filter.listType === 'user_couponList') {
        result = await adminAxios({
          method: 'GET',
          uri: '/coupons/',
          query: defaultQuery + (params.filter.coupon_name ? '&coupon_name=' + params.filter.coupon_name : '') + '&limit=' + params.pagination.perPage + '&offset=' + offset,
        });
      }
      if (params.filter.listType === 'user_eventList') {
        result = await adminAxios({
          method: 'GET',
          uri: '/userEvents/',
          query:
            defaultQuery +
            (params.filter.category ? '&category=' + params.filter.category : '') +
            (params.filter.action ? '&action=' + params.filter.action : '') +
            '&limit=' +
            params.pagination.perPage +
            '&offset=' +
            offset,
        });
      }
      return {
        data: result.data.results,
        total: result.data.count,
      };
    }

    const { search, search_by_phone, stage, is_inactive } = params.filter;
    if (search && !isNaN(search)) {
      const { data } = await adminAxios({
        method: 'GET',
        uri: `/users/${search}/`,
      });
      return {
        data: [data],
        total: 1,
      };
    }

    const query = queryString.stringify({
      limit: params.pagination.perPage,
      offset,
      ...(stage >= 0 && { stage }),
      ...(is_inactive && { is_active: 0 }),
      ...((search_by_phone && { search: search_by_phone }) || (search && { search })),
    });

    const { data } = await adminAxios({
      method: 'GET',
      uri: '/users/',
      query: `?${query}`,
    });

    const results = data.results.map((item) => ({
      ...item,
      username: item.username && (item.username.length >= 2 ? item.username.replace(/(?<=.{1})./, '*') : '*'),
      email: item.email && '*****@' + item.email.split('@')[1],
      phone_number: item.phone_number && (item.phone_number.length >= 4 ? item.phone_number.replace(/.{4}$/, '****') : '*'.repeat(item.phone_number.length)),
    }));

    return {
      data: results,
      total: data.count,
    };
  },
  getOne: async (resource, params) => {
    // setUserId=(params.id);
    var categoryList = {};
    categoryList = await adminAxios({
      method: 'GET',
      uri: '/categories/',
      query: '',
    });

    const { data } = await adminAxios({
      method: 'GET',
      uri: '/users/',
      query: params.id + '/',
    });

    var category_names = [];
    data.category_ids.forEach((x) => {
      categoryList.data.results.forEach(function (value) {
        if (value['id'] === x) {
          category_names.push(value['name']);
        }
      });
    });

    data['category_names'] = category_names;
    if (data.is_active === false && data.data?.['withdrawal_reason']) {
      data.data['withdrawal_reason'] = WITHDRAW_REASON[`${data.data['withdrawal_type']}`];
    }
    return {
      data,
    };
  },
  update: async (resource, params) => {
    const {
      username,
      nickname,
      phone_number,
      email,
      introduce,
      address,
      postcode,
      detail_address,
      company,
      job,
      industry,
      position,
      facebook,
      instagram,
      linkedin,
      is_active,
      marketing,
      memo,
      stage,
      category_ids,
      data: user_data,
      is_staff,
    } = params.data;

    let newUserData = {};

    if (is_active === true) {
      // 휴면 계정 활성화
      if (user_data?.sleep_at) {
        delete user_data.sleep_at;
      }
      newUserData = { ...user_data };
      // 휴면 계정은 기존 user data 유지하면서 update
    } else if (user_data?.sleep_at) {
      newUserData = { ...user_data };
      // 휴면 계정이 아니면서 is_active = false인 user data에 탈퇴 정보 update
    } else {
      newUserData = {
        withdrawal_at: new Date(),
        withdrawal_text: '관리자 콘솔 탈퇴처리',
        withdrawal_type: 'w_1000',
        withdrawal_reason: '기타',
      };
    }

    const { data } = await adminAxios({
      method: 'PATCH',
      uri: '/users/' + params.id + '/',
      data: {
        username,
        nickname,
        phone_number,
        email,
        introduce,
        address,
        postcode,
        detail_address,
        company,
        job,
        industry,
        position,
        facebook,
        instagram,
        linkedin,
        is_active,
        marketing,
        memo,
        stage,
        category_ids,
        is_staff,
        ...((user_data || newUserData) && { data: newUserData }),
      },
    });

    return {
      data,
    };
  },
};

export default userProvider;
