import { client } from 'graphql/providers';
import { gql } from '@apollo/client';
import { getAppSetting } from 'graphql/queries';
import { createAppSetting as createAppSettingMutation, updateAppSetting as updateAppSettingMutation, deleteAppSetting as deleteAppSettingMutation } from 'graphql/mutations';

const appSettingProvider = {
  getOne: async (resource, params) => {
    const { id } = params;
    const { data } = await client.query({
      query: gql`
        ${getAppSetting}
      `,
      variables: {
        id,
      },
    });
    return {
      data: {
        ...data.getAppSetting,
        data: JSON.parse(data.getAppSetting.data).map((value) => ({
          name: value.name,
          path: value.path,
          is_highlighted: value.is_highlighted,
        })),
      },
    };
  },
  create: async (resource, params) => {
    const { group, type, start_at, end_at, data } = params.data;

    if (start_at >= end_at) {
      throw Error('노출 시작 일시는 종료 일시보다 이전이어야 합니다.');
    }

    // 종료 시각은 00초 -> 59초로 설정해서 다음 설정 노출 시작 시점까지 빈 시간 없애기
    end_at.setSeconds(59);

    const {
      data: { createAppSetting },
    } = await client.mutate({
      mutation: gql`
        ${createAppSettingMutation}
      `,
      variables: {
        input: {
          group,
          type,
          start_at,
          end_at,
          data: JSON.stringify(data),
        },
      },
    });
    return {
      data: {
        ...createAppSetting,
        data: JSON.parse(createAppSetting.data).map((value) => ({
          name: value.name,
          path: value.path,
          is_highlighted: value.is_highlighted,
        })),
      },
    };
  },
  update: async (resource, params) => {
    const { id, start_at, end_at, data } = params.data;

    if (start_at >= end_at) {
      throw Error('노출 시작 일시는 종료 일시보다 이전이어야 합니다.');
    }

    // 종료 시각은 00초 -> 59초로 설정해서 다음 설정 노출 시작 시점까지 빈 시간 없애기
    // 종료 시각을 수정하면 Date 객체고, 수정하지 않으면 string 타입. setSeconds는 Date 객체의 메소드이므로, Date 객체일 때만 동작하도록 함
    if (end_at instanceof Date) end_at.setSeconds(59);

    const {
      data: { updateAppSetting },
    } = await client.mutate({
      mutation: gql`
        ${updateAppSettingMutation}
      `,
      variables: {
        input: {
          id,
          start_at,
          end_at,
          data: JSON.stringify(data),
        },
      },
    });
    return {
      data: {
        ...updateAppSetting,
        data: JSON.parse(updateAppSetting.data).map((value) => ({
          name: value.name,
          path: value.path,
          is_highlighted: value.is_highlighted,
        })),
      },
    };
  },
  delete: async (resource, params) => {
    const { id } = params;
    const {
      data: { deleteAppSetting },
    } = await client.mutate({
      mutation: gql`
        ${deleteAppSettingMutation}
      `,
      variables: {
        input: {
          id,
        },
      },
    });
    return {
      data: deleteAppSetting,
    };
  },
};

export default appSettingProvider;
