import { adminAxios } from 'helpers/axios';
import moment from 'moment';

const bannerProvider = {
  getList: async (resource, params) => {
    const { filter, pagination, sort } = params;
    const offset = (pagination.page - 1) * pagination.perPage;

    let queryParams = `?offset=${offset}&limit=${pagination.perPage}`;

    if (filter.search) {
      queryParams += `&search=${filter.search}`;
    }

    if (filter.is_active) {
      queryParams += '&status=is_active';
    }

    if (filter.target) {
      queryParams += `&target=${filter.target}`;
    }

    if (filter.banner_type) {
      queryParams += `&type=${filter.banner_type}`;
    }

    queryParams += `&ordering=${sort.order === 'DESC' ? '-' : ''}${sort.field}`;

    const { data } = await adminAxios({
      method: 'GET',
      uri: '/banners/',
      query: queryParams,
    });

    return {
      data: data.results,
      total: data.count,
    };
  },
  getOne: async (resource, params) => {
    const { data } = await adminAxios({
      method: 'GET',
      uri: '/banners/',
      query: params.id + '/',
    });

    return {
      data: data,
    };
  },
  create: async (resource, params) => {
    const {
      banner_type,
      description,
      image_url,
      m_image_url = '',
      tag = '',
      title = '',
      subtitle = '',
      link,
      start_date,
      end_date,
      priority,
      target_guest,
      target_associate,
      target_lite,
      target_standard,
      target_premium,
      test,
    } = params.data;

    const startDateTime = moment(start_date).format();
    const endDateTime = moment(end_date).format();

    const formData = new FormData();

    formData.append('banner_type', banner_type);
    formData.append('description', description);
    formData.append('image_url', image_url instanceof File ? image_url : image_url.rawFile);
    formData.append('m_image_url', m_image_url && (m_image_url instanceof File ? m_image_url : m_image_url.rawFile));
    formData.append('tag', tag);
    formData.append('title', title);
    formData.append('subtitle', subtitle);
    formData.append('link', link);
    formData.append('start_date', startDateTime);
    formData.append('end_date', endDateTime);
    formData.append('priority', priority);
    formData.append('target_guest', target_guest);
    formData.append('target_associate', target_associate);
    formData.append('test', test);

    const { data } = await adminAxios({
      method: 'POST',
      uri: '/banners/',
      data: formData,
    });

    return {
      data: data,
    };
  },
  update: async (resource, params) => {
    const { previousData } = params;
    const {
      id,
      banner_type,
      description,
      new_image_url,
      new_m_image_url,
      tag,
      title,
      subtitle,
      link,
      start_date,
      end_date,
      priority,
      target_guest,
      target_associate,
      target_lite,
      target_standard,
      target_premium,
      test,
    } = params.data;

    const startDateTime = moment(start_date).format();
    const endDateTime = moment(end_date).format();

    const formData = new FormData();

    // 이미지 교체
    if (new_image_url) {
      formData.append('image_url', new_image_url instanceof File ? new_image_url : new_image_url.rawFile);
    }

    // 모바일 이미지 교체
    if (new_m_image_url) {
      formData.append('m_image_url', new_m_image_url instanceof File ? new_m_image_url : new_m_image_url.rawFile);
    }

    formData.append('banner_type', banner_type);
    formData.append('description', description);
    formData.append('tag', tag);
    formData.append('title', title);
    formData.append('subtitle', subtitle);
    formData.append('link', link);
    formData.append('start_date', startDateTime);
    formData.append('end_date', endDateTime);
    formData.append('priority', priority);
    formData.append('target_guest', target_guest);
    formData.append('target_associate', target_associate);
    formData.append('test', test);

    const { data } = await adminAxios({
      method: 'PATCH',
      uri: '/banners/',
      data: formData,
      query: id + '/',
    });

    return {
      data: data,
    };
  },
  delete: async (resource, params) => {
    const { id } = params;
    const { data } = await adminAxios({
      method: 'DELETE',
      uri: '/banners/' + id + '/',
    });

    return {
      data: data,
    };
  },
};

export default bannerProvider;
