import React, { useState } from 'react';
import { Link, useRefresh } from 'react-admin';
import { Button, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import AttendeeBulkCreateModal from 'components/Modal/AttendeeBulkCreateModal';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FaceIcon from '@material-ui/icons/Face';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import CheckIcon from '@material-ui/icons/Check';
import { gql } from '@apollo/client';
import { client } from 'graphql/providers';
import { useModalContext } from 'hooks/useModalContext';
import { invokeOperatorEvent } from 'graphql/mutations';
import { EVENT, ACTIONS } from 'event';
import jsonExport from 'jsonexport/dist';

const useStyles = makeStyles((theme) => ({
  topToolBar: {
    margin: '10px 0px',
    flex: 1,
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 3,
    marginBottom: 10,
  },
  controlRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  buttonIcon: {
    fontSize: 19,
    marginRight: 5,
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  updateTimestamp: {
    position: 'relative',
    fontSize: 13,
    color: 'gray',
    marginLeft: 9,
  },
  teachableCourse: {
    width: 100,
    padding: 10,
    fontSize: 14,
    borderRadius: 4,
    border: 'none',
    background: '#ececec',
    marginRight: 10,
    '&:focus': {
      outline: 'none',
    },
  },
  actionButton: {
    color: '#ff5946',
    marginLeft: 12,
    '& svg': {
      width: 20,
      marginRight: 8,
    },
  },
}));

function getDateTime(timestamp) {
  var d = new Date(timestamp);
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var day = d.getDate();
  d.setHours(d.getHours()); // offset from local time
  var h = d.getHours() % 12 || 12; // show midnight & noon as 12
  return `${year}년 ${month}월 ${day}일 ${d.getHours() < 12 ? '오전 ' : '오후 '}${(h < 10 ? '0' : '') + h + (d.getMinutes() < 10 ? ':0' : ':') + d.getMinutes()}`;
}

const AttendeesActions = (props) => {
  const { id, record } = props;
  const { attendees } = record;

  const refresh = useRefresh();
  const [, dispatch, hide] = useModalContext();

  // record에 부모(해당 웨비나) 정보 있음
  const [teachableStatus, setTeachableStatus] = useState('');
  const [teachableCourse, setTeachableCourse] = useState(0);

  // props.data 에 신청자 리스트 값 들어있음
  const classes = useStyles();

  const handleChangeTeachableId = (e) => setTeachableCourse(e.target.value);

  const handleRegisterTeachable = async () => {
    setTeachableStatus('');

    const { data } = await client.mutate({
      mutation: gql`
        ${invokeOperatorEvent}
      `,
      variables: {
        payload: JSON.stringify({
          id,
          event: {
            category: EVENT.webinar,
            action: ACTIONS.teachableRegistered,
          },
          data: {
            teachableCourse,
          },
        }),
      },
    });

    setTeachableStatus(data.invokeOperatorEvent.id);
  };

  const handleClickExport = async (attendees) => {
    jsonExport(
      attendees.map((row) => ({
        id: row.id,
        username: row.username,
        email: row.email,
        phone: row.phone,
        program_id: row.program_id,
        order_id: row.order_id,
        ordered_at: row.ordered_at,
        membership_type: row.membership_type,
        position: row.position,
        attendance: row.attendance,
      })),
      {
        headers: ['id', 'username', 'email', 'phone', 'program_id', 'order_id', 'ordered_at', 'membership_type', 'position', 'attendance'],
      },
      (err, csv) => {
        let blob;

        var userAgent = window.navigator.userAgent;
        var isWindows = userAgent.indexOf('Windows NT');

        const fakeLink = document.createElement('a');
        fakeLink.style.display = 'none';
        document.body.appendChild(fakeLink);

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // Manage IE10+ & Edge
          blob = new Blob(['\uFEFF' + csv], { type: 'text/csv;charset=utf-8' });
          window.navigator.msSaveOrOpenBlob(blob, `${`웨비나_${props.id}_신청자리스트`}.csv`);
          return;
        } else if (isWindows) {
          blob = new Blob(['\uFEFF' + csv], { type: 'text/csv;charset=utf-8' });
        } else {
          blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        }

        fakeLink.setAttribute('href', URL.createObjectURL(blob));
        fakeLink.setAttribute('download', `${`웨비나_${props.id}_신청자리스트`}.csv`);
        fakeLink.click();
      }
    );
  };

  return (
    <div className={classes.topToolBar}>
      <div className={classes.infoRow}>
        <div>
          <Chip style={{ marginRight: '5px' }} icon={<FaceIcon />} label={`총 신청자 ${Object.keys(attendees).length}명`} variant="outlined" />
          <Chip style={{ marginRight: '5px' }} icon={<InsertEmoticonIcon />} label={`출석자 ${attendees.filter((item) => item.attendance)?.length} 명`} variant="outlined" />
          {Object.keys(attendees).length !== 0 && (
            <Chip variant="outlined" icon={<CheckIcon />} label={`출석률 ${(attendees.filter((item) => item.attendance)?.length / Object.keys(attendees).length) * 100} %`} />
          )}
          <span className={classes.updateTimestamp}>{record.updated_at && `마지막 업데이트 : ${getDateTime(record.updated_at)}`}</span>
        </div>
        {/* <div>
          <span>{teachableStatus !== '' && `📣 ${teachableStatus} `}</span>
          <input className={classes.teachableCourse} placeholder="코스ID" onChange={handleChangeTeachableId}></input>
          <Button color="secondary" className={classes.teachableButton} variant="contained" onClick={() => handleRegisterTeachable(attendees)}>
            <ExitToAppIcon className={classes.buttonIcon} /> 티쳐블 등록
          </Button>
        </div> */}
      </div>
      <div className={classes.controlRow}>
        <Button
          className={classes.actionButton}
          component={Link}
          to={{
            pathname: '/webinarAttendees/create',
            search: `?webinarId=${id}`,
          }}
        >
          <PersonAddIcon />
          참석자 등록하기
        </Button>
        <Button
          className={classes.actionButton}
          onClick={() =>
            dispatch({
              key: 'webinarAttendeeBulk',
              data: {
                modalComponent: <AttendeeBulkCreateModal webinarId={record.id} refresh={refresh} />,
              },
            })
          }
        >
          <GroupAddIcon />
          참석자 일괄등록하기 (csv)
        </Button>
        <Button className={classes.actionButton} onClick={() => handleClickExport(attendees)}>
          <SaveAltIcon />
          내보내기
        </Button>
      </div>
    </div>
  );
};

// const Empty = (props) => (
//   <>
//     <AttendeesActions {...props} />
//     <Box textAlign="center" m={1}>
//       <Typography variant="h4" paragraph>
//         웨비나 신청자가 없습니다.
//       </Typography>
//     </Box>
//   </>
// );

const WebinarAttendeesList = (props) => {
  const {
    record: { attendees },
  } = props;
  return (
    <>
      <AttendeesActions {...props} />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>이름</TableCell>
              <TableCell>이메일</TableCell>
              <TableCell>휴대폰</TableCell>
              <TableCell>프로그램ID</TableCell>
              <TableCell>주문ID</TableCell>
              <TableCell>결제일시</TableCell>
              <TableCell>멤버십</TableCell>
              <TableCell>직책</TableCell>
              <TableCell>출석여부</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendees.map((row) => (
              <TableRow key={row.pk}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.program_id}</TableCell>
                <TableCell>{row.order_id}</TableCell>
                <TableCell>{row.ordered_at}</TableCell>
                <TableCell>{row.membership_type}</TableCell>
                <TableCell>{row.position}</TableCell>
                <TableCell align="center">{row.attendance ? '✅' : ''}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default WebinarAttendeesList;
