import React from 'react';
import { Edit, SimpleForm, TextInput, TextField, ImageInput, RadioButtonGroupInput, ImageField, BooleanInput, required, TopToolbar, ShowButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { CustomEditor, toolbarOptions } from 'components/CustomEditor';

const PostEditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '50ch',
  },
  richText: {
    '& .ql-editor': {
      minHeight: '700px',
      width: '1000px',
    },
    '& .ra-rich-text-input .ql-editor p': {
      marginBottom: '0px',
    },
    '& .ql-snow .ql-editor h2': {
      fontSize: '28px',
    },
    '& .ql-snow .ql-editor h3': {
      fontSize: '20px',
    },
    '& .ql-snow .ql-editor h4': {
      fontSize: '20px',
    },
    '& .ql-snow .ql-editor h5': {
      fontSize: '1em',
    },
    '& .ql-editor ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    '& .ql-editor li': {
      paddingLeft: '1em',
      textIndent: '-1em',
    },
    '& .ql-editor ul li:before': {
      content: '',
      paddingRight: '5px',
    },
  },
}));

const EditBlog = (props) => {
  const classes = useStyles();

  const blogTypeList = [
    { id: '브랜드 스토리', name: '브랜드 스토리' },
    { id: '가이드', name: '가이드' },
    { id: '인터뷰', name: '인터뷰' },
  ];
  return (
    <Edit mutationMode="pessimistic" title="블로그 편집" actions={<PostEditActions />} {...props}>
      <SimpleForm submitOnEnter={false} redirect="show">
        <TextField className={classes.layout} label="타입" source="type" variant="outlined" />
        <TextInput className={classes.layout} validate={[required()]} label="제목" source="data.title" variant="standard" />
        <TextInput className={classes.layout} validate={[required()]} label="부제" source="data.summary" variant="standard" />
        <RadioButtonGroupInput source="tags[0]" validate={[required()]} label="블로그 타입" choices={blogTypeList} />
        <TextField className={classes.layout} label="작성자" source="data.author.username" variant="outlined" />
        <ImageField className={classes.layout} label="기존 썸네일" source="data.thumbnail"></ImageField>
        <ImageInput label="변경 썸네일" className={classes.layout} source="newThumbnail" variant="standard" parse={(v) => v}>
          <ImageField source="newThumbnail" />
        </ImageInput>
        <div className={classes.richText}>
          <CustomEditor toolbar={toolbarOptions} variant="standard" label="본문" source="data.body" />
        </div>
        <BooleanInput source="is_pinned" label="메인 노출" initialValue={false} />
      </SimpleForm>
    </Edit>
  );
};

export default EditBlog;
