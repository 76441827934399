import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloClient, ApolloLink, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { customMessages } from 'helpers/constants';
import providerContainer from 'graphql/provider/providerContainer';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import koreanMessages from 'ra-language-korean';
import { apiAxios } from 'helpers/axios';
import awsConfig from '../aws-exports';

export const AUTH_KEY = '@accessToken';
export const url = awsConfig.graphqlEndpoint;
const region = awsConfig.region;
const auth = {
  type: awsConfig.authenticationType,
  apiKey: awsConfig.apiKey,
};

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  try {
    return JSON.parse(window.atob(base64));
  } catch (err) {
    console.log('parse error');
  }
  return null;
};

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_KEY);
  const { access } = JSON.parse(token);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${access}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, createAuthLink({ url, region, auth }), createSubscriptionHandshakeLink({ url, region, auth })]),
  // https://github.com/apollographql/apollo-client/issues/1564#issuecomment-357492659
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

export const authProvider = {
  login: ({ username, password }) => {
    if (!username.includes('@heyjoyce.com')) {
      return Promise.reject('Unauthorized');
    }
    return apiAxios({
      method: 'POST',
      uri: '/token/',
      data: {
        email: username,
        password,
      },
    })
      .then((res) => {
        const { status, statusText, data } = res;
        if (status >= 400) {
          throw new Error(statusText);
        }
        const { access } = data;
        localStorage.setItem(
          AUTH_KEY,
          JSON.stringify({
            ...parseJwt(access),
            access,
            username,
          })
        );
      })
      .catch(() => console.log('Failed to login'));
  },
  logout: () => {
    localStorage.removeItem(AUTH_KEY);
    return Promise.resolve();
  },
  checkAuth: () => (localStorage.getItem(AUTH_KEY) ? Promise.resolve() : Promise.reject()),
  checkError: (error) => Promise.resolve(),
  getPermissions: () => {
    const userInfo = JSON.parse(localStorage.getItem(AUTH_KEY));
    return userInfo ? Promise.resolve(userInfo) : Promise.reject();
  },
};

export const dataProvider = {
  getList: async (resource, params) => {
    const provider = providerContainer[resource];
    return await provider.getList(resource, params);
  },
  getOne: async (resource, params) => {
    const provider = providerContainer[resource];
    return await provider.getOne(resource, params);
  },
  create: async (resource, params) => {
    const provider = providerContainer[resource];
    return await provider.create(resource, params);
  },
  update: async (resource, params) => {
    const provider = providerContainer[resource];
    return await provider.update(resource, params);
  },
  delete: async (resource, params) => {
    const provider = providerContainer[resource];
    return await provider.delete(resource, params);
  },
};

export const i18nProvider = polyglotI18nProvider(
  () => ({ ra: { ...koreanMessages.ra, navigation: { ...koreanMessages.ra.navigation, ...customMessages.ra.navigation }, action: { ...koreanMessages.ra.action, ...customMessages.ra.action } } }),
  'ko'
);
