import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, SaveButton, Edit, SimpleForm, TextField, SelectInput, required } from 'react-admin';

const useStyles = makeStyles({
  fieldTitle: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8px',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  fieldContent: {
    padding: '10px 0 8px',
  },
  link: {
    textDecoration: 'none',
    color: '#1cad8c',
    '&:hover': {
      color: '#F04E44',
      cursor: 'pointer',
      transitionProperty: 'color',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'ease-out',
    },
  },
});

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ProductInfo = ({ record }) => {
  const classes = useStyles();

  if (record.merchant_uid.includes('coupon')) {
    const merchantUid = record.merchant_uid.split('_');
    const couponType = merchantUid[1];
    const coupontDict = {
      P1001: {
        name: '성장 패키지',
      },
      P1002: {
        name: '베이직 패키지',
      },
      P1003: {
        name: '부스팅 패키지',
      },
      P1004: {
        name: '베이직 패키지',
      },
      P1005: {
        name: '콘조이스 패키지',
      },
      P1006: {
        name: '새해 다짐 패키지',
      },
    };
    return (
      <div>
        <div className={classes.fieldTitle}>결제 쿠폰패키지</div>
        <div className={classes.fieldContent}>
          <span>{coupontDict[couponType].name}</span>
        </div>
      </div>
    );
  } else {
    const programUrl = `${process.env.REACT_APP_ENV === 'production' ? 'https://heyjoyce.com/program/' : 'http://dev.heyjoyce.com/program/'}${record.participation.program.type}/${
      record.participation.program.id
    }`;
    return (
      <div>
        <div className={classes.fieldTitle}>결제 프로그램 ID</div>
        <div className={classes.fieldContent}>
          <span>{record.participation.program.id}</span>
        </div>
        <div className={classes.fieldTitle}>결제 프로그램</div>
        <div className={classes.fieldContent}>
          <a href={programUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
            {record.participation.program.name}
          </a>
        </div>
      </div>
    );
  }
};

const UserNameLink = ({ record }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.fieldTitle}>이름</div>
      <div className={classes.fieldContent}>
        <a className={classes.link} href={`#/users/${record ? record.user.id : ''}/show`} target="_blank" rel="noopener noreferrer">
          {record.user.username}
        </a>
      </div>
    </div>
  );
};

const EditOrder = (props) => (
  <Edit title="결제상태 변경하기 (콘솔만. 실제 결제 미반영)" {...props}>
    <SimpleForm toolbar={<UserEditToolbar />} submitOnEnter={false}>
      <TextField label="유저 ID" source="user.id" />
      <UserNameLink label="이름" source="user.username" />
      <TextField label="이메일" source="user.email" />
      <TextField label="휴대폰 번호" source="user.phone_number" />
      <TextField label="결제 ID (imp_uid)" source="id" />
      <TextField label="주문번호" source="merchant_uid" />
      <ProductInfo />
      <SelectInput
        validate={[required()]}
        label="결제 상태"
        source="status"
        choices={[
          { id: 'paid', name: '결제 완료' },
          { id: 'cancelled', name: '결제 취소' },
          { id: 'ready', name: '결제 대기' },
        ]}
        variant="outlined"
      />
    </SimpleForm>
  </Edit>
);

export default EditOrder;
