import React, { useEffect, useState } from 'react';
import { Show, TextField, DateField, BooleanField, TabbedShowLayout, Tab, useShowController, SimpleShowLayout } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PushReceiverList from './PushReceiverList';
import { FilterField, getReceiverList } from './filterHelpers';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles({
  labelText: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '15px 0',
    fontSize: '0.75rem',
    fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Arial,sans-serif',
    fontWeight: '400',
    lineHeight: '1',
  },
  smallIcon: {
    fontSize: 'small',
    margin: '0 0 15px 0',
  },
  valueText: {
    fontSize: '1rem',
    color: 'rgb(0,0,0)',
  },
  countContainer: {
    marginBottom: '20px',
  },
  tags: {
    margin: '0 0 20px 0',
  },
  listContainer: {
    width: '350px',
    margin: '0 0 30px 0',
  },
  layoutContainer: {
    width: '100%',
    display: 'flex',
  },
  leftLayout: {
    width: '50%',
  },
  rightLayout: {
    width: '50%',
  },
});

const SendCount = ({ label, record, success, total }) => {
  const classes = useStyles();
  const count = success ? record.success_count : record.failure_count;
  //success ? record.success_count : record.failure_count;
  return (
    <div className={classes.countContainer}>
      <div className={classes.labelText}>{label}</div>
      <span className={classes.valueText}>{count !== null ? count : <RemoveIcon className={classes.smallIcon} />}</span>
      <span>{total ? ` (${((count / total) * 100).toFixed(2)}%)` : ''}</span>
    </div>
  );
};

const TotalCount = ({ label, total }) => {
  const classes = useStyles();
  return (
    <div className={classes.countContainer}>
      <div className={classes.labelText}>{label}</div>
      <span className={classes.valueText}>{total ? total : <RemoveIcon className={classes.smallIcon} />}</span>
    </div>
  );
};

const PostShow = (props) => {
  const [receiverList, setReceiverList] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();

  const {
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);

  useEffect(() => {
    async function fetchData(inOut, range, pushStartAt, isStaff) {
      const { data } = await getReceiverList(inOut, range, pushStartAt, isStaff);
      setReceiverList(data);
    }
    if (!loading) {
      if (record.success_count !== null && record.failure_count !== null) {
        setTotalCount(record.success_count + record.failure_count);
      }

      fetchData(record.in_out, record.range, record.start_at, record.is_staff);
    }
  }, [loading]);

  return (
    <>
      <Show {...props}>
        <TabbedShowLayout syncWithLocation={false}>
          <Tab label="기본 정보">
            <TextField label="ID" source="id" />
            <DateField label="생성일시" source="created_at" />
            <TextField label="제목" source="title" />
            <TextField label="내용" source="content" />
            <TextField label="랜딩 URL" source="landing_url" />
            <DateField label="실행일시" showTime source="start_at" />

            <TextField label="필터" />
            <FilterField className={classes.tags} />
          </Tab>
          <Tab label="푸시 정보">
            <SimpleShowLayout className={classes.layoutContainer}>
              <SimpleShowLayout className={classes.leftLayout}>
                <BooleanField label="취소여부" source="canceled" />
                <TotalCount label="전송시도" total={totalCount} />
                <SendCount label="전송성공" source="success_count" success={true} sortable={false} total={totalCount} />
                <SendCount label="전송실패" source="failure_count" success={false} sortable={false} total={totalCount} />
              </SimpleShowLayout>
              <SimpleShowLayout className={classes.rightLayout}>
                <TextField label="수신자 리스트" />
                <div className={classes.listContainer}>
                  <PushReceiverList receivers={receiverList} />
                </div>
              </SimpleShowLayout>
            </SimpleShowLayout>
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export default PostShow;
