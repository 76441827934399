import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  listTitle: {
    padding: '0 0 5px 10px',
    fontWeight: '700',
  },
  listContainer: {
    maxHeight: '250px',
  },
  listDecription: {
    padding: '0 0 15px 10px',
    fontSize: '12px',
  },
}));

const PushReceiverList = ({ receivers }) => {
  const classes = useStyles();
  return (
    <div>
      {receivers && (
        <>
          <div className={classes.listTitle}>총 예상 수신자 : {receivers.count}명</div>
          <div className={classes.listDecription}>
            현재 시점을 기준으로 조건에 해당하는 유저의 리스트를 불러옵니다. <br />
            (추후 조회 시 푸시 생성 시점과 데이터가 달라질 수 있습니다.) <br />
            *B2B 남성 유저는 자동으로 제외 됩니다.
          </div>
          <Paper>
            <TableContainer className={classes.listContainer}>
              <Table stickyHeader aria-label="a dense table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>이름</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {receivers.results &&
                    receivers.results.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.username}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </div>
  );
};

export default PushReceiverList;
