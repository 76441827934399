import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-admin';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper } from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { gql } from '@apollo/client';
import { listCommunities } from 'graphql/queries';
import { client } from 'graphql/providers';

const useStyles = makeStyles({
  topToolBar: {
    margin: '10px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  filterSelect: {
    width: '150px',
    height: '45px',
    marginRight: '10px',
    padding: '10px',
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    fontSize: '1rem',
    '&:focus': {
      outline: 'none',
    },
  },
  filterInput: {
    width: '150px',
    height: '24px',
    marginRight: '10px',
    padding: '10px',
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    fontSize: '1rem',
    '&:focus': {
      outline: 'none',
    },
  },
  filterSearch: {
    width: '350px',
    height: '24px',
    marginRight: '10px',
    padding: '10px',
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    fontSize: '1rem',
    '&:focus': {
      outline: 'none',
    },
  },
  controlRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    color: '#F04E44',
  },
  headerCell: {
    color: 'white',
    backgroundColor: '#3a3a3a',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  column: {
    maxWidth: '300px',
    textAlign: 'center',
  },
  columnLeft: {
    maxWidth: '300px',
    textAlign: 'left',
  },
  columnbodyMaxWidth: {
    display: 'block',
    maxWidth: '300px',
  },
  statusDelete: {
    padding: '2px 6px',
    borderRadius: '15px',
    color: 'black',
    backgroundColor: '#FFE1E3',
    fontSize: '13px',
    wordBreak: 'keep-all',
  },
  link: {
    textDecoration: 'none',
    color: '#1cad8c',
    '&:hover': {
      color: '#F04E44',
      cursor: 'pointer',
      transitionProperty: 'color',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'ease-out',
    },
  },
  edit: {
    color: '#F04E44',
    textDecorationLine: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  pagination: {
    alignSelf: 'center',
  },
});

const ROOT_TYPE = { program: '프로그램' };
const COMMUNITY_TYPE = {
  0: '댓글',
  1: '답글',
};

const CommunityPagination = ({ nextToken, setNextToken, page, setPage }) => {
  const classes = useStyles();

  return (
    <div className={classes.pagination}>
      {nextToken && (
        <Button
          color="primary"
          key="next"
          onClick={() => {
            setNextToken(nextToken);
            setPage(++page);
          }}
        >
          더 보기
          <ExpandMoreIcon />
        </Button>
      )}
    </div>
  );
};

const CommunityDetailLink = ({ communityId, communityBody }) => {
  const classes = useStyles();

  return (
    <a className={classes.link} href={`#/communities/${communityId}/show`}>
      {communityBody}
    </a>
  );
};

const CommunityEditLink = ({ communityId }) => {
  const classes = useStyles();

  return (
    <a className={classes.edit} href={`#/communities/${communityId}`}>
      <EditIcon />
      수정
    </a>
  );
};

const ListCommunity = () => {
  const classes = useStyles();

  const [communities, setCommunities] = useState([]);
  const [nextToken, setNextToken] = useState('');
  const [filterRootType, setFilterRootType] = useState('program');
  const [filterRootId, setFilterRootId] = useState('');
  const [filterKeyword, setFilterKeyword] = useState('');
  const [filterIsPinned, setFilterIsPinned] = useState(false);
  const [page, setPage] = useState(0);

  const fetchData = async () => {
    const { data } = await client.query({
      query: gql`
        ${listCommunities}
      `,
      variables: {
        filter: {
          root_type: filterRootType,
          ...(filterRootId ? { root_id: parseInt(filterRootId) } : {}),
          ...(filterIsPinned ? { is_pinned: true } : {}),
          ...(filterKeyword ? { keyword: filterKeyword } : {}),
        },
        ...(filterRootId || filterIsPinned || filterKeyword ? {} : { limit: 10 }),
        ...(filterRootId || filterIsPinned || filterKeyword ? {} : { nextToken }),
      },
    });
    return data;
  };

  const useDebouncedEffect = (func, delay, deps) => {
    const callback = useCallback(func, deps);

    useEffect(() => {
      const timer = setTimeout(() => {
        callback();
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }, [callback, delay]);
  };

  useEffect(() => {
    fetchData().then((data) => {
      if (page === 0) {
        setCommunities(
          data.listCommunities.items.map((item) => ({
            ...item,
            root_type: item.root.split('#')[0],
            root_id: item.root.split('#')[1],
            is_deleted: item.deleted_at ? true : false,
          }))
        );
      } else {
        setCommunities(
          communities.concat(
            data.listCommunities.items.map((item) => ({
              ...item,
              root_type: item.root.split('#')[0],
              root_id: item.root.split('#')[1],
              is_deleted: item.deleted_at ? true : false,
            }))
          )
        );
      }
      setNextToken(data.listCommunities.nextToken);
    });
  }, [page]);

  useDebouncedEffect(
    () =>
      fetchData().then((data) => {
        setCommunities(
          data.listCommunities.items.map((item) => ({
            ...item,
            root_type: item.root.split('#')[0],
            root_id: item.root.split('#')[1],
            is_deleted: item.deleted_at ? true : false,
          }))
        );
        setNextToken(data.listCommunities.nextToken);
      }),
    200,
    [filterRootType, filterRootId, filterKeyword, filterIsPinned]
  );

  const handleChangeRootType = (event) => {
    setFilterRootType(event.target[event.target.selectedIndex].value);
    setNextToken('');
  };

  return (
    <>
      <div className={classes.topToolBar}>
        <div>
          <select className={classes.filterSelect} value={filterRootType} onChange={handleChangeRootType}>
            <option value="program">프로그램</option>
          </select>
          <input className={classes.filterInput} type="text" placeholder="🔎 최상위 id로 검색" onChange={(e) => setFilterRootId(e.target.value)} />
          <input className={classes.filterSearch} type="text" placeholder="🔎 댓글 내용으로 검색" onChange={(e) => setFilterKeyword(e.target.value)} />
          <input type="checkbox" onChange={(e) => setFilterIsPinned(e.target.checked)} /> {'상단 고정 댓글만'}
        </div>
        <div className={classes.controlRow}>
          <Button
            className={classes.actionButton}
            component={Link}
            to={{
              pathname: '/communities/create',
            }}
          >
            <AddIcon />
            커뮤니티 생성
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>분류</TableCell>
              <TableCell className={classes.headerCell}>최상위 타입</TableCell>
              <TableCell className={classes.headerCell}>최상위 id</TableCell>
              <TableCell className={classes.headerCell}>작성자 id</TableCell>
              <TableCell className={classes.headerCell}>작성자 닉네임</TableCell>
              <TableCell className={classes.headerCell}>내용</TableCell>
              <TableCell className={classes.headerCell}>작성일</TableCell>
              <TableCell className={classes.headerCell}>상단 고정 여부</TableCell>
              <TableCell className={classes.headerCell}>삭제 여부</TableCell>
              <TableCell className={classes.headerCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {communities.map((row) => (
              <TableRow key={row.id}>
                <TableCell className={classes.column}>{COMMUNITY_TYPE[row.depth]}</TableCell>
                <TableCell className={classes.column}>{ROOT_TYPE[row.root_type]}</TableCell>
                <TableCell className={classes.column}>{row.root_id}</TableCell>
                <TableCell className={classes.column}>{row.user_id}</TableCell>
                <TableCell className={classes.column}>{row.data.author.nickname}</TableCell>
                <TableCell className={classes.columnLeft}>
                  <CommunityDetailLink communityId={row.id} communityBody={row.data.body}></CommunityDetailLink>
                </TableCell>
                <TableCell className={classes.column}>{new Date(row.created_at).toLocaleString()}</TableCell>
                <TableCell className={classes.column}>{row.is_pinned ? '📌' : ''}</TableCell>
                <TableCell className={classes.column}>{row.is_deleted ? <span className={classes.statusDelete}>삭제됨</span> : ''}</TableCell>
                <TableCell className={classes.column}>
                  <CommunityEditLink communityId={row.id}></CommunityEditLink>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {filterRootId || filterKeyword || filterIsPinned ? '' : <CommunityPagination nextToken={nextToken} setNextToken={setNextToken} page={page} setPage={setPage} />}
    </>
  );
};

export default ListCommunity;
