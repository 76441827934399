import React from 'react';
import { Create, SimpleForm, TextInput, DateTimeInput, SelectInput, required, ImageField, ArrayInput, SimpleFormIterator, ImageInput, AutocompleteArrayInput, BooleanInput } from 'react-admin';
import { CustomEditor } from 'components/CustomEditor';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, gql } from '@apollo/client';
import { listPrograms } from 'graphql/queries';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '50ch',
  },
}));

const CreateWebinar = (props) => {
  const classes = useStyles();
  const { loading: eventLoading, data: eventData } = useQuery(
    gql`
      ${listPrograms}
    `,
    {
      variables: {
        limit: 100,
        filter: {
          type: 'event',
        },
      },
    }
  );
  const { loading: diconLoading, data: diconData } = useQuery(
    gql`
      ${listPrograms}
    `,
    {
      variables: {
        limit: 100,
        filter: {
          type: 'digitalcontents',
        },
      },
    }
  );

  if (eventLoading || diconLoading) return <p>Loading ...</p>;

  const programs = [...eventData.listPrograms.items, ...diconData.listPrograms.items];
  const optionRenderer = (choice) => `${choice.id} ${choice.name}`;

  return (
    <Create title="Webinar 생성하기" {...props}>
      <SimpleForm submitOnEnter={false} initialValues={{ programs }} warnWhenUnsavedChanges>
        <TextInput className={classes.layout} validate={[required()]} label="웨비나 제목" source="notice.title" variant="standard" options={{ multiline: true }} />
        <TextInput className={classes.layout} label="웨비나 설명" source="notice.subtitle" variant="standard" options={{ multiline: true }} />
        <BooleanInput className={classes.layout} label="웨비나 신청하지 않아도 시청 가능" source="is_all_user_access" variant="outlined" defaultValue={false} />
        <SelectInput
          className={classes.layout}
          validate={[required()]}
          label="타입"
          source="type"
          choices={[
            { id: 'ivs', name: 'AWS IVS' },
            { id: 'pagecall', name: '플링크 페이지콜' },
          ]}
          variant="outlined"
        />
        <DateTimeInput className={classes.layout} validate={[required()]} label="진행일시" source="scheduled_at" defaultValue={new Date()} variant="outlined" />
        <AutocompleteArrayInput className={classes.layout} validate={[required()]} label="프로그램 리스트" source="program_ids" choices={programs} optionText={optionRenderer} variant="outlined" />
        <Box p="0.5em"></Box>
        <TextInput className={classes.layout} label="프로그램 URL" source="notice.program_url" variant="outlined" helperText="https://heyjoyce.com 뒤의 url만 입력해 주세요 (ex. /program/event/111)" />
        <Box p="0.5em"></Box>
        <TextInput
          className={classes.layout}
          label="사전질문 URL"
          source="notice.prequestion_url"
          variant="outlined"
          helperText="진행일 오전에 알림톡으로 발송됩니다. https://를 포함한 전체 url을 입력해 주세요."
        />
        <Box p="0.5em"></Box>
        <TextInput
          className={classes.layout}
          label="피드백 URL"
          source="notice.feedback_url"
          variant="outlined"
          helperText="웨비나 종료시 알림톡으로 공유됩니다. https://를 포함한 전체 url을 입력해 주세요."
        />
        <Box p="1em"></Box>
        <CustomEditor
          variant="standard"
          label="공지사항&emsp;&emsp;서식없이 붙여넣기 방법 => ctrl+shift+v&emsp;헤이조이스 상담 채널 링크 => https://heyjoyce.channel.io/support-bots/21125"
          source="notice.body"
          toolbar={[
            [{ header: [1, 2, 3, 4, 5, 6, false] }, 'bold', 'italic', 'underline', { align: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }, { background: [] }],
            ['link', 'image'],
          ]}
          helperText="공지할 내용을 입력해주세요"
        />
        <Box p="0.5em"></Box>
        <ArrayInput variant="standard" label="연사 리스트" source="speakers">
          <SimpleFormIterator>
            <ImageInput label="연사 프로필 이미지" source="images" parse={(v) => v}>
              <ImageField source="profile_url" />
            </ImageInput>
            <TextInput className={classes.layout} variant="outlined" label="강연 주제" source="title" />
            <TextInput className={classes.layout} variant="outlined" label="연사 정보" source="subtitle" />
            <CustomEditor
              label="세부 정보"
              source="body"
              toolbar={[
                [{ header: [1, 2, 3, 4, 5, 6, false] }, 'bold', 'italic', 'underline', { align: [] }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ color: [] }, { background: [] }],
                ['link', 'image'],
              ]}
              helperText="연사 세부 정보를 입력해주세요"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default CreateWebinar;
