import React from 'react';
import { Create, SimpleForm, SelectInput, TextInput, BooleanInput, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
}));

const rootType = [{ id: 'program', name: '프로그램' }];

const CreateCommunity = (props) => {
  const classes = useStyles();

  return (
    <Create title="커뮤니티(댓글) 작성" {...props}>
      <SimpleForm submitOnEnter={false} redirect="list">
        <SelectInput validate={[required()]} label="최상위 타입" source="root_type" choices={rootType} defaultValue="program" />
        <TextInput validate={[required()]} label="최상위 id" source="root_id" />
        <TextInput validate={[required()]} label="내용" source="data.body" multiline fullWidth />
        <BooleanInput
          className={classes.fullWidth}
          label="상단 고정"
          source="is_pinned"
          initialValue={true}
          helperText="상단 고정을 해제하면 작성자 정보와 함께 커뮤니티 영역에 노출됩니다. (작성자: 어드민 콘솔에 로그인한 계정)"
        />
      </SimpleForm>
    </Create>
  );
};

export default CreateCommunity;
