import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  SelectInput,
  Pagination,
  DateField,
  ShowButton,
  Filter,
  TextInput,
  downloadCSV,
  useListContext,
  sanitizeListRestProps,
  TopToolbar,
  ExportButton,
  BooleanInput,
  CreateButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import jsonExport from 'jsonexport/dist';

const useStyles = makeStyles({
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
  },
  editButton: {
    fontWeight: 'bold',
  },
  layout: {
    width: '300px',
  },
  showButton: {
    fontWeight: 'bold',
  },
  filterLayout: {
    width: '150px',
  },
});

const UserPagination = (props) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const UserMemoPanel = ({ record }) => (
  <div>
    <b>✏️</b> {record.memo}
  </div>
);

const StatusField = ({ record }) => {
  if (!record.is_active) {
    if (record.data?.withdrawal_at) return <span style={{ color: 'red' }}>탈퇴</span>;
    else if (record.data?.sleep_at) return <span style={{ color: 'blue' }}>휴면</span>;
    else return <span style={{ color: 'gray' }}>이상</span>;
  } else return <span style={{ color: 'green' }}>활성</span>;
};

const StageField = ({ record }) => {
  if (record.stage === 20) return <span style={{ color: 'red' }}>펠로우</span>;
  else if (record.stage === 30) return <span style={{ color: 'red' }}>인스파이러</span>;
  else if (record.stage === 5) return <span style={{ color: 'blue' }}>B2B남성</span>;
  else return <span style={{ color: 'green' }}>일반</span>;
};

const UserFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SelectInput
        className={classes.filterLayout}
        label="🔎 유저 상태 필터"
        source="stage"
        alwaysOn
        choices={[
          { id: 0, name: '프로필 없는 유저' },
          { id: 5, name: 'B2B남성' },
          { id: 10, name: '멤버' },
          { id: 20, name: '펠로우' },
          { id: 30, name: '인스파이러' },
        ]}
      />
      <TextInput label="🔎 id/이름/이메일/닉네임으로 검색" source="search" alwaysOn className={classes.layout} />
      <TextInput label="📱 또는 번호로 검색" source="search_by_phone" alwaysOn />
      <BooleanInput label="비활성 계정(탈퇴/휴면)만 보기" source="is_inactive" alwaysOn />
    </Filter>
  );
};

const ShowUserButton = (props) => {
  const classes = useStyles();
  return <ShowButton className={classes.showButton} {...props} />;
};

const ListActions = (props) => {
  const { className, filters, maxResults, ...rest } = props;
  const { currentSort, resource, displayedFilters, filterValues, hasCreate, basePath, selectedIds, showFilter, total } = useListContext();

  const exporter = (users) => {
    const usersForExport = users.map((user) => {
      const { id, email, username, phone_number, company, job, position, industry, marketing, address, detail_address, postcode, date_joined, provider, data } = user;
      return {
        id,
        email,
        phone_number,
        username,
        company,
        job,
        position,
        industry,
        marketing,
        address,
        detail_address,
        postcode,
        date_joined,
        provider,
        withdrawal_at: data?.withdrawal_at,
      };
    });
    jsonExport(usersForExport, (err, csv) => {
      downloadCSV(csv, 'users'); // download as 'posts.csv` file
    });
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
      {/* <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={4000} exporter={exporter} /> */}
    </TopToolbar>
  );
};

const ListUser = (props) => {
  const classes = useStyles();
  return (
    <List {...props} pagination={<UserPagination />} bulkActionButtons={false} filters={<UserFilter />} actions={<ListActions />}>
      <Datagrid expand={<UserMemoPanel />} classes={{ headerCell: classes.headerCell }}>
        <ShowUserButton label="" />
        <StatusField label="상태" source="is_active" sortable={false} />
        <TextField label="ID" source="id" sortable={false} />
        <TextField label="이름" source="username" sortable={false} />
        <TextField label="닉네임" source="nickname" sortable={false} />
        <TextField label="이메일" source="email" sortable={false} />
        <TextField label="휴대번호" source="phone_number" sortable={false} />
        {/* <DateField label="가입일자" source="date_joined" sortable={false} showTime /> */}
        <TextField label="가입방식" source="provider" sortable={false} />
        {/* <TextField label="회사명" source="company" sortable={false} />
        <TextField label="직무" source="position" sortable={false} /> */}
        <StageField label="등급" source="stage" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default ListUser;
