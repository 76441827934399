import React, { useState, useEffect, useCallback } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Link } from 'react-admin';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';
import { gql } from '@apollo/client';
import { client } from 'graphql/providers';
import { listWebinars } from 'graphql/queries';

const useStyles = makeStyles({
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
    textAlign: 'center',
  },
  topToolBar: {
    margin: '10px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  filterInput: {
    border: 'none',
    borderBottom: '1px solid gray',
    fontSize: '18px',
    padding: '10px',
    width: '350px',
    '&:focus': {
      outline: 'none',
    },
  },
  controlRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    color: '#F04E44',
  },
  editButton: {
    fontWeight: 'bold',
  },
  filterLayout: {
    width: '300px',
  },
  link: {
    color: '#1cad8c',
    textDecorationLine: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  edit: {
    color: '#F04E44',
    textDecorationLine: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  pagination: {
    alignSelf: 'center',
  },
});

const WebinarPagination = ({ nextToken, setNextToken, page, setPage }) => {
  const classes = useStyles();

  return (
    <div className={classes.pagination}>
      {nextToken !== null && (
        <Button
          color="primary"
          key="next"
          onClick={() => {
            setNextToken(nextToken);
            setPage(++page);
          }}
        >
          더보기 (+10)
          <ExpandMoreIcon />
        </Button>
      )}
    </div>
  );
};

const WebinarIdLink = ({ webinarId, webinarTitle }) => {
  const classes = useStyles();

  return (
    <a className={classes.link} href={`#/webinars/${webinarId}/show`}>
      {webinarTitle}
    </a>
  );
};

const WebinarEditLink = ({ webinarId }) => {
  const classes = useStyles();

  return (
    <a className={classes.edit} href={`#/webinars/${webinarId}`}>
      <EditIcon />
      수정
    </a>
  );
};

const ListWebinar = (props) => {
  const classes = useStyles();
  const [webinars, setWebinars] = useState([]);
  const [nextToken, setNextToken] = useState('');
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(0);

  async function fetchData() {
    let variables = {};

    if (filter) {
      variables['filter'] = { name: filter };
    }
    variables['nextToken'] = nextToken;
    variables['limit'] = 10;

    const { data } = await client.query({
      query: gql`
        ${listWebinars}
      `,
      variables,
    });

    return data;
  }

  const useDebouncedEffect = (func, delay, deps) => {
    const callback = useCallback(func, deps);

    useEffect(() => {
      const timer = setTimeout(() => {
        callback();
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }, [callback, delay]);
  };

  useDebouncedEffect(
    () =>
      fetchData().then((data) => {
        if (page === 0) {
          setWebinars(data.listWebinars.items);
        } else {
          setWebinars(webinars.concat(data.listWebinars.items));
        }
        setNextToken(data.listWebinars.nextToken);
      }),
    150,
    [page, filter]
  );

  const handleChange = (e) => {
    setPage(0);
    setNextToken('');
    setFilter(e.target.value);
  };

  return (
    <>
      <div className={classes.topToolBar}>
        <div>
          <input className={classes.filterInput} type="text" value={filter} placeholder="🔎 웨비나 제목으로 검색" onChange={handleChange} />
          <Button>
            <HighlightOffIcon
              onClick={() => {
                setFilter('');
                setPage(0);
                setNextToken('');
              }}
            />
          </Button>
        </div>
        <div className={classes.controlRow}>
          <Button
            className={classes.actionButton}
            component={Link}
            to={{
              pathname: '/webinars/create',
            }}
          >
            <AddIcon />
            웨비나 생성
          </Button>
        </div>
      </div>{' '}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>제목</TableCell>
              <TableCell className={classes.headerCell}>전체공개</TableCell>
              <TableCell className={classes.headerCell}>생성일시</TableCell>
              <TableCell className={classes.headerCell}>시작일시</TableCell>
              <TableCell className={classes.headerCell}>수정일시</TableCell>
              <TableCell className={classes.headerCell}>종료🔴</TableCell>
              <TableCell className={classes.headerCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {webinars.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <WebinarIdLink webinarId={row.id} webinarTitle={row.notice.title}></WebinarIdLink>
                </TableCell>
                <TableCell>{row?.is_all_user_access ? '⭕️' : '❌'}</TableCell>
                <TableCell>{row.created_at}</TableCell>
                <TableCell>{row.scheduled_at}</TableCell>
                <TableCell>{row.updated_at}</TableCell>
                <TableCell align="center">{row.is_terminated ? '🔴' : '🔵'}</TableCell>
                <TableCell>
                  <WebinarEditLink webinarId={row.id}></WebinarEditLink>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <WebinarPagination nextToken={nextToken} setNextToken={setNextToken} page={page} setPage={setPage} />
    </>
  );
};

export default ListWebinar;
