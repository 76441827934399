import React from 'react';
import { Show, ShowController, ShowView, SimpleShowLayout, ImageField, SelectField, TextField, DateField, BooleanField, NumberField, ChipField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  bannerImage: {
    '& img': {
      maxWidth: '50ch',
    },
  },
  fieldTitle: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8px',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

const locationRange = [
  { id: 'MAIN_TOP', name: '메인 상단' },
  { id: 'MAIN_BOTTOM', name: '메인 하단' },
];

const DetailBanner = (props) => {
  const classes = useStyles();
  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <ShowView {...props} {...controllerProps}>
          <SimpleShowLayout>
            <TextField source="id" />
            <SelectField label="배너 타입" source="banner_type" choices={locationRange} />
            <TextField label="배너 설명 (관리자 식별용)" source="description" />
            <ImageField label="배너 이미지" className={classes.bannerImage} source="image_url" />
            <ImageField label="모바일 배너 이미지" className={classes.bannerImage} source="m_image_url" />
            {controllerProps.record && controllerProps.record.banner_type === 'MAIN_TOP' && <ChipField label="태그" source="tag" />}
            <TextField label="타이틀 텍스트 영역" source="title" component="pre" />
            <TextField label="서브타이틀 텍스트 영역" source="subtitle" component="pre" />
            <TextField label="랜딩 URL" source="link" />
            <DateField label="노출 시작 일시" source="start_date" />
            <DateField label="노출 종료 일시" source="end_date" />
            <NumberField label="노출 가중치(클수록 먼저 노출)" source="priority" />
            <div className={classes.fieldTitle}>노출 대상</div>
            <BooleanField label="비로그인" source="target_guest" />
            <BooleanField label="로그인" source="target_associate" />
            <BooleanField label="Test" source="test" />
          </SimpleShowLayout>
        </ShowView>
      )}
    </ShowController>
  );
};

export default DetailBanner;
