import React from 'react';
import { Create, SimpleForm, TextInput, DateTimeInput, SimpleFormIterator, AutocompleteArrayInput, NumberInput, required, ArrayInput, useNotify, useRefresh, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '50ch',
  },
  table: {
    border: '1px solid #444444',
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid #444444',
    padding: '6px 6px',
  },
  td: {
    border: '1px solid #444444',
    padding: '4px 6px',
  },
  notice: {
    color: 'red',
  },
  participationTitle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    marginTop: '25px',
    paddingBottom: '8px',
  },
}));

const choices = [
  { name: '콘조이스', id: 'conjoyce' },
  { name: 'VOD', id: 'digitalcontents' },
  { name: '헤이조이스파티', id: 'heyjoyceParty' },
];
const optionRenderer = (choice) => `${choice.name}`;

const CouponTypeExampleTable = ({ record }) => {
  const classes = useStyles();

  return (
    <>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.th}>쿠폰 타입</th>
            <th className={classes.th}>값</th>
            <th className={classes.th}>값 예시</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.td}>가격 일부 할인</td>
            <td className={classes.td}>discount_가격</td>
            <td className={classes.td}>discount_20000 (2만원 할인)</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td className={classes.td}>가격 상관 없이 무조건 0원 결제</td>
            <td className={classes.td}>freepass</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const CreateSerializedCoupon = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('생성 완료!');
    redirect('/serializedCoupons');
    refresh();
  };

  return (
    <Create title="난수-쿠폰 생성" {...props} onSuccess={onSuccess}>
      <SimpleForm submitOnEnter={false}>
        <Typography variant="h6" className={classes.participationTitle}>
          🔢 난수 생성하기
        </Typography>
        <TextInput className={classes.layout} validate={[required()]} label="라벨 (난수 발행 목적입니다. 난수들을 구분할 목적으로 사용됩니다.)" source="label" />
        <NumberInput className={classes.layout} validate={[required()]} label="수량 (생성할 난수의 갯수입니다.)" source="amount" />
        <Typography variant="h6" className={classes.participationTitle}>
          🎟 쿠폰 조합 만들기
        </Typography>
        <CouponTypeExampleTable />
        <ArrayInput variant="standard" label="추가 버튼을 눌러 쿠폰을 추가 할 수 있습니다." source="components">
          <SimpleFormIterator>
            <TextInput className={classes.layout} variant="outlined" label="쿠폰 타입 (할인 타입을 결정합니다. 위 표를 참고하여 작성해주세요.)" source="coupon.type" />
            <TextInput className={classes.layout} variant="outlined" label="쿠폰 이름 (마이페이지, 알림톡 등에 노출 되는 쿠폰의 이름입니다.)" source="coupon.coupon_name" />
            <DateTimeInput className={classes.layout} variant="outlined" label="소멸일시" source="coupon.expire_at" parse={(v) => new Date(v).toISOString()} />
            <TextInput className={classes.layout} variant="outlined" label="쿠폰 장수" source="amount" />
            <span>쿠폰 사용 상품 제한하기</span>
            <span>특정 상품(콘조이스, VOD)에만 사용 가능한 쿠폰을 만들기. 선택하지 않으면 모든 상품에 사용 가능한 기본 쿠폰으로 생성됩니다.</span>
            <AutocompleteArrayInput className={classes.arrayInput} label="상품 복수 선택" source="coupon.tags" choices={choices} optionText={optionRenderer} defaultValue={null} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default CreateSerializedCoupon;
