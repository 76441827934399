import { client } from 'graphql/providers';
import { gql } from '@apollo/client';
import { searchFeeds, listFeeds, getFeed } from 'graphql/queries';
import { createFeed as createFeedMutation, updateFeed as updateFeedMutation, deleteFeed as deleteFeedMutation } from 'graphql/mutations';

const DEFAULT_TYPE = 'qna';

const feedProvider = {
  getList: async (resource, params) => {
    const { filter, pagination } = params;
    if (filter.search) {
      const { data } = await client.query({
        query: gql`
          ${searchFeeds}
        `,
        variables: {
          filter: {
            type: filter.type || DEFAULT_TYPE,
            keyword: filter.search,
          },
          limit: pagination.perPage,
          page: pagination.page,
        },
      });

      return {
        data: data.searchFeeds.items,
        total: data.searchFeeds.total,
      };
    }

    const { data } = await client.query({
      query: gql`
        ${listFeeds}
      `,
      variables: {
        filter: {
          type: filter.type || DEFAULT_TYPE,
          is_pinned: filter.is_pinned || false,
        },
        limit: pagination.perPage,
        page: pagination.page,
      },
    });

    return {
      data: data.listFeeds.items,
      total: data.listFeeds.total,
    };
  },
  getOne: async (resource, params) => {
    const { id } = params;
    const { data } = await client.query({
      query: gql`
        ${getFeed}
      `,
      variables: {
        id,
      },
    });
    return {
      data: data.getFeed,
    };
  },
  create: async (resource, params) => {
    const { type, user_id, category_id, is_pinned, data } = params.data;
    const {
      data: { createFeed },
    } = await client.mutate({
      mutation: gql`
        ${createFeedMutation}
      `,
      variables: {
        input: {
          type,
          user_id: parseInt(user_id),
          category_id,
          is_pinned,
          data,
        },
      },
    });
    return {
      data: createFeed,
    };
  },
  update: async (resource, params) => {
    const { id, is_pinned, data } = params.data;

    const {
      data: { updateFeed },
    } = await client.mutate({
      mutation: gql`
        ${updateFeedMutation}
      `,
      variables: {
        input: {
          id,
          is_pinned,
          data: {
            title: data.title,
            body: data.body,
          },
        },
      },
    });

    return {
      data: updateFeed,
    };
  },
  delete: async (resource, params) => {
    const { id } = params;

    const {
      data: { deleteFeed },
    } = await client.mutate({
      mutation: gql`
        ${deleteFeedMutation}
      `,
      variables: {
        input: {
          id,
        },
      },
    });

    return {
      data: deleteFeed,
    };
  },
};

export default feedProvider;
