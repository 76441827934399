import React from 'react';
import { useModalContext } from 'hooks/useModalContext';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

const Wrapper = styled.div`
  background: white;
  border-radius: 4px;
  min-width: 400px;
  min-height: 150px;
  padding: 16px;
  position: relative;

  .top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      margin: 0px;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: -0.02em;
      line-height: 150%;
      word-break: keep-all;
      white-space: pre-wrap;
    }
  }

  .rightButton {
    position: absolute;
    right: 12px;
    top: 14px;
    padding: 0px;
    border: none;
    background: none;

    svg {
      font-size: 26px;
      color: gray;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .middle {
    margin-top: 12px;
    .text {
      margin: 0px;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: -0.02em;
      line-height: 150%;
      word-break: keep-all;
      white-space: pre-line;
    }
  }
  .bottom {
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    .button,
    .button-secondary {
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 4px;
      width: 100%;
      height: 48px;
      font-weight: 700;
      font-size: 14px;
      color: '#1a1a1a';
      .icon {
        margin-right: 16px;
      }
    }
    .button {
      border: none;
      background-color: #fddb00;
    }
    .button-secondary {
      background-color: #ffffff;
      border: 1px solid #dfdfdf;
    }
  }
`;

const ModalCloseButton = () => {
  const [, , hide] = useModalContext();
  return (
    <button className="rightButton" onClick={() => hide()}>
      <CloseIcon />
    </button>
  );
};

const CommonModal = ({ children }) => <Wrapper>{children}</Wrapper>;

export { CommonModal, ModalCloseButton };
