import { adminAxios } from 'helpers/axios';

const couponProviders = {
  getOne: async (resource, params) => {
    const { data } = await adminAxios({
      method: 'GET',
      uri: `/coupons/${params.id}/`,
    });
    return {
      data: data,
    };
  },
  create: async (resource, params) => {
    const { user_id } = params.data;

    const { data } = await adminAxios({
      method: 'POST',
      uri: '/coupons/',
      query: '',
      data: { ...params.data, user: user_id },
    });
    return {
      data,
    };
  },
  update: async (resource, params) => {
    const { id } = params.data;
    const { data } = await adminAxios({
      method: 'PATCH',
      uri: `/coupons/${id}/`,
      data: { ...params.data },
    });
    return {
      data: data,
    };
  },
  delete: async (resource, params) => {
    const { data } = await adminAxios({
      method: 'DELETE',
      uri: '/coupons/',
      query: params.id + '/',
    });
    return {
      data,
    };
  },
};

export default couponProviders;
