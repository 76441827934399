import React from 'react';
import { TopToolbar, Toolbar, EditButton, DeleteWithConfirmButton, Show, SimpleForm, Datagrid, SelectField, DateField, ArrayField, TextField, BooleanField, FormDataConsumer } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { APPSETTING_GROUP, APPSETTING_TYPE, NAV_PATHNAME } from 'helpers/constants';

const useStyles = makeStyles({
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
    textAlign: 'center',
  },
});

const CustomEditToolbar = (props) => (
  <TopToolbar {...props}>
    <></>
  </TopToolbar>
);

const AppSettingDetailToolbar = (props) => (
  <Toolbar>
    <EditButton {...props} label="수정" submitOnEnter={false} />
    <DeleteWithConfirmButton {...props} confirmContent="🚨 정말로 삭제하시려면 [✔확인]을 눌러주세요." confirmTitle={'설정 삭제'} />
  </Toolbar>
);

const DetailAppSetting = (props) => {
  const classes = useStyles();

  return (
    <Show title="서비스 설정 보기" {...props} actions={<CustomEditToolbar />}>
      <SimpleForm toolbar={<AppSettingDetailToolbar />}>
        <div>
          <h3>설정 분류</h3>
        </div>
        <SelectField label="대분류" source="group" choices={APPSETTING_GROUP} defaultValue={APPSETTING_GROUP[0].id} />
        <SelectField label="분류" source="type" choices={APPSETTING_TYPE} />
        <div>
          <h3>노출 기간</h3>
        </div>
        <DateField label="노출 시작 일시" source="start_at" showTime={true} />
        <DateField label="노출 종료 일시" source="end_at" showTime={true} />
        <DateField label="생성 일시" source="created_at" showTime={true} />
        <div>
          <h3>내비게이션 바 설정</h3>
        </div>
        <FormDataConsumer>
          {({ formData }) =>
            formData &&
            formData.data.forEach((paths) => {
              // SelectInput 옵션에 중복으로 추가되지 않도록 항목 중복 검사
              if (paths && !NAV_PATHNAME.find((item) => item.name === paths.path)) {
                NAV_PATHNAME.push({ id: paths.path, name: paths.path });
              }
            })
          }
        </FormDataConsumer>
        <ArrayField source="data" label="설정">
          <Datagrid classes={{ headerCell: classes.headerCell }}>
            <TextField label="메뉴 이름" source="name" />
            <SelectField label="연결 페이지" source="path" choices={NAV_PATHNAME} />
            <BooleanField label="점 찍기" source="is_highlighted" />
          </Datagrid>
        </ArrayField>
        <DateField label="설정 생성일" source="created_at" showTime={true} />
      </SimpleForm>
    </Show>
  );
};

export default DetailAppSetting;
