import React from 'react';
import { List, Datagrid, EditButton, BooleanInput, TextField, NumberField, DateField, ChipField, DeleteWithConfirmButton, RadioButtonGroupInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import styled from 'styled-components';

import Quote from '../../images/quote_icon.png';
import { youtubeParser } from '../../helpers/index.js';

const useStyles = makeStyles({
  headerCell: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3a3a3a',
  },
});

const Content = styled.div`
  height: auto;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.8 !important;

  & > *:first-child {
    margin-top: 1em;
  }

  * {
    letter-spacing: -0.2px;
    word-spacing: 0.5px;
    margin: 0;
    word-break: break-all;
  }

  h2 {
    font-size: 28px;
    margin: 1em 0;
  }

  h3,
  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 1em;
  }

  img,
  figure img {
    max-width: 100%;
    margin: 16px auto;
    display: block;
    border-radius: 4px;
  }

  a {
    color: #2a3384;
  }
  a:hover {
    cursor: pointer;
  }
  a:after {
    font-size: small;
    content: '🔗';
    padding-left: 2px;
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 12px 15px;
    box-sizing: border-box;
  }

  li {
    padding-left: 1.5em;
    text-indent: -1.5em;
    margin-bottom: 5px;
  }

  li:before {
    content: '✔️';
    padding-right: 8px;
  }

  blockquote {
    position: relative;
    margin-top: 60px;
    text-align: center;
    color: #424242;
    padding: 0px 10px;
  }

  blockquote:before {
    content: '';
    position: absolute;
    background: url(${Quote}); /*url of image*/
    left: calc(50% - 16px);
    top: -42px;
    font-size: 100px;
    width: 32px;
    height: 28px;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-center {
    text-align: center;
  }

  width: 100%;

  .ql-video {
    width: 420px;
    max-width: 90%;
    height: 260px;
  }
  .ql-video.ql-align-center {
    display: flex;
    margin: 0 auto;
  }
  .ql-video.ql-align-right {
    display: flex;
    margin-left: auto;
  }
`;

const StageField = ({ record }) => (record.opened ? <span>⭕️</span> : null);

const postFilters = [
  <BooleanInput label="홈페이지 노출" source="opened" alwaysOn />,
  <RadioButtonGroupInput
    label="타입"
    source="type"
    choices={[
      { id: '', name: '없음' },
      { id: '전체', name: '전체' },
      { id: 'event', name: '이벤트' },
      { id: 'digitalcontents', name: 'VOD' },
      { id: 'moim', name: '모임' },
      { id: 'member', name: '멤버' },
    ]}
    alwaysOn
  />,
];

const EditField = (props) => (
  <>
    <EditButton {...props} />
    <DeleteWithConfirmButton {...props} confirmContent="🚨 정말로 삭제하시려면 [✔확인]을 눌러주세요." confirmTitle={`"${props.record.question}" 삭제`} />
  </>
);

const AnswerPanel = ({ record }) => (
  <Content>
    <div dangerouslySetInnerHTML={{ __html: youtubeParser(record.answer) }} />
  </Content>
);

const ListFAQ = (props) => {
  const classes = useStyles();

  return (
    <List {...props} title="FAQ 리스트" bulkActionButtons={false} filters={postFilters} filterDefaultValues={{ type: '' }} exporter={false}>
      <Datagrid classes={{ headerCell: classes.headerCell }} rowClick={false} expand={<AnswerPanel />}>
        <NumberField lable="ID" source="id" sortable={false} />
        <ChipField label="타입" source="type" sortable={false} />
        <TextField label="질문" source="question" sortable={false} />
        <StageField label="홈페이지 노출" source="opened" sortable={false} />
        <EditField />
      </Datagrid>
    </List>
  );
};

export default ListFAQ;
