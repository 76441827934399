import React from 'react';
import { Edit, SimpleForm, TextInput, DateTimeInput, required, SaveButton, Toolbar, useEditController, DeleteWithConfirmButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '50ch',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  inputRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > span:first-child': {
      width: '130px',
      marginBottom: '10px',
      color: '#484848',
      fontWeight: 'bold',
      justifyContent: 'flex-end',
      textAlign: 'right',
      padding: '20px',
    },
    '& .MuiFormControl-root': {
      minWidth: '250px',
    },
  },
  description: {
    fontSize: '12px',
    margin: '0 0 0 70px',
  },
  exampleRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    marginBottom: '10px',
    '& > span:first-child': {
      color: 'black',
      borderRadius: '20px',
      padding: '5px 10px',
      fontSize: '0.9em',
      marginRight: '5px',
    },
    '& ul': {
      margin: '0',
      padding: '0',
      display: 'flex',
      listStyle: 'none',
    },
    '& li': {
      margin: '0',
      padding: '0',
      marginRight: '10px',
      padding: '5px 10px',
      boxSizing: 'border-box',
      borderRadius: '20px',
      backgroundColor: '#ebebeb',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:active': {
        backgroundColor: '#fddb005e',
      },
    },
    '& li:after': {
      content: ',',
    },
  },
}));

const CustomToolbar = (props) => {
  const redirect = `/users/${props.record.user}/show/couponList`;
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton redirect={redirect} />
      <DeleteWithConfirmButton redirect={redirect} confirmContent="🚨 정말로 삭제하시려면 [✔확인]을 눌러주세요." confirmTitle={`"${props.record.coupon_name}" 삭제`} />
    </Toolbar>
  );
};

const EditCoupon = (props) => {
  const classes = useStyles();

  const controllerProps = useEditController(props);
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = controllerProps;

  const copyText = (e) => {
    const copiedText = e.target.innerText;
    const tempElem = document.createElement('textarea');
    tempElem.value = copiedText;
    document.body.appendChild(tempElem);
    tempElem.select();
    document.execCommand('copy');
    document.body.removeChild(tempElem);
  };

  return (
    <Edit title="쿠폰 수정하기" {...props}>
      <SimpleForm toolbar={<CustomToolbar />} redirect={() => `/users/${record.user}/show/couponList`}>
        <div className={classes.inputRow}>
          <span>유저 아이디</span>
          <TextInput source="user" label=" " validate={[required()]} variant="standard" disabled={true} />
        </div>
        <div className={classes.inputRow}>
          <span>쿠폰 타입</span>
          <TextInput source="type" label=" " validate={[required()]} variant="standard" />
        </div>
        <div className={classes.inputRow}>
          <span>쿠폰 이름</span>
          <TextInput source="coupon_name" label=" " validate={[required()]} variant="standard" />
          <div className={classes.exampleRow}>
            <span>( 📋 클릭해서 복사 )</span>
            <ul>
              <li onClick={copyText}>친구 초대 3천원 쿠폰</li>
              <li onClick={copyText}>헤이조이스 가입 환영 5천원 쿠폰</li>
            </ul>
          </div>
        </div>
        <div className={classes.inputRow}>
          <span>쿠폰 생성일시</span>
          <DateTimeInput source="created_at" label=" " validate={[required()]} variant="standard" />
        </div>
        <div className={classes.inputRow}>
          <span>쿠폰 만료일시</span>
          <DateTimeInput source="expire_at" label=" " validate={[required()]} variant="standard" />
        </div>
        <span className={classes.description}>* 환불 시에는 쿠폰 만료일시를 1년전으로 설정해주세요.</span>
        <span className={classes.description}>** 쿠폰 삭제는 쿠폰을 잘못 생성한 경우에만 사용해주세요.</span>
      </SimpleForm>
    </Edit>
  );
};

export default EditCoupon;
