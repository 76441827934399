import React from 'react';
import { Edit, SimpleForm, TextInput, required, ImageInput, ImageField, BooleanInput } from 'react-admin';

const EditInterview = (props) => (
  <Edit title="Interview 수정" {...props}>
    <SimpleForm submitOnEnter={false}>
      <ImageField label="기존 썸네일" source="thumbnail_img_url" />
      <ImageInput label="새로운 썸네일" source="new_thumbnail_img_url">
        <ImageField />
      </ImageInput>
      <TextInput label="제목" source="subject" validate={[required()]} />
      <TextInput label="요약" source="summary" validate={[required()]} />
      <TextInput label="링크" source="link" />
      <BooleanInput label="홈페이지 노출" source="opened" />
    </SimpleForm>
  </Edit>
);

export default EditInterview;
