import { client } from 'graphql/providers';
import { gql } from '@apollo/client';
import { adminAxios } from 'helpers/axios';
import { listFeeds, getFeed } from 'graphql/queries';
import { updateFeed as updateFeedMutation, deleteFeed as deleteFeedMutation } from 'graphql/mutations';

const DEFAULT_TYPE = 'apply';

const applyProvider = {
  getList: async (resource, params) => {
    const { filter, pagination } = params;

    const { data } = await client.query({
      query: gql`
        ${listFeeds}
      `,
      variables: {
        filter: {
          type: DEFAULT_TYPE,
          ...(filter.status && { status: filter.status }),
        },
        limit: pagination.perPage,
        page: pagination.page,
      },
    });

    return {
      data: data.listFeeds.items,
      total: data.listFeeds.total,
    };
  },
  getOne: async (resource, params) => {
    const { id } = params;
    const { data } = await client.query({
      query: gql`
        ${getFeed}
      `,
      variables: {
        id,
      },
    });
    return {
      data: data.getFeed,
    };
  },
  update: async (resource, params) => {
    const { previousData } = params;
    const { id, data } = params.data;
    const { body, status, author } = data;
    const {
      data: { updateFeed },
    } = await client.mutate({
      mutation: gql`
        ${updateFeedMutation}
      `,
      variables: {
        input: {
          id,
          data: {
            body,
            status,
          },
        },
      },
    });

    return {
      data: updateFeed,
    };
  },
  delete: async (resource, params) => {
    const { id } = params;

    const {
      data: { deleteFeed },
    } = await client.mutate({
      mutation: gql`
        ${deleteFeedMutation}
      `,
      variables: {
        input: {
          id,
        },
      },
    });

    return {
      data: deleteFeed,
    };
  },
};

export default applyProvider;
