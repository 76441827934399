import React from 'react';
import { Show, SimpleForm, Toolbar, TextField, SelectField, BooleanField, DateField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  section: {
    width: '100%',
    margin: '20px 0',
    border: '1px solid #cdcdcd',
  },
}));

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <></>
  </Toolbar>
);

const rootType = [{ id: 'program', name: '프로그램' }];
const communityType = [
  { id: 0, name: '댓글' },
  { id: 1, name: '답글' },
];

const DetailCommunity = (props) => {
  const classes = useStyles();

  return (
    <Show {...props}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextField label="id" source="id" />
        <SelectField label="분류" source="depth" choices={communityType} />
        <div className={classes.section}></div>
        <SelectField label="최상위 타입" source="root_type" choices={rootType} />
        <TextField label="최상위 id" source="root_id" />
        <div className={classes.section}></div>
        <TextField label="작성자 id" source="data.author.id" />
        <TextField label="작성자" source="data.author.nickname" />
        <TextField label="내용" source="data.body" />
        <div className={classes.section}></div>
        <DateField label="작성일" source="created_at" showTime={true} />
        <DateField label="최종 수정일" source="updated_at" showTime={true} />
        <DateField label="삭제일" source="deleted_at" showTime={true} />
        <div className={classes.section}></div>
        <BooleanField label="상단 고정" source="is_pinned" sortable={false} />
        <BooleanField label="삭제 여부" source="is_deleted" />
      </SimpleForm>
    </Show>
  );
};

export default DetailCommunity;
