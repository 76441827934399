import React, { useState } from 'react';
import { Show, SimpleForm, TextField, DateField, EditButton, useRefresh, Toolbar, BooleanInput, FormDataConsumer, NumberField } from 'react-admin';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { payMethodDict, payStatusDict } from 'helpers';
import { apiAxios } from 'helpers/axios';

const useStyles = makeStyles({
  fieldTitle: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8px',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  fieldContent: {
    padding: '10px 0 8px',
  },
  link: {
    textDecoration: 'none',
    color: '#1cad8c',
    '&:hover': {
      color: '#F04E44',
      cursor: 'pointer',
      transitionProperty: 'color',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'ease-out',
    },
  },
  statusChip: {
    padding: '2px 6px',
    borderRadius: '4px',
    width: 'max-content',
    color: 'white',
  },
  inputBox: {
    margin: '0 6px 24px 0',
    lineHeight: '2.25',
  },
  editButton: {
    display: 'inline',
  },
  receiptButton: {
    marginTop: '8px',
    backgroundColor: '#FDDB00',
    color: '#000000',
    fontWeight: 'bold',
  },
  cancelButton: {
    backgroundColor: '#F04E44',
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
});

const ProductInfo = ({ record }) => {
  const classes = useStyles();
  const listPrice = (record.participation ? record.participation.program.associate_member_price : record.price).toLocaleString('ko-KR', { style: 'currency', currency: 'KRW' });

  if (record.merchant_uid.includes('coupon')) {
    const merchantUid = record.merchant_uid.split('_');
    const couponType = merchantUid[1];
    const coupontDict = {
      P1001: {
        name: '성장 패키지',
      },
      P1002: {
        name: '베이직 패키지',
      },
      P1003: {
        name: '부스팅 패키지',
      },
      P1004: {
        name: '베이직 패키지',
      },
      P1005: {
        name: '콘조이스 패키지',
      },
      P1006: {
        name: '새해 다짐 패키지',
      },
    };
    return (
      <div>
        <div className={classes.fieldTitle}>결제 쿠폰패키지</div>
        <div className={classes.fieldContent}>
          <span>{coupontDict[couponType].name}</span>
        </div>
        <div className={classes.fieldTitle}>판매가</div>
        <div className={classes.fieldContent}>
          <span>{listPrice}</span>
        </div>
      </div>
    );
  } else {
    const programUrl = `${process.env.REACT_APP_ENV === 'production' ? 'https://heyjoyce.com/program/' : 'http://dev.heyjoyce.com/program/'}${
      record.participation ? record.participation.program.type : ''
    }/${record.participation ? record.participation.program.id : ''}`;
    return (
      <div>
        <div className={classes.fieldTitle}>결제 프로그램 ID</div>
        <div className={classes.fieldContent}>
          <span>{record.participation.program.id}</span>
        </div>
        <div className={classes.fieldTitle}>결제 프로그램</div>
        <div className={classes.fieldContent}>
          <a href={programUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
            {record.participation.program.name}
          </a>
        </div>
        <div className={classes.fieldTitle}>판매가</div>
        <div className={classes.fieldContent}>
          <span>{listPrice}</span>
        </div>
      </div>
    );
  }
};

const OrderStatusField = ({ record }) => {
  const classes = useStyles();
  if (!record.status) {
    return <div />;
  }

  let { name, color } = payStatusDict[record.status];

  if (record.refund_price && record.price > record.refund_price && record.status === 'cancelled') {
    name = '부분 취소';
    color = '#E69D00';
  }

  return (
    <div>
      <div className={classes.fieldTitle}>결제상태</div>
      <div className={classes.fieldContent}>
        <span className={classes.statusChip} style={{ backgroundColor: color, color: 'white' }}>
          {name}
        </span>
      </div>
    </div>
  );
};

const PaymentMethodField = ({ record }) => {
  const classes = useStyles();
  if (!record) {
    return <div />;
  }
  const value = record.method;
  return (
    <div>
      <div className={classes.fieldTitle}>결제수단</div>
      <div className={classes.fieldContent}>
        <span>{payMethodDict[value].name}</span>
      </div>
    </div>
  );
};

const CancelOrder = ({ record }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const defaultRefundPrice = record.refund_price ? record.price - record.refund_price : record.price;
  const [refundPrice, setRefundPrice] = useState(defaultRefundPrice);
  const [refundReason, setRefundReason] = useState('관리자 취소');

  const handleChangeRefundPrice = (e) => setRefundPrice(parseInt(e.target.value));
  const handleChangeRefundReason = (e) => setRefundReason(e.target.value);

  const handleClickVbankReadyCancel = async () => {
    const cancelConfirm = window.confirm('취소하시겠습니까?');
    if (cancelConfirm) {
      try {
        const res = await apiAxios({
          method: 'POST',
          uri: '/admin_cancel/',
          data: {
            reason: refundReason,
            imp_uid: record.imp_uid,
            amount: 0,
            method: record.method,
            status: record.status,
          },
        });
        if (res.status === 200) {
          refresh();
        }
      } catch (e) {
        window.alert(`결제 취소 중 에러가 발생했습니다. - ${e}`);
      }
    }
  };

  const handleClickPaymentCancel = async () => {
    if (record.refund_price ? refundPrice > record.price - record.refund_price : refundPrice > record.price) {
      window.alert('취소할 금액이 실결제금액보다 큽니다. 문제가 계속 발생하면 새로고침 후 다시 시도해주세요.');
    } else if (!refundPrice) {
      window.alert('취소할 금액을 입력하세요.');
    } else {
      const cancelConfirm = window.confirm(`${record.price === refundPrice ? '전액' : '부분'}취소하시겠습니까? (취소 금액: ${refundPrice}원)`);
      if (cancelConfirm) {
        try {
          const res = await apiAxios({
            method: 'POST',
            uri: '/admin_cancel/',
            data: {
              reason: refundReason,
              imp_uid: record.imp_uid,
              amount: refundPrice,
              method: record.method,
              status: record.status,
            },
          });
          if (res.status === 200) {
            refresh();
          }
        } catch (e) {
          window.alert(`결제 취소 중 에러가 발생했습니다. - ${e}`);
        }
      }
    }
  };

  const handleClickNotImpCancel = async () => {
    const cancelConfirm = window.confirm('취소하시겠습니까?');
    if (cancelConfirm) {
      try {
        const res = await apiAxios({
          method: 'POST',
          uri: `/participation/${record.participation.id}/cancel`,
          data: {
            participation_id: record.participation.id,
            user_id: record.user.id,
            reason: refundReason,
            is_console: true,
          },
        });
        if (res.status === 200) {
          refresh();
        }
      } catch (e) {
        window.alert(`결제 취소 중 에러가 발생했습니다. - ${e}`);
      }
    }
  };

  return (
    <div>
      {/* 입금 전인 가상계좌 결제건 */}
      <div hidden={record.method !== 'vbank' || record.participation.program.associate_member_price === 0 || record.status !== 'ready'}>
        <BooleanInput className={classes.fieldContent} source="showinput" label="실제 결제상태 변경 (콘솔 & 아임포트 반영)" />
        <FormDataConsumer>
          {({ formData }) =>
            formData.showinput && (
              <div>
                <p>* 프로그램(이벤트. VOD) 결제건 중 쿠폰을 사용한 결제건을 취소하면 사용한 쿠폰은 자동으로 복구됩니다.</p>
                <p>* 프로그램 자체는 유료지만 쿠폰을 사용해서 실결제금액이 0원인 결제건은 아임포트 내역에 없고 콘솔에서만 확인할 수 있습니다.</p>
                <input className={classes.inputBox} placeholder="취소 사유 입력(선택)" onChange={handleChangeRefundReason}></input>
                <Button className={classes.cancelButton} onClick={handleClickVbankReadyCancel}>
                  취소하기
                </Button>
              </div>
            )
          }
        </FormDataConsumer>
      </div>
      {/* 신용카드, 카카오페이 결제건 */}
      <div hidden={record.method === 'notimp' || record.method === 'vbank' || record.method === 'trans' ? true : record.price === record.refund_price ? true : false}>
        <BooleanInput className={classes.fieldContent} source="showinput" label="실제 결제상태 변경 (콘솔 & 아임포트 반영)" />
        <FormDataConsumer>
          {({ formData }) =>
            formData.showinput && (
              <div>
                <p>* 쿠폰패키지(성장패키지, 콘조이스패키지) 결제취소 후 쿠폰 만료처리는 수동으로 해야 합니다.</p>
                <p>* 프로그램(이벤트. VOD) 결제건 중 쿠폰을 사용한 결제건을 취소하면 사용한 쿠폰은 자동으로 복구됩니다.</p>
                <p>* 프로그램 자체는 유료지만 쿠폰을 사용해서 실결제금액이 0원인 결제건은 아임포트 내역에 없고 콘솔에서만 확인할 수 있습니다.</p>
                <div className={classes.fieldContent}>유저에게 환불해줄 금액을 입력해주세요.</div>
                <input className={classes.inputBox} placeholder="취소 사유 입력(선택)" onChange={handleChangeRefundReason}></input>
                <input className={classes.inputBox} placeholder="유저에게 환불할 금액 입력" defaultValue={defaultRefundPrice} onChange={handleChangeRefundPrice}></input>
                <Button className={classes.cancelButton} onClick={handleClickPaymentCancel}>
                  취소하기
                </Button>
              </div>
            )
          }
        </FormDataConsumer>
      </div>
      {/* 유료 프로그램 0원 결제건 */}
      <div hidden={!(record.method === 'notimp' && record.participation.program.associate_member_price !== 0 && record.status === 'paid')}>
        <BooleanInput className={classes.fieldContent} source="showinput" label="실제 결제상태 변경" />
        <FormDataConsumer>
          {({ formData }) =>
            formData.showinput && (
              <div>
                <p>* 쿠폰패키지(성장패키지, 콘조이스패키지) 결제취소 후 쿠폰 만료처리는 수동으로 해야 합니다.</p>
                <p>* 프로그램(이벤트. VOD) 결제건 중 쿠폰을 사용한 결제건을 취소하면 사용한 쿠폰은 자동으로 복구됩니다.</p>
                <p>* 프로그램 자체는 유료지만 쿠폰을 사용해서 실결제금액이 0원인 결제건은 아임포트 내역에 없고 콘솔에서만 확인할 수 있습니다.</p>
                <input className={classes.inputBox} placeholder="취소 사유 입력(선택)" onChange={handleChangeRefundReason}></input>
                <Button className={classes.cancelButton} onClick={handleClickNotImpCancel}>
                  취소하기
                </Button>
              </div>
            )
          }
        </FormDataConsumer>
      </div>
    </div>
  );
};

const PaymentReceiptButton = ({ record }) => {
  const classes = useStyles();
  const handleClickPaymentReceipt = async () => {
    const { data: paymentData } = await apiAxios({
      method: 'GET',
      uri: `/payment_info/${record.imp_uid}`,
    });
    window.open(paymentData.data.receipt_url);
  };

  return (
    <div hidden={record.method === 'notimp' || record.method === 'vbank' || record.method === 'trans'}>
      <div>
        <Button className={classes.receiptButton} onClick={handleClickPaymentReceipt}>
          매출전표 조회하기
        </Button>
      </div>
    </div>
  );
};

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <></>
  </Toolbar>
);

const UserNameLink = ({ record }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.fieldTitle}>이름</div>
      <div className={classes.fieldContent}>
        <a className={classes.link} href={`#/users/${record ? record.user.id : ''}/show`} target="_blank" rel="noopener noreferrer">
          {record.user.username}
        </a>
      </div>
    </div>
  );
};

const DetailOrder = (props) => {
  const classes = useStyles();
  return (
    <Show actions={null} {...props}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextField label="유저 ID" source="user.id" />
        <UserNameLink label="이름" source="user.username" />
        <TextField label="이메일" source="user.email" />
        <TextField label="휴대폰 번호" source="user.phone_number" />
        <TextField label="결제 ID (imp_uid)" source="id" />
        <TextField label="주문번호" source="merchant_uid" />
        <ProductInfo />
        <FormDataConsumer>
          {({ formData }) =>
            formData && formData.method === 'vbank' && formData.status === 'ready' ? (
              <>
                <div className={classes.fieldTitle}>실결제금액</div>
                <div className={classes.fieldContent}>
                  <span>₩0</span>
                </div>
              </>
            ) : (
              <>
                <div className={classes.fieldTitle}>실결제금액</div>
                <div className={classes.fieldContent}>
                  <NumberField label="실결제금액" source="price" options={{ style: 'currency', currency: 'KRW' }} />
                </div>
              </>
            )
          }
        </FormDataConsumer>
        <NumberField label="환불금액" source="refund_price" options={{ style: 'currency', currency: 'KRW' }} />
        <PaymentMethodField label="결제수단" source="method" />
        <TextField label="사용한 쿠폰 ID" source="participation.used_coupon" />
        <DateField label="결제 일시" showTime={true} source="created_at" />
        <DateField label="취소 일시" showTime={true} source="cancelled_at" />
        <OrderStatusField className={classes.orderStatusChip} label="결제상태" source="status" />
        <EditButton className={classes.editButton} label="결제상태 변경 (콘솔만. 실제 결제 미반영)" />
        <CancelOrder />
        <PaymentReceiptButton />
      </SimpleForm>
    </Show>
  );
};

export default DetailOrder;
