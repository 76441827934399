import React, { useState, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DoneIcon from '@material-ui/icons/Done';
import styled from 'styled-components';

const StyeldInput = styled.input`
  box-sizing: border-box;
  margin: 2px 4px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;

  min-width: 0;
  padding: 4px 11px;
  color: #000000d9;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`;

const AddWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  font-size: 14px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const JsonListComponent = (props) => {
  const { jsonData = '', setJsonData } = props;
  const [jsonList, setJsonList] = useState(Object.entries(jsonData ? JSON.parse(jsonData) : {}));

  useEffect(() => {
    setJsonData(JSON.stringify(Object.fromEntries(jsonList)));
  }, [jsonList]);

  useEffect(() => {
    setJsonList(Object.entries(jsonData ? JSON.parse(jsonData) : {}));
  }, [jsonData]);

  const handleAddClick = () => {
    setJsonList([...jsonList, ['', '']]);
  };
  const handleDeleteClick = (index) => {
    setJsonList(jsonList.filter((item, i) => index !== i));
  };

  return (
    <div>
      <AddWrapper onClick={handleAddClick}>
        <span style={{ color: '#ff5946' }}>옵션 추가</span>
        <AddCircleOutlineIcon color="primary" style={{ padding: '8px' }} />
      </AddWrapper>

      {jsonList.map((item, index) => (
        <FormWrapper key={index}>
          <DoneIcon fontSize="small" />
          <StyeldInput
            value={item[0]}
            onChange={(e) => {
              setJsonList(jsonList.map((item, i) => (i === index ? [e.target.value, item[1]] : item)));
            }}
          />
          <StyeldInput
            value={item[1]}
            onChange={(e) => {
              setJsonList(jsonList.map((item, i) => (i === index ? [item[0], e.target.value] : item)));
            }}
          />
          <DeleteIcon color="primary" style={{ padding: '8px' }} onClick={() => handleDeleteClick(index)} />
        </FormWrapper>
      ))}
    </div>
  );
};

export default JsonListComponent;
