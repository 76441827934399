/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWebinar = /* GraphQL */ `
  mutation CreateWebinar($input: CreateWebinarInput!) {
    createWebinar(input: $input) {
      id
      type
      notice {
        title
        subtitle
        body
        program_name
        program_url
      }
      scheduled_at
      is_all_user_access
      is_terminated
      speakers {
        profile_url
        title
        subtitle
        body
      }
      programs {
        id
        type
        name
        original_price
        discount_price
        register_start_at
        register_end_at
        register_available
      }
      url
      sendbird {
        channel_url
        name
        participant_count
        custom_type
      }
      updated_at
      created_at
    }
  }
`;
export const updateWebinar = /* GraphQL */ `
  mutation UpdateWebinar($input: UpdateWebinarInput!) {
    updateWebinar(input: $input) {
      id
      type
      notice {
        title
        subtitle
        body
        program_name
        program_url
        prequestion_url
      }
      scheduled_at
      is_all_user_access
      speakers {
        profile_url
        title
        subtitle
        body
      }
      programs {
        id
        type
        name
        original_price
        discount_price
        register_start_at
        register_end_at
        register_available
      }
      updated_at
    }
  }
`;

export const createWebinarAttendeeMutation = /* Graphql */ `
  mutation CreateWebinarAttendee($input: CreateWebinarAttendeeInput!) {
    createWebinarAttendee(input: $input) {
      id,
      username,
      email,
      phone,
      updated_at,
      created_at,
      position,
      membership_type,
      program_id,
      order_id,
      ordered_at,
      attendance,
      push_token
    }
  }
`;

export const deleteWebinar = /* GraphQL */ `
  mutation DeleteWebinar($input: DeleteWebinarInput!) {
    deleteWebinar(input: $input) {
      id
    }
  }
`;
export const invokeOperatorEvent = /* GraphQL */ `
  mutation InvokeOperatorEvent($payload: String!) {
    invokeOperatorEvent(payload: $payload) {
      id
    }
  }
`;
export const createFeed = /* GraphQL */ `
  mutation CreateFeed($input: CreateFeedInput!) {
    createFeed(input: $input) {
      id
    }
  }
`;
export const updateFeed = /* GraphQL */ `
  mutation UpdateFeed($input: UpdateFeedInput!) {
    updateFeed(input: $input) {
      id
      data {
        status
      }
    }
  }
`;
export const deleteFeed = /* GraphQL */ `
  mutation DeleteFeed($input: DeleteFeedInput!) {
    deleteFeed(input: $input) {
      id
    }
  }
`;
export const createPromotion = /* GraphQL */ `
  mutation CreatePromotion($input: CreatePromotionInput!) {
    createPromotion(input: $input) {
      code
    }
  }
`;

export const updatePromotion = /* GraphQL */ `
  mutation UpdatePromotion($input: UpdatePromotionInput!) {
    updatePromotion(input: $input) {
      code
    }
  }
`;

export const createSerializedCoupon = /* GraphQL */ `
  mutation CreateSerializedCoupon($input: SerializedCouponInput!) {
    createSerializedCoupon(input: $input) {
      label
    }
  }
`;

export const createCommunity = /* GraphQL */ `
  mutation CreateCommunity($input: CreateCommunityInput!) {
    createCommunity(input: $input) {
      id
      created_at
      updated_at
      data {
        author {
          id
          profile_url
          nickname
        }
        body
        reply_count
      }
      is_pinned
    }
  }
`;

export const updateCommunity = /* GraphQL */ `
  mutation UpdateCommunity($input: UpdateCommunityInput!) {
    updateCommunity(input: $input) {
      id
      created_at
      updated_at
      deleted_at
      data {
        author {
          id
          profile_url
          nickname
        }
        body
        reply_count
      }
    }
  }
`;

export const createB2B = /* GraphQL */ `
  mutation CreateB2B($input: B2BInput!) {
    createB2B(input: $input) {
      id
      name
      code
      url
      created_at
    }
  }
`;

export const bulkCreateWebinarAttendee = /* GraphQL */ `
  mutation BulkCreateWebinarAttendee($input: BulkCreateWebinarAttendeeInput!) {
    bulkCreateWebinarAttendee(input: $input)
  }
`;

export const createAppSetting = /* GraphQL */ `
  mutation CreateAppSetting($input: CreateAppSettingInput!) {
    createAppSetting(input: $input) {
      id
      group
      type
      start_at
      end_at
      data
    }
  }
`;

export const updateAppSetting = /* GraphQL */ `
  mutation UpdateAppSetting($input: UpdateAppSettingInput!) {
    updateAppSetting(input: $input) {
      id
      start_at
      end_at
      data
    }
  }
`;

export const deleteAppSetting = /* GraphQL */ `
  mutation DeleteAppSetting($input: DeleteAppSettingInput!) {
    deleteAppSetting(input: $input) {
      id
    }
  }
`;
