import { client } from 'graphql/providers';
import { gql } from '@apollo/client';
import { getCommunity } from 'graphql/queries';
import { createCommunity as createCommunityMutation, updateCommunity as updateCommunityMutation } from 'graphql/mutations';

const communityProvider = {
  getOne: async (resource, params) => {
    const { id } = params;
    const { data } = await client.query({
      query: gql`
        ${getCommunity}
      `,
      variables: {
        id,
      },
    });
    return {
      data: {
        ...data.getCommunity,
        root_type: data.getCommunity.root.split('#')[0],
        root_id: data.getCommunity.root.split('#')[1],
        is_deleted: data.getCommunity.deleted_at ? true : null,
      },
    };
  },
  create: async (resource, params) => {
    const { root_type, root_id, data, is_pinned = false } = params.data;
    const { author_id, body } = data;
    const {
      data: { createCommunity },
    } = await client.mutate({
      mutation: gql`
        ${createCommunityMutation}
      `,
      variables: {
        input: {
          root_type,
          root_id,
          data: {
            body,
            author_id: parseInt(author_id),
            ...(root_type === 'program' ? { program_id: parseInt(root_id) } : {}),
          },
          is_pinned,
        },
      },
    });
    return {
      data: createCommunity,
    };
  },
  update: async (resource, params) => {
    const { previousData } = params;
    const { id, is_pinned, data, is_deleted } = params.data;
    const { body } = data;
    const {
      data: { updateCommunity },
    } = await client.mutate({
      mutation: gql`
        ${updateCommunityMutation}
      `,
      variables: {
        input: {
          id,
          is_pinned,
          data: {
            body,
          },
          is_deleted,
        },
      },
    });

    return {
      data: updateCommunity,
    };
  },
};

export default communityProvider;
