import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, required, SaveButton, Toolbar, useEditController } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { payMethodDict } from 'helpers';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '50ch',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  inputRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > span:first-child': {
      width: '130px',
      marginBottom: '10px',
      color: '#484848',
      fontWeight: 'bold',
      justifyContent: 'flex-end',
      textAlign: 'right',
      padding: '20px',
    },
    '& .MuiFormControl-root': {
      minWidth: '250px',
    },
  },
  description: {
    fontSize: '12px',
    margin: '0 0 0 70px',
  },
  exampleRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    marginBottom: '10px',
    '& > span:first-child': {
      color: 'black',
      borderRadius: '20px',
      padding: '5px 10px',
      fontSize: '0.9em',
      marginRight: '5px',
    },
    '& ul': {
      margin: '0',
      padding: '0',
      display: 'flex',
      listStyle: 'none',
    },
    '& li': {
      margin: '0',
      padding: '0',
      marginRight: '10px',
      padding: '5px 10px',
      boxSizing: 'border-box',
      borderRadius: '20px',
      backgroundColor: '#ebebeb',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:active': {
        backgroundColor: '#fddb005e',
      },
    },
    '& li:after': {
      content: ',',
    },
  },
}));

const CustomToolbar = (props) => {
  const redirect = `/users/${props.record.user}/show/participationList`;
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton redirect={redirect} />
    </Toolbar>
  );
};

const PaymentMethodField = ({ record }) => {
  if (!record.order) {
    return <div />;
  }
  const value = record.order.method;
  return (
    <div>
      <span>{payMethodDict[value].name}</span>
    </div>
  );
};

const EditParticipation = (props) => {
  const classes = useStyles();

  const controllerProps = useEditController(props);
  const { record } = controllerProps;

  return (
    <Edit title="수정하기" {...props}>
      <SimpleForm toolbar={<CustomToolbar />} redirect={() => `/users/${record.user}/show/participationList`}>
        <div className={classes.inputRow}>
          <span>유저 아이디</span>
          <TextInput disabled source="user" label=" " validate={[required()]} variant="standard" />
        </div>
        <div className={classes.inputRow}>
          <span>프로그램ID</span>
          <TextInput disabled source="program.id" label=" " validate={[required()]} variant="standard" />
        </div>
        <div className={classes.inputRow}>
          <span>주문번호</span>
          <TextInput disabled source="order.imp_uid" label=" " validate={[required()]} variant="standard" />
        </div>
        <div className={classes.inputRow}>
          <span>상품번호</span>
          <TextInput disabled source="order.merchant_uid" label=" " validate={[required()]} variant="standard" />
        </div>
        <div className={classes.inputRow}>
          <span>결제수단</span>
          <TextInput disabled source="order.method" label=" " validate={[required()]} variant="standard" />
        </div>
        <div className={classes.inputRow}>
          <span>결제상태</span>
          <SelectInput
            validate={[required()]}
            label=" "
            source="order.status"
            choices={[
              { id: 'paid', name: '완료' },
              { id: 'cancelled', name: '취소' },
              { id: 'ready', name: '대기' },
            ]}
            variant="outlined"
          />
        </div>
        <div className={classes.inputRow}>
          <span>결제금액</span>
          <TextInput source="order.price" label=" " validate={[required()]} variant="standard" />
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default EditParticipation;
