import React, { useEffect, useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  ImageInput,
  ImageField,
  DateTimeInput,
  CheckboxGroupInput,
  required,
  maxLength,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  AutocompleteInput,
  useDataProvider,
} from 'react-admin';
import { CustomEditor } from 'components/CustomEditor';
import useAdminApi from 'hooks/useAdminApi';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const AccordionSummary = withStyles({
  root: {
    boxShadow: 'none',
    height: '48px',
    minHeight: '48px',
    borderRadius: '4px',
    '&$expanded': {
      margin: '0',
      height: '48px',
      minHeight: '48px',
      backgroundColor: '#fff9d6',
    },
  },
  content: {
    '&$expanded': {
      margin: '0',
      height: '48px',
      minHeight: '48px',
      alignItems: 'center',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    '& .MuiFormControl-marginNormal': {
      marginTop: '0px',
    },
    '& ul[class^="RaSimpleFormIterator"]': {
      marginTop: '0px',
    },
    '& div[class^="RaAutocompleteArrayInput"]': {
      flexGrow: '0',
    },
    '& .ra-rich-text-input .ql-editor': {
      backgroundColor: 'white',
      border: '1px solid #cdcdcd',
    },
  },
  leftForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    marginRight: '15px',
    padding: '15px',
  },
  rightForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    borderLeft: '1px solid #e5e5e5',
    paddingLeft: '30px',
    padding: '15px',
  },
  formHeader: {
    backgroundColor: '#535353',
    color: ' white',
    borderRadius: '4px',
    padding: '10px 15px',
    marginBottom: '20px',
    marginTop: '15px',
    '&.first': {
      marginTop: '0px',
    },
  },
  formLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    // borderTop: '1px solid #e5e5e5',
    paddingTop: '20px',
    '& > span:nth-child(2)': {
      color: 'gray',
      fontWeight: 'normal',
      marginLeft: '12px',
      fontSize: '14px',
    },
    '&.first': {
      paddingTop: '0px',
      borderTop: 'none',
    },
  },
  tagsWrapper: {
    margin: '20px 0px 0px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > span': {
      marginRight: '10px',
      marginBottom: '8px',
    },
  },
  rowInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > span': {
      margin: '0px 15px',
    },
    '& > *': {
      marginRight: '15px',
    },
  },
  richText: {
    '& .ql-editor': {
      minHeight: '350px',
      maxHeight: '700px',
    },
    '& .ra-rich-text-input .ql-editor p': {
      marginBottom: '0px',
    },
    '& .ql-snow .ql-editor h2': {
      fontSize: '28px',
    },
    '& .ql-snow .ql-editor h3': {
      fontSize: '20px',
    },
    '& .ql-snow .ql-editor h4': {
      fontSize: '20px',
    },
    '& .ql-snow .ql-editor h5': {
      fontSize: '1em',
    },
    '& .ql-editor ul': {
      padding: 0,
      margin: 0,
      listStyle: 'none',
    },
    '& .ql-editor li': {
      paddingLeft: '1em',
      textIndent: '-1em',
    },
    '& .ql-editor ul li:before': {
      content: '',
      paddingRight: '5px',
    },
  },
  layout: {},
  tag: {
    backgroundColor: '#e8e8e8',
    padding: '2px 6px',
    borderRadius: '15px',
    fontSize: '13px',
  },
  divider: {
    widht: '100%',
  },
  boolean: {
    '& > *': {
      wordBreak: 'keep-all',
    },
  },
  desc: {
    color: 'gray',
    fontSize: 14,
    padding: 16,
  },
  editorRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderTop: '1px solid #e5e5e5',
    '& .MuiAccordion-root': {
      width: '100%',
    },
    '& .MuiAccordion-root:before': {
      height: 0,
    },
    '& .MuiAccordion-root.Mui-expanded:last-child': {
      marginTop: 0,
    },
    '& .MuiFormControlLabel-root': {
      marginTop: 6,
      marginRight: 0,
      marginLeft: 0,
    },
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
    '& .MuiSwitch-colorPrimary.Mui-checked': {
      color: '#fddb00',
    },
    '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#fddb00',
    },
  },
}));

const toolbarOptions = [
  [{ header: [2, 3, 4, 5, false] }],
  ['bold', 'italic', 'underline', 'strike', 'link', 'image', 'video'], // toggled buttons
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ align: [] }],
  [
    { color: [false, '#FDDB00', '#F04E44', '#2A3384', '#005950', '#FFE1E3', '#8CE7DA', '#222222', '#444444', '#666666', '#888888', '#aaaaaa', '#cccccc', '#eeeeee'] },
    {
      background: [
        false,
        '#FDDB00',
        '#F04E44',
        '#2A3384',
        '#005950',
        '#FFE1E3',
        '#8CE7DA',
        'black',
        '#fddb0030',
        '#ff524717',
        '#4959ea21',
        '#00bfac30',
        '#ffe9ea7d',
        '#a1fff252',
        '#222222',
        '#444444',
        '#666666',
        '#888888',
        '#aaaaaa',
        '#cccccc',
        '#eeeeee',
      ],
    },
  ],
  ['clean'], // remove formatting button
];

const validateName = [required(), maxLength(60)];
const validateSummary = [maxLength(120)];

const userOptionRenderer = (choice) => (choice ? (choice.id ? `${choice.id}/${choice.username}/${choice.company}/${choice.job}/${choice.email}` : '❌선택안함') : '');

const CreateProgram = (props) => {
  const classes = useStyles();
  const [isUserUnlimited, setIsUserUnlimited] = useState({
    member: false,
    associate_member: false,
  });

  const [defaultDescription, setDefaultDescription] = useState();
  const dataProvider = useDataProvider();

  const { data: programTags } = useAdminApi({
    method: 'GET',
    url: '/programTags/unique/',
  });

  const { data: categories } = useAdminApi({
    method: 'GET',
    url: '/categories/',
  });

  const handleChangeLimit = (value, target) => {
    setIsUserUnlimited({
      ...isUserUnlimited,
      [target]: value,
    });
  };

  useEffect(() => {
    async function fetchData() {
      const record = props.location.state && props.location.state.record ? props.location.state.record : null;

      if (record && record.id) {
        if (!record.description) {
          const { data: participationsForExports } = await dataProvider.getOne('programs', {
            id: record.id,
          });

          setDefaultDescription(participationsForExports.description);
        } else {
          setDefaultDescription(record.description);
        }
      }
    }
    fetchData();
  }, []);

  const validateNestedObjectEditor = (data, key, errorMessageObject) => {
    if (data && Object.prototype.hasOwnProperty.call(data, 'detail') && Object.prototype.hasOwnProperty.call(data.detail, key) && data.detail[key] && data.detail[key].includes('data:image')) {
      const splitDesc = data.detail[key].split('data:image');
      errorMessageObject.data.detail[key] = `${splitDesc.length - 1}개의 rawdata 이미지가 있습니다. 에디터의 이미지 기능을 사용해서 업로드해주세요!`;
    }
  };

  const validateEditorImage = (values) => {
    let errors = {};

    const { description, data } = values;
    const errorMessageObject = {
      description: '',
      data: {
        detail: {
          leader_info: '',
          notice: '',
          review: '',
          sale: '',
        },
      },
    };

    if (description && description.includes('data:image')) {
      const splitDesc = description.split('data:image');
      errorMessageObject.description = `${splitDesc.length - 1}개의 rawdata 이미지가 있습니다. 에디터의 이미지 기능을 사용해서 업로드해주세요!`;
    }

    validateNestedObjectEditor(data, 'leader_info', errorMessageObject);
    validateNestedObjectEditor(data, 'notice', errorMessageObject);
    validateNestedObjectEditor(data, 'review', errorMessageObject);
    validateNestedObjectEditor(data, 'sale', errorMessageObject);

    errors = {
      ...(errorMessageObject.description && { description: errorMessageObject.description }),
      data: {
        detail: {
          ...(errorMessageObject.data.detail.leader_info && { leader_info: errorMessageObject.data.detail.leader_info }),
          ...(errorMessageObject.data.detail.notice && { notice: errorMessageObject.data.detail.notice }),
          ...(errorMessageObject.data.detail.review && { review: errorMessageObject.data.detail.review }),
          ...(errorMessageObject.data.detail.sale && { sale: errorMessageObject.data.detail.sale }),
        },
      },
    };

    return errors;
  };

  return (
    <Create title="프로그램 만들기" {...props}>
      <SimpleForm submitOnEnter={false} validate={validateEditorImage}>
        <div className={classes.formWrapper}>
          <div className={classes.leftForm}>
            <div className={`${classes.formHeader} first`}>프로그램 정보</div>
            <TextInput className={classes.layout} validate={validateName} label="프로그램 제목" source="name" resettable />
            <TextInput className={classes.layout} validate={validateSummary} label="프로그램 요약" source="summary" resettable multiline />
            <div className={classes.editorRow}>
              <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <h3>소개 *</h3>
                </AccordionSummary>
                <AccordionDetails className={classes.richText}>
                  <CustomEditor toolbar={toolbarOptions} label="" source="description" defaultValue={defaultDescription} />
                </AccordionDetails>
              </Accordion>
            </div>
            <div className={classes.desc}>
              아래 정보들은 <br />
              1. 작성된 적이 없거나
              <br />
              2. 스위치가 OFF 상태가 되면
              <br /> 상세페이지에 노출되지 않습니다.
            </div>
            <div className={classes.editorRow}>
              <BooleanInput label="" source="data.detail.activeTab.leader_info" initialValue={true} />
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <h3>연사 정보</h3>
                </AccordionSummary>
                <AccordionDetails className={classes.richText}>
                  <CustomEditor toolbar={toolbarOptions} label="연사 정보" source="data.detail.leader_info" />
                </AccordionDetails>
              </Accordion>
            </div>
            <div className={classes.editorRow}>
              <BooleanInput label="" source="data.detail.activeTab.notice" initialValue={true} />
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <h3>공지사항</h3>
                </AccordionSummary>
                <AccordionDetails className={classes.richText}>
                  <CustomEditor toolbar={toolbarOptions} label="공지사항" source="data.detail.notice" />
                </AccordionDetails>
              </Accordion>
            </div>
            <div className={classes.editorRow}>
              <BooleanInput label="" source="data.detail.activeTab.review" initialValue={true} />
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <h3>이용후기</h3>
                </AccordionSummary>
                <AccordionDetails className={classes.richText}>
                  <CustomEditor toolbar={toolbarOptions} label="후기" source="data.detail.review" />
                </AccordionDetails>
              </Accordion>
            </div>
            <div className={classes.editorRow}>
              <BooleanInput label="" source="data.detail.activeTab.sale" initialValue={true} />
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <h3>할인</h3>
                </AccordionSummary>
                <AccordionDetails className={classes.richText}>
                  <CustomEditor toolbar={toolbarOptions} label="할인" source="data.detail.sale" />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className={classes.rightForm}>
            <div className={`${classes.formHeader} first`}>프로그램 속성</div>
            <div className={`${classes.formLabel} first`}>
              <span>타입 설정 * </span>
              <span>프로그램의 타입 한가지를 설정하세요.</span>
            </div>
            <SelectInput
              className={classes.layout}
              validate={[required()]}
              label=""
              source="type"
              choices={[
                { id: 'event', name: '이벤트' },
                { id: 'moim', name: '모임' },
                { id: 'digitalcontents', name: '디지털콘텐츠' },
              ]}
              defaultValue="event"
            />
            {categories && (
              <>
                <div className={classes.formLabel}>
                  <span>카테고리 설정 *</span>
                  <span>{'(🚨 필수) 프로그램의 카테고리를 선택해주세요.'}</span>
                </div>
                <CheckboxGroupInput label="" source="category_ids" validate={[required()]} choices={categories.results} />
              </>
            )}
            <div className={classes.formLabel}>
              <span>프로그램 썸네일 *</span>
              <span>.jpg, .png 등 이미지 파일을 1개씩만 올려주세요</span>
            </div>
            <ImageInput label="" source="thumbnails" parse={(v) => v} multiple={true} placeholder={<p>썸네일을 선택해주세요</p>} validate={[required()]}>
              <ImageField source="thumbnail" />
            </ImageInput>
            {programTags && (
              <>
                <div className={classes.formLabel}>
                  <span>태그 설정</span>
                </div>
                <ArrayInput source="tags" label="">
                  <SimpleFormIterator>
                    <TextInput source="name" label="태그 이름" />
                  </SimpleFormIterator>
                </ArrayInput>
                <div className={classes.formLabel}>
                  <span>태그 예제</span>
                </div>
                <div className={classes.tagsWrapper}>
                  {programTags.results.map((tag, index) => (
                    <span key={index} className={classes.tag}>{`${tag}`}</span>
                  ))}
                </div>
              </>
            )}
            <div className={classes.formHeader}>추가 정보 설정</div>
            <div className={`${classes.formLabel} first`}>
              <span>티쳐블 코스 ID</span>
              <span>다시보기 영상 제공을 위한 teachable ID를 입력하세요.</span>
            </div>
            <NumberInput source="course_id" label="코스 ID" />
            <div className={`${classes.formLabel}`}>
              <span>마케팅 영역 정보</span>
              <span>상세페이지 Top Banner 영역에 들어가는 마케팅 영역입니다</span>
            </div>
            <TextInput source="data.marketing.title" validate={[maxLength(50)]} label="타이틀" />
            <TextInput source="data.marketing.subtitle" validate={[maxLength(25)]} label="부제" />
            <div className={`${classes.formLabel}`}>
              <span>할부 개월 수 설정</span>
              <span>프로그램의 가격에 할부 개월을 적용하여 보여줍니다</span>
            </div>
            <NumberInput source="data.marketing.installment" label="개월 수" />
            <div className={`${classes.formLabel}`}>
              <span>다시보기 기간 설정</span>
              <span>다시보기 혜택이 있는 라이브에만 해당하는 항목입니다.</span>
            </div>
            <DateTimeInput source="data.replay.start_at" label="다시보기 시작 일시" fullWidth={true} />
            <span style={{ textAlign: 'center' }}>~</span>
            <DateTimeInput source="data.replay.end_at" label="다시보기 종료 일시" fullWidth={true} />
            <div className={classes.formHeader}>상태 설정</div>
            <div className={`${classes.formLabel} first`}>
              <span>테스트 모드 설정</span>
              <span>
                프로그램 기획중일시 테스트모드 ON<br></br>프로그램 오픈시 테스트모드 OFF
              </span>
            </div>
            <BooleanInput className={classes.boolean} source="test" label="테스트 모드 설정(ON: @heyjoyce.com 계정에게만 노출)" defaultValue={false} />
            <div className={classes.formLabel}>
              <span>신청 가능 설정</span>
            </div>
            <BooleanInput className={classes.boolean} source="register_available" label="신청 가능 설정 (ON: 프로그램 신청 버튼 활성화 & 신청 가능, OFF: 신청 불가)" defaultValue={true} />
            <div className={classes.formLabel}>
              <span>프로그램 활성화 설정</span>
            </div>
            <BooleanInput className={classes.boolean} source="opened" label="프로그램 활성화 설정 (ON: 프로그램 리스트에 노출, OFF: 노출하지 않음)" defaultValue={true} />
            <div className={classes.formLabel}>
              <span>알림톡 설정</span>
              <span>
                이벤트, 모임 관련 알림톡 발송 설정<br></br>(※ 웨비나 관련 알림톡과는 별개)
              </span>
            </div>
            <BooleanInput className={classes.boolean} source="alimtalk" label="알림톡 설정 (ON: 알림톡 발송, OFF: 알림톡 발송하지 않음)" defaultValue={true} />
            <div className={classes.formHeader}>신청 가능 기간 설정</div>
            <DateTimeInput source="register_start_at" label="신청 시작 일시" validate={[required()]} fullWidth={true} />
            <span style={{ textAlign: 'center' }}>~</span>
            <DateTimeInput source="register_end_at" label="신청 마감 일시" validate={[required()]} fullWidth={true} />

            <div className={classes.formHeader}>인원 설정</div>
            <div className={`${classes.formLabel} first`}>
              <span>예비 멤버</span>
              <span>무제한으로 설정 시 졍원은 999999로 설정됩니다</span>
            </div>
            <div className={classes.rowInput}>
              <TextInput type="number" label="멤버 정원" source="associate_member_limit" disabled={isUserUnlimited['associate_member']} fullWidth={true} defaultValue={0} />
              <BooleanInput className={classes.boolean} label="무제한" onChange={(e) => handleChangeLimit(e, 'associate_member')} source="associate_member_no_limit" defaultValue={false} />
            </div>
            <div className={`${classes.formLabel}`}>
              <span>멤버</span>
              <span>예비 멤버와 같은 수를 입력해 주세요</span>
            </div>
            <div className={classes.rowInput}>
              <TextInput type="number" label="멤버 정원" source="member_limit" disabled={isUserUnlimited['member']} fullWidth={true} defaultValue={0} />
              <BooleanInput className={classes.boolean} label="무제한" onChange={(e) => handleChangeLimit(e, 'member')} source="member_no_limit" defaultValue={false} />
            </div>
            <div className={classes.formHeader}>가격 설정</div>

            <div className={classes.rowInput}>
              <TextInput type="number" label="판매가" source="associate_member_price" fullWidth={true} defaultValue={0} />
              <TextInput type="number" label="정가" source="member_price" fullWidth={true} defaultValue={0} />
            </div>
            <div className={classes.formHeader}>미팅 일정</div>
            <ArrayInput source="programmeets" label="">
              <SimpleFormIterator>
                <DateTimeInput source="start_at" label="미팅 시작 일시" />
                <DateTimeInput source="end_at" label="미팅 종료 일시" />
              </SimpleFormIterator>
            </ArrayInput>

            <div className={classes.formHeader}>리더 설정</div>
            <div className={`${classes.formLabel} first`}>
              <span>리더</span>
              <span>연사님(들)이 헤이조이스 멤버일 경우 등록해주세요</span>
            </div>
            <ReferenceArrayInput label="리더" source="leaders" reference="searchUsers" fullWidth={true}>
              <AutocompleteArrayInput optionText={userOptionRenderer} />
            </ReferenceArrayInput>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export default CreateProgram;
