import React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';

export default () => (
  <Card>
    <CardHeader title="안녕하세요 플래너 여러분 🎉" />
    <CardContent>
      {/** ref https://www.slipp.net/questions/402 */}
      {/** <iframe title="" src="http://dashboard.heyjoyce.com:3000/d/Bkqh4vBZk/membership?orgId=1" /> **/}
    </CardContent>
  </Card>
);
