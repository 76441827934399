import React from 'react';
import { Create, SimpleForm, TextInput, RadioButtonGroupInput, ImageInput, AutocompleteInput, ImageField, BooleanInput, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import useAdminApi from 'hooks/useAdminApi';
import { CustomEditor, toolbarOptions } from 'components/CustomEditor';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '50ch',
  },
  richText: {
    '& .ql-editor': {
      minHeight: '700px',
      width: '1000px',
    },
    '& .ra-rich-text-input .ql-editor p': {
      marginBottom: '0px',
    },
    '& .ql-snow .ql-editor h2': {
      fontSize: '28px',
    },
    '& .ql-snow .ql-editor h3': {
      fontSize: '20px',
    },
    '& .ql-snow .ql-editor h4': {
      fontSize: '20px',
    },
    '& .ql-snow .ql-editor h5': {
      fontSize: '1em',
    },
    '& .ql-editor ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    '& .ql-editor li': {
      paddingLeft: '1em',
      textIndent: '-1em',
    },
    '& .ql-editor ul li:before': {
      content: '',
      paddingRight: '5px',
    },
  },
}));

const CreateBlog = (props) => {
  const classes = useStyles();

  const { data: staffs } = useAdminApi({
    method: 'GET',
    url: '/users/?is_staff=true&limit=50&offset=0',
  });

  if (!staffs) {
    return <div />;
  }

  const blogTypeList = [
    { id: '브랜드 스토리', name: '브랜드 스토리' },
    { id: '가이드', name: '가이드' },
    { id: '인터뷰', name: '인터뷰' },
  ];

  return (
    <Create title="블로그 작성" {...props}>
      <SimpleForm submitOnEnter={false}>
        <TextInput className={classes.layout} validate={[required()]} label="타입" source="type" initialValue="blog" variant="outlined" />
        <RadioButtonGroupInput source="tags" validate={[required()]} label="블로그 타입" choices={blogTypeList} />
        <TextInput className={classes.layout} validate={[required()]} label="제목" source="data.title" variant="standard" />
        <TextInput className={classes.layout} validate={[required()]} label="부제" source="data.summary" variant="standard" />
        <AutocompleteInput
          className={classes.layout}
          validate={[required()]}
          label="작성자"
          source="user_id"
          choices={[...staffs.results]}
          optionText={(staff) => staff && `${staff.id}/${staff.username}/${staff.email}`}
        />
        <ImageInput label="썸네일" className={classes.layout} source="thumbnail" variant="standard" validate={[required()]} parse={(v) => v}>
          <ImageField source="data.thumbnail" />
        </ImageInput>
        <div className={classes.richText}>
          <CustomEditor toolbar={toolbarOptions} variant="standard" label="본문" source="data.body" />
        </div>
        <BooleanInput source="is_pinned" label="메인 노출" initialValue={false} />
      </SimpleForm>
    </Create>
  );
};

export default CreateBlog;
