import { client } from 'graphql/providers';
import { gql } from '@apollo/client';
import { createWebinarAttendeeMutation } from 'graphql/mutations';

const webinarAttendeeProviders = {
  create: async (resource, params) => {
    const {
      data: { user_id, webinar_id, program_id, order_id, ordered_at },
    } = params;

    const { data } = await client.mutate({
      mutation: gql`
        ${createWebinarAttendeeMutation}
      `,
      variables: {
        input: {
          webinar_id: webinar_id,
          user_id: user_id,
          program_id: program_id,
          order_id: order_id,
          ordered_at: ordered_at,
        },
      },
    });
    return {
      data,
    };
  },
};

export default webinarAttendeeProviders;
