import React from 'react';
import { Create, SimpleForm, TextInput, TopToolbar, DateTimeInput, required, SaveButton, Toolbar, AutocompleteArrayInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: '50ch',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  inputRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > span:first-child': {
      width: '130px',
      marginBottom: '10px',
      color: '#484848',
      fontWeight: 'bold',
      justifyContent: 'flex-end',
      textAlign: 'right',
      padding: '20px',
    },
    '& .MuiFormControl-root': {
      minWidth: '300px',
    },
  },
  exampleRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    marginBottom: '10px',
    '& > span:first-child': {
      color: 'black',
      borderRadius: '20px',
      padding: '5px 10px',
      fontSize: '0.9em',
      marginRight: '5px',
    },
    '& ul': {
      margin: '0',
      padding: '0',
      display: 'flex',
      listStyle: 'none',
    },
    '& li': {
      margin: '0',
      padding: '0',
      marginRight: '10px',
      padding: '5px 10px',
      boxSizing: 'border-box',
      borderRadius: '20px',
      backgroundColor: '#ebebeb',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:active': {
        backgroundColor: '#fddb005e',
      },
    },
    '& li:after': {
      content: ',',
    },
  },
}));

const CreateCouponActions = ({ basePath, data, resource, userId }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <TopToolbar className={classes.header}>
      {userId && (
        <div>
          <Button onClick={() => history.push(`/users/${userId}/show/couponList`)}>
            <ArrowBackIcon />
          </Button>
          <b>{`유저 #${userId}`}</b> 에게 쿠폰 지급하기
        </div>
      )}
    </TopToolbar>
  );
};

const CreateCouponToolbar = (props) => {
  const { redirect, userId } = props;
  const createRedirect = (basePath, id, data) => `/coupons/create/?user_id=${userId}`;

  return (
    <Toolbar {...props}>
      <SaveButton label="저장" redirect={redirect} submitOnEnter={true} />
      <SaveButton label="저장하고 계속 생성하기" redirect={createRedirect} submitOnEnter={false} variant="text" />
    </Toolbar>
  );
};

const COUPON_TYPE_DEFAULT = 'discount_';

const CreateCoupon = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { user_id } = parse(history.location.search);

  const redirect = (basePath, id, data) => `/users/${user_id}/show/couponList`;

  const copyText = (e) => {
    const copiedText = e.target.innerText;
    const tempElem = document.createElement('textarea');
    tempElem.value = copiedText;
    document.body.appendChild(tempElem);
    tempElem.select();
    document.execCommand('copy');
    document.body.removeChild(tempElem);
  };
  const choices = [
    { name: '콘조이스', id: 'conjoyce' },
    { name: 'VOD', id: 'digitalcontents' },
    { name: '헤이조이스파티', id: 'heyjoyceParty' },
  ];
  const optionRenderer = (choice) => `${choice.name}`;
  return (
    <Create title="쿠폰 생성하기" actions={<CreateCouponActions userId={user_id} />} {...props}>
      <SimpleForm toolbar={<CreateCouponToolbar userId={user_id} />} redirect={redirect}>
        <div className={classes.inputRow}>
          <span>유저 아이디</span>
          <TextInput source="user_id" label=" " defaultValue={user_id} validate={[required()]} variant="standard" />
        </div>
        <div className={classes.inputRow}>
          <span>쿠폰 타입</span>
          <TextInput source="type" label=" " defaultValue={COUPON_TYPE_DEFAULT} validate={[required()]} variant="standard" />
        </div>
        <div className={classes.inputRow}>
          <span>쿠폰 이름</span>
          <TextInput source="coupon_name" label=" " validate={[required()]} variant="standard" />
          <div className={classes.exampleRow}>
            <span>( 📋 클릭해서 복사 )</span>
            <ul>
              <li onClick={copyText}>친구 초대 3천원 쿠폰</li>
              <li onClick={copyText}>헤이조이스 가입 환영 5천원 쿠폰</li>
            </ul>
          </div>
        </div>
        <div className={classes.inputRow}>
          <span>쿠폰 생성일시</span>
          <DateTimeInput source="created_at" label=" " validate={[required()]} variant="standard" />
        </div>
        <div className={classes.inputRow}>
          <span>쿠폰 만료일시</span>
          <DateTimeInput source="expire_at" label=" " validate={[required()]} variant="standard" />
        </div>
        <div className={classes.inputRow}>
          <span>쿠폰 사용 한정</span>
          <AutocompleteArrayInput label="선택하지 않으면 모든 상품에 사용 가능한 기본 쿠폰으로 생성됩니다." source="tags" choices={choices} optionText={optionRenderer} defaultValue={null} />
        </div>
      </SimpleForm>
    </Create>
  );
};

export default CreateCoupon;
